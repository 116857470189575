import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  customerData: {},
  answerQuestion: {},
  allQuestion: [],

};

export const RegisterKlSlice = createSlice({
  name: "RegisterKl",
  initialState: defaultData,
  reducers: {
    setRegister: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.customerData[key] = value;
      }
    },
    resetRegister: () => defaultData.customerData,

    setAllQuestion: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.allQuestion[key] = value;
      }
    },

    setQuestion: (state, action) => {
      if (Object.entries(action.payload).length) {
        for (const [key, value] of Object.entries(action.payload)) {
          state.answerQuestion[key] = value;
        }
      } else {
        state.answerQuestion = {};
      }
    },
    resetQuestion: () => defaultData.answerQuestion,
  },
});

// Action creators are generated for each case reducer function
export const { setQuestion, setAllQuestion, setRegister, setCustomerId } =
  RegisterKlSlice.actions;

export default RegisterKlSlice.reducer;
