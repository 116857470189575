import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import Banners from "../centerComponent/Banners";
import { RewardHistoryList } from "services/Api/Module/Reward";
import Pagination from "../centerComponent/Pagination";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import helper from "services/helper";
import Submenu2 from "pages/centerComponent/Submenu2";
import EmptyData from "pages/centerComponent/EmptyData";

const DEF_LIMIT = 10;
const DEF_OFFSET = 0;
const RewardHistory = () => {
  const [rewardHistorys, setRewardHistorys] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = DEF_LIMIT;
  const offset = searchParams.get("offset") ?? DEF_OFFSET;

  const getRewardHistoryList = async () => {
    let _return = {
      status: false,
      result: null,
      rowCount: 0,
    };

    try {
      const { data } = await RewardHistoryList({
        params: { customer: customerId, limit, offset },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchRewardHistoryList = async () => {
    try {
      const resData = await getRewardHistoryList();
      setRewardHistorys(resData.result);
      setRowCount(resData.rowCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRewardHistoryList();
  }, [stateProfile, offset]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageHistory-Reward">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/profile")}
                ></a>
                <h2>ประวัติการใช้ Point</h2>
              </div>
            </section>
            <Submenu2 />
            <section id="pContent">
              <h1>ประวัติการแลก Rewards</h1>
              {/* <?php for ($y = 1; $y <= 4; $y++) {?> */}
              {rowCount > 0 ? (
                rewardHistorys.map((item, index) => {
                  return (
                    <article key={index} className="bCard">
                      <div className="bHistoryDetails">
                        <div className="bDateStatus">
                          <p className="tDate">
                            แลกเมื่อวันที่:
                            <span>
                              {/* 25 เมษายน 2566 <br/> */}
                              {helper.TIME.toFullDateTH(item.createdOn)}
                            </span>
                          </p>
                          {item.trackingStatus === 1 ? (
                            <div className="tStatus" data-status="shipped">
                              จัดส่งแล้ว
                            </div>
                          ) : (
                            <div className="tStatus" data-status="pending">
                              รอจัดส่ง
                            </div>
                          )}
                        </div>
                        <div className="bDetailsMain">
                          <div className="bPic">
                            <img
                              src={
                                item.rewardImg
                                  ? item.rewardImg
                                  : "https://dummyimage.com/200x200/fff/000"
                              }
                              alt=""
                            />
                          </div>
                          <div className="bDetails">
                            <div className="bText">
                              <h3>{item.rewardTitle}</h3>
                              <p>{item.rewardDescription}</p>
                              <span>รหัส: {item.orderCode}</span>
                            </div>
                            <div className="bPoint">
                              <div className="point">
                                {helper.FN.toNumberWithCommas(
                                  item.redeemUsedPoint
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bBtn">
                          <a
                            onClick={() =>
                              navigate("/history-reward-details?id=" + item.id)
                            }
                            className="btn outline"
                          >
                            ดูรายละเอียด
                          </a>
                        </div>
                      </div>
                    </article>
                  );
                })
              ) : (
                <EmptyData />
              )}
              {/* <?php };?> */}
            </section>
            <Menu />
          </div>
        </main>
      </div>
    </div>
  );
};

export default RewardHistory;
