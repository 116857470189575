/* eslint-disable array-callback-return */
/*  */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Menu from "pages/menuMain/menu";
import Banners from "pages/centerComponent/Banners";
import ModalLucky from "./ModalLucky";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import config from "util/config";
import helper from "services/helper";
import ModalHeartEmpty from "../profile/modalHeartEmpty";

const CharacterPoint = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state.profile.dataUser);
  const stateCurrentPoint = useSelector((state) => state.currentPoint);

  const [openLucky, setOpenLucky] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [stickerRedeem, setStickerRedeem] = useState([]);
  const [totalSticker, setTotalSticker] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const bottonLuckyImg =
    "assets/img/ci/phase2/ico-collectpoint/ico-aniversary.svg";
  const bottonGameImg = "assets/img/ci/img-btn-playgame.png";

  const CONFIG = config.config();
  const gameURL = CONFIG.GAME_ENV;
  const gameKey = CONFIG.GAME_KEY_TOKEN;
  const gameLiffID = CONFIG.GAME_LIFF_ID;

  const totalHeart = state.actionHeart ?? 0;
  const newCurrentHeart = stateCurrentPoint.currentHeart;
  const usedHeart = stateCurrentPoint.usedHeart;
  const isHasHeart = newCurrentHeart > 0 ? true : false;
  const isSchedualSticker =
    stateCurrentPoint.isSchedualSticker == 1 ? true : false;
  const isSchedualGame = stateCurrentPoint.isSchedualGame == 1 ? true : false;
  const isUsedHeart = usedHeart == 0 ? true : false;

  useEffect(() => {
    setStickerRedeem(state.stickerHistory);
    setTotalSticker(state.countSticker);
    if (isFirstLoad) {
      const temp = state.stickerHistory.filter((item) => item.isSpecial === 1);
      setOpenLucky(temp.length > 0);
      setIsFirstLoad(false);
    }
  }, [state]);

  const redirectionGame = () => {
    // external Game
    if (!isHasHeart && !isUsedHeart) {
      return setOpenPopup(true);
    } else {
      if (gameURL) {
        // set payload token
        const token = setupGameToken(gameKey);
        redirectionGamePlatform(gameURL, token);
      } else {
        alert("URL wrong setup");
      }
    }
  };

  const setupGameToken = (secretKey) => {
    let payloadToken = {
      customerId: state.id,
      memberId: state.memberId,
      lineUid: state.lineUid,
    };
    let token = helper.encodeJWT(payloadToken, secretKey);

    let encodeToken = token;
    return encodeToken;
  };

  const redirectionGamePlatform = (link, token) => {
    if (liff.getOS() === "web") {
      window.location.href = `${link}?token=${token}`;
    } else {
      const redirect = `line://app/${gameLiffID}?token=${token}`;
      window.location.replace(redirect);
    }
  };

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageScan-Collectpoint">
        <main>
          <div className="wrapContent">
            <CollectpointHeader />

            <section id="pCollectpoint">
              <div className="bCollectpoint">
                <div className="bPoint">
                  <p>คุณได้รับ Points</p>
                  <div className="bTotalPoint">
                    <span>{state.actionPoint}</span>
                  </div>
                </div>
                <div className="bLink">
                  <a onClick={() => navigate("/history-collect-point")}>
                    ประวัติการ Collect point
                  </a>
                </div>
              </div>
            </section>
            {isSchedualGame && (
              <section id="pCollectHeart">
                <div class="bContent">
                  <p class="sym">คุณได้รับ</p>
                  <p>ทั้งหมด {totalHeart ?? 0} หัวใจ</p>
                </div>
              </section>
            )}
            {isSchedualSticker && (
              <section id="pCollectpointSticker">
                <p>
                  คุณได้รับ Character Collection <br />
                  รวมทั้งหมด {totalSticker ?? 0} ตัว
                </p>

                {/* <!-- lucky koala ตัวพิเศษ --> */}
                {stickerRedeem.map((item, index) => {
                  if (item.isSpecial === 1) {
                    return (
                      <div key={index} className="logoAniverary">
                        <p>Lucky Koala</p>
                        <img src={bottonLuckyImg} />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="logoAniverary hide"></div>
                    );
                  }
                })}

                {/* <!-- data-character สำหรับใส่เลข stk จากการสุ่ม --> */}
                <div className="bCollectpointSticker">
                  {stickerRedeem.map((item, index) => {
                    if (item.isSpecial !== 1) {
                      return (
                        <div
                          key={index}
                          className="bSticker"
                          data-character={item.imgPath}
                        ></div>
                      );
                    }
                  })}
                </div>
              </section>
            )}
            <section id="bButton">
              <div className="bBtn">
                {isSchedualGame && (
                  <a class="gameBtn" onClick={redirectionGame}>
                    <img src={bottonGameImg} />
                  </a>
                )}
                <a onClick={() => navigate("/scanpoint")} className="btn">
                  กรอกโค้ดเพิ่ม
                </a>
                {isSchedualSticker && (
                  <a
                    onClick={() => navigate("/character-collection")}
                    className="btn outline"
                  >
                    หน้า Character Collection
                  </a>
                )}
              </div>
            </section>
            <section id="pBanner">
              <Banners currentPage="Scan results" />
            </section>
            <Menu />
            <ModalLucky
              isOpen={openLucky}
              onClose={() => setOpenLucky(false)}
            />
            <ModalHeartEmpty
              isOpen={openPopup}
              onClose={() => setOpenPopup(false)}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default CharacterPoint;
