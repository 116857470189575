import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Submenu2 = ({}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setActive = (pages = [], path) => {
    if (pages.includes(path)) {
      return " active ";
    }
    return "";
  };

  return (
    <section id="pSubmenu">
      <div className="bSubmenu">
        <nav>
          <a
            className={`${setActive(["/history-collect-point"], pathname)}`}
            onClick={() => navigate("/history-collect-point")}
          >
            Collect point
          </a>
          <a
            className={`${setActive(["/history-reward"], pathname)}`}
            onClick={() => navigate("/history-reward")}
          >
            Rewards
          </a>
          <a
            className={`${setActive(["/history-luckydraw"], pathname)}`}
            onClick={() => navigate("/history-luckydraw")}
          >
            Lucky draw
          </a>
          <a
            className={`${setActive(["/history-receipt"], pathname)}`}
            onClick={() => navigate("/history-receipt")}
          >
            Receipt point
          </a>
          <a
            className={`${setActive(["/history-pillow"], pathname)}`}
            onClick={() => navigate("/history-pillow")}
          >
            Character Collection
          </a>
        </nav>
      </div>
    </section>
  );
};

export default Submenu2;
