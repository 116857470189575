import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckPointView } from "services/Api/Module/Reward";
import { setCurrentPoint } from "../../features/CurrentPointSlice";

const GetCurrentPoint = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const customerId = stateProfile.id;

  const newCurrentPoint = stateCurrentPoint.currentPoint;
  const currentPoint = newCurrentPoint ?? stateProfile.currentPoint ?? 0;

  const getCheckPointView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await CheckPointView({ customerId: customerId });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchCheckPointView = async () => {
    try {
      const resData = await getCheckPointView();
      // setRewardData(resData.result)
      if (resData.result && resData.result.currentPoint) {
        // setNewCurrentPoint(resData.result.currentPoint)
        const tmpUpdate = {
          customer: resData.result.customer,
          currentPoint: resData.result.currentPoint,
          currentHeart: resData.result.currentHeart,
          isSchedualGame: resData.result.isSchedualGame,
          isSchedualSticker: resData.result.isSchedualSticker,
          usedHeart: resData.result.usedHeart,
          isLoaded: true,
        };
        dispatch(setCurrentPoint(tmpUpdate));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (stateProfile.id) {
      fetchCheckPointView();
    }
  }, [stateProfile]);

  return (
    // <div>GetCurrentPoint:: {JSON.stringify(stateCurrentPoint)}</div>
    <div></div>
  );
};

export default GetCurrentPoint;
