import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalRewardEmpty = ({ isOpen = false, onClose, onConfirm }) => {
  const [modalRef, setModalRef] = useState(null);

  useEffect(() => {
    if (modalRef && isOpen) {
      openModal();
      listenerConfirmModal();
      listenerCloseModal();
    }
  }, [modalRef, isOpen]);

  useEffect(() => {
    const initModalRef = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalRewardEmpty"),
    });
    setModalRef(initModalRef);

    return () => {
      initModalRef.destroy();
    };
  }, []);

  const listenerConfirmModal = () => {
    const btnYesModal = document.getElementById("btnYesModalRewardEmpty");
    btnYesModal.addEventListener("click", () => {
      onConfirm();
      closeModal(); // ระวังบางหน้าไม่มี
    });
  };

  const listenerCloseModal = () => {
    const btnNoModal = document.getElementById("btnNoModalRewardEmpty");
    btnNoModal.addEventListener("click", () => {
      closeModal();
    });
    // const btnCloseModal = document.getElementById("btnCloseModalRewardEmpty");
    // btnCloseModal.addEventListener("click", () => {
    //   closeModal();
    // });
  };

  const openModal = () => {
    modalRef.open();
  };

  const closeModal = () => {
    modalRef.close();
    onClose();
  };

  return (
    // <!-- Modal Reward Empty -->
    <div
      className="bModal bCard"
      id="ModalRewardEmpty"
      style={{ display: "none" }}
    >
      <div className="bInner">
        <div className="bDetails">
          <i className="icoAlert"></i>
          <p className="tTitle">ขออภัยคุณมาช้าไป!</p>
          <p className="tDetails">ขณะนี้สินค้าสินค้าหมดแล้ว</p>
        </div>
        <div className="bBtn">
          <a className="btn red" id="btnYesModalRewardEmpty">
            ดูของรางวัลอื่น
          </a>
          <a
            className="btn outline red btnModalCancel"
            id="btnNoModalRewardEmpty"
          >
            ย้อนกลับ
          </a>
        </div>
        {/* <a className="btnCloseModal2" id="btnCloseModalRewardEmpty"></a> */}
      </div>
    </div>
    // <!-- Modal Cancel Edit Address -->
  );
};

export default ModalRewardEmpty;
