import { get, post, put } from "../Config";

// GET
export function LuckydrawList({ params = { limit: 10, offset: 0 } }) {
  return get({ url: `/api/koala/v1/luckydraw`, params });
}
export function LuckydrawView({ luckydrawId = 0, params }) {
  return get({ url: `/api/koala/v1/luckydraw/${luckydrawId}`, params });
}

export function LuckydrawHistoryList({ params }) {
  return get({ url: `/api/koala/v1/luckydrawHistory`, params });
}
export function LuckydrawHistoryView({ orderCode = 0, params }) {
  return get({ url: `/api/koala/v1/luckydrawHistory/${orderCode}`, params });
}

// POST
export function CreateLuckydraw({ data }) {
  return post({ url: `/api/koala/v1/luckydraw`, data, timeOut: 20000 });
}
