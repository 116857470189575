import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuestion, setAllQuestion } from "features/RegisterKlSlice";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import helper from "services/helper";
import { QuestionnaireSearch } from "services/Api/Module/Customer";

const Question = () => {
  const stateQuestion = useSelector((state) => state.registerKl.answerQuestion); // ไว้ดึง state
  const stateAllQuestion = useSelector((state) => state.registerKl.allQuestion); // ไว้ดึง state
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [disable, setDisable] = useState(true);
  const onSubmit = () => {
    setIsSubmit(true);
    // if (stateAllQuestion.length === Object.keys(stateQuestion).length) {
    //   navigate("/theme");
    // }
    navigate("/theme");
  };
  const validateForm = () => {
    if (Object.keys(stateQuestion).length !== 3) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [stateQuestion]);

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    getFieldState,
    register,
  } = useForm();

  const getQuestionnaireSearch = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await QuestionnaireSearch();
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (err) {
      console.log("ERR :", err);
    }

    return _return;
  };

  useEffect(() => {
    (async () => {
      const questionnaireData = await getQuestionnaireSearch();
      if (questionnaireData) {
        dispatch(setAllQuestion(questionnaireData.result));
      }
    })();
  }, []);

  const setCheck = (mainId, listId, state) => {
    if (state[mainId] && state[mainId].includes(listId)) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      {/* <!-- Content Page --> */}

      <div id="pageRegister-step2">
        {/* <!-- Content Page --> */}

        <main>
          <div className="wrapContent">
            <section id="pServayMain">
              <div className="bServay">
                <div className="bTitlePage">
                  <h1>แบบสอบถาม</h1>
                  <p>ตอบคำถามกับเราสักหน่อย เพื่อให้เราได้เข้าใจคุณมากขึ้น</p>
                </div>
                {stateAllQuestion && (
                  <div className="bForm">
                    {stateAllQuestion.map((item0) => {
                      return (
                        <div className="bGroup">
                          <div
                            className={`control-group ${
                              errors.mainQ1 ? "error" : ""
                            }`}
                          >
                            <div key={item0.id} className="bQuestion">
                              {item0.name}
                            </div>

                            <div className="bAnswer">
                              <div className="control-group">
                                <p className="" style={{ color: "gray" }}>
                                  ตอบได้มากกว่า 1 ข้อ
                                </p>
                                {item0.questionnaireList.map((item1) => (
                                  <label className="control control--checkbox">
                                    <p>{item1.name}</p>

                                    <input
                                      name={`mainQ.${item1.id}`}
                                      key={item1.id}
                                      checked={setCheck(
                                        item0.questionmain,
                                        item1.id,
                                        stateQuestion
                                      )}
                                      type="checkbox"
                                      onChange={(e) => {
                                        const _answerQuestion =
                                          helper.FNFORM.handleCheckBox(
                                            item0.questionmain,
                                            item1.id,
                                            stateQuestion
                                          );

                                        dispatch(setQuestion(_answerQuestion));
                                      }}
                                    />
                                    <div className="control__indicator"></div>
                                  </label>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* { Object.keys(allQuestion).length ? '': <p className="">เลือกอย่างน้อย 1 ข้อ</p>} */}
                          {isSubmit &&
                          stateQuestion[item0.questionmain] === undefined ? (
                            <p className="" style={{ color: "red" }}>
                              ตอบได้มากกว่า 1 ข้อ
                            </p>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="bStepBtn">
                  <div className="bStep">
                    <div className="bBullet">
                      <i></i>
                      <i></i>
                      <i></i>
                    </div>
                    <p>เหลืออีกเพียงขั้นตอนเดียวก็จะเสร็จสิ้นการลงทะเบียน</p>
                  </div>
                  <div className="bBtn">
                    <button
                      // href="#"
                      // disabled={disable}
                      className="btn"
                      onClick={onSubmit}
                    >
                      ยืนยัน
                    </button>
                    <button
                      className="btn outline"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Question;
