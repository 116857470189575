import { get, post, put } from "../Config";

//GET
export function GetPillowDetail({ type = "", params }) {
  return get({ url: `/api/koala/v1/pillow/${type}`, params });
}
export function GetPillowHistoryView({ pillowHistoryId = "", params }) {
  return get({ url: `/api/koala/v1/pillowHistory/${pillowHistoryId}`, params });
}
export function GetPillowHistoryList({ params }) {
  return get({ url: `/api/koala/v1/pillowHistory`, params });
}

//POST
export function PostCreatePillow({ data }) {
  return post({ url: `/api/koala/v1/redeemPillow`, data, timeOut: 20000 });
}
