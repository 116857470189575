import Menu from "pages/menuMain/menu";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const RecieptComplete = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <>
        <div id="pageScan-Receiptcomplete">
          {/* <!-- Content Page --> */}
          <main>
            <div className="wrapContent">
              <section id="pDetails">
                <div className="bTitle">
                  <p>กรอกใบเสร็จรับ Point เพิ่ม</p>
                </div>
                <div className="bDetails">
                  <div className="bPic">
                    <img src="assets/img/ci/reciept/img-check.svg" />
                  </div>
                  <h1>คุณกรอกใบเสร็จเรียบร้อยแล้ว</h1>
                  <p>
                    <span>ขอบคุณที่ร่วมสนุกกิจกรรม</span>
                    ระบบจะทำการตรวจสอบใบเสร็จ <br />
                    และอนุมัติภายใน 3 วันทำการ
                  </p>
                  <p></p>
                </div>
              </section>

              <section id="bButton">
                <div className="bBtn">
                  <a onClick={() => navigate(-1)} className="btn">
                    กรอกใบเสร็จเพิ่ม
                  </a>
                  <a
                    onClick={() => navigate("/profile")}
                    className="btn outline"
                  >
                    กลับหน้าหลัก
                  </a>
                </div>
              </section>

              {/* <!-- Menu -->
          <?php include 'inc/inc-menu.php';?>

          <!-- Modal -->
          <?php include 'inc/inc-modal.php';?> */}
            </div>
          </main>
          <Menu />
        </div>
      </>
    </div>
  );
};

export default RecieptComplete;
