import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const PolicyNews = ({ open = false, onBack = () => {} }) => {
  const styleShow = { display: open ? "block" : "none" };

  const goBack = () => {
    onBack();
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [open]);

  return (
    <div style={styleShow}>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pagePolicy-News">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a className="btnIconBack" onClick={goBack}></a>
                <h2>เงื่อนไขรับข่าวสารและโปรโมชั่น</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bContent bCard">
                <h1>เงื่อนไขรับข่าวสารและโปรโมชั่น</h1>
                <div className="bDetails">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Sapiente maiores facere hic dolorum fugit earum velit ipsa,
                  aut voluptas nesciunt iste eius ipsum explicabo quam
                  aspernatur blanditiis obcaecati cumque ipsam? Lorem ipsum
                  dolor sit amet consectetur adipisicing elit. Sapiente maiores
                  facere hic dolorum fugit earum velit ipsa, aut voluptas
                  nesciunt iste eius ipsum explicabo quam aspernatur blanditiis
                  obcaecati cumque ipsam? Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Sapiente maiores facere hic dolorum fugit
                  earum velit ipsa, aut voluptas nesciunt iste eius ipsum
                  explicabo quam aspernatur blanditiis obcaecati cumque ipsam?
                </div>
              </div>
              <div className="bBtn">
                <a onClick={goBack} className="btn">
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PolicyNews;
