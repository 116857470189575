import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BannerList } from "services/Api/Module/Banner";
import { setBanners } from "../../features/BannerSlice";
import liff from "@line/liff";
import config from "util/config";
import helper from "services/helper";

const Banners = ({ currentPage = "", sendDataToParent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [usedBanner, setUsedBanner] = useState([]);
  const stateBanner = useSelector((state) => state.banner.banners);
  const isLoaded = useSelector((state) => state.banner.isLoaded);
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const stateProfile = useSelector((state) => state.profile.dataUser);

  const [token, setToken] = useState("");
  const newCurrentHeart = stateCurrentPoint.currentHeart;
  const usedHeart = stateCurrentPoint.usedHeart;

  const isHasHeart = newCurrentHeart > 0 ? true : false;
  const isUsedHeart = usedHeart == 0 ? true : false;
  const CONFIG = config.config();
  const gameURL = CONFIG.GAME_ENV;
  const gameKey = CONFIG.GAME_KEY_TOKEN;
  const gameLiffID = CONFIG.GAME_LIFF_ID;

  const getBannerList = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await BannerList({});
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchBannerList = async () => {
    try {
      const resData = await getBannerList();
      // setBanners(resData.result)
      const tmpObj = { banners: resData.result, isLoaded: true };
      dispatch(setBanners(tmpObj));

      // const _usedBanner = calUsedBanner(resData.result, currentPage)
      // setUsedBanner(_usedBanner)
    } catch (err) {
      console.log(err);
    }
  };

  const calUsedBanner = (bannerList = [], curPage = "") => {
    const CONF_PAGE = {};
    let useBanner = [];
    const findBanner = bannerList.find(
      (item) => item.pageBannerName === curPage
    );
    if (findBanner?.banner) {
      useBanner = findBanner.banner;
    }
    return useBanner;
  };

  useEffect(() => {
    if (stateBanner.length > 0) {
      const _usedBanner = calUsedBanner(stateBanner, currentPage);
      setUsedBanner(_usedBanner);
    }
  }, [stateBanner]);

  useEffect(() => {
    if (isLoaded === false) {
      fetchBannerList();
    }
  }, []);

  const openExternalLink = (link, type = -1) => {
    if (link) {
      if (type === 1) {
        if (!isHasHeart && !isUsedHeart) {
          return sendDataToParent(true);
        } else {
          if (link == gameURL) {
            // external Game
            // set payload token
            const token = setupGameToken(gameKey);
            redirectionGamePlatform(link, token);
          } else if (link != gameURL) {
            redirectionPlatform(link);
          } else {
            alert("URL wrong setup");
          }
        }
        // external anoter
      } else if (type === 0 || type === null) {
        // InternalLink
        let url = link; //"https://www.koala.co.th/abcd/1234";
        let URI = "/" + url.split("/").slice(3).join("/"); // เอาโดเมนออก
        navigate(URI);
      }
    }
  };

  const setupGameToken = (secretKey) => {
    let payloadToken = {
      customerId: stateProfile.id,
      memberId: stateProfile.memberId,
      lineUid: stateProfile.lineUid,
    };
    let token = helper.encodeJWT(payloadToken, secretKey);

    let encodeToken = token;
    return encodeToken;
  };

  const redirectionPlatform = (externalLink) => {
    if (liff.getOS() === "web") {
      window.open(externalLink, "_blank");
    } else {
      liff.openWindow({ url: externalLink, external: true });
    }
  };

  const redirectionGamePlatform = (link, token) => {
    if (liff.getOS() === "web") {
      window.location.href = `${link}?token=${token}`;
    } else {
      const redirect = `line://app/${gameLiffID}?token=${token}`;
      window.location.replace(redirect);
    }
  };

  if (currentPage === "Lucky draw Results") {
    return (
      <div className="bBanner">
        {usedBanner.map((item, index) => {
          return (
            // <div className="bBanner">
            <a
              key={index}
              onClick={() =>
                openExternalLink(item.bannerLink, item.bannerTypeLink)
              }
            >
              <img src={item.bannerImg} />
            </a>
            // </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {usedBanner.map((item, index) => {
        return (
          <div
            key={index}
            className="bBanner"
            onClick={() =>
              openExternalLink(item.bannerLink, item.bannerTypeLink)
            }
          >
            <img src={item.bannerImg} />
          </div>
        );
      })}
    </>
  );
};

export default Banners;
