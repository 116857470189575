import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { LuckydrawView, CreateLuckydraw } from "services/Api/Module/Luckydraw";
import helper from "services/helper";
import ModalConfirmLuckydraw from "./ModalConfirmLuckydraw";
import ModalAlert from "components/modal/ModalAlert";
import GetCurrentPoint from "pages/reward/GetCurrentPoint";
import PolicyLuckydraw from "./PolicyLuckydraw";

const LuckydrawDetail = () => {
  const [luckydrawData, setLuckydrawData] = useState({});
  const [drawTimes, setDrawTimes] = useState(1);
  const [isAccept, setIsAccept] = useState(false);
  const [notEnoughPoints, setNotEnoughPoints] = useState(false);
  const navigate = useNavigate();

  console.log("drawTimes >>>>", drawTimes);

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;

  const [openCf, setOpenCf] = useState(false); // แลกรางวัล

  let [searchParams, setSearchParams] = useSearchParams();
  const luckydrawId = searchParams.get("id");

  // state page pdpd contions ต่างๆ
  const page = searchParams.get("page") ?? "";
  const isShowPage = !!page ? true : false;

  const currentPoint = newCurrentPoint ?? stateProfile?.currentPoint ?? 0;
  const pointToLuckyDraw = luckydrawData?.pointToLuckyDraw ?? 0;

  const maxDrawTimes =
    currentPoint && pointToLuckyDraw
      ? Math.floor(currentPoint / pointToLuckyDraw)
      : 0;

  const [isLoading, setIsLoading] = useState(false);

  const getLuckydrawView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await LuckydrawView({
        luckydrawId: luckydrawId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const gotoConfrim = () => {
    // alert('gotoConfrim!')
    navigate(`/luckydraw-confirm?id=${luckydrawId}`);
  };

  const fetchLuckydrawView = async () => {
    try {
      const resData = await getLuckydrawView();
      console.log("resData.result >>", resData.result);
      setLuckydrawData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  const postCreateLuckydraw = async (payload = {}) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await CreateLuckydraw({ data: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      } else {
        setNotEnoughPoints(true);
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        //   ModalAlert.info({
        //   open: true,
        //   title: _title,
        //   subTitle: _subTitle,
        //   content: "",
        // });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  // ---------Prepare data------------
  const prepareData = () => {
    const payload = {
      customer: Number(stateProfile.id),
      luckyDrawConfig: Number(luckydrawId),
      drawUsedPoint: drawTimes * pointToLuckyDraw,
      drawTimes: drawTimes,
    };
    return payload;
  };

  const saveCreateLuckydraw = async () => {
    const payload = prepareData();
    const result = await postCreateLuckydraw(payload);
    if (result.status) {
      navigate("/luckydraw-complete?id=" + luckydrawId);
    }
  };

  const onSubmit = async (val) => {
    setIsLoading(true);
    saveCreateLuckydraw();
  };

  useEffect(() => {
    fetchLuckydrawView();
  }, [stateProfile]);

  const contBTN0 = !isAccept; // กรณีไม่ checkboox
  const contBTN1 = !(currentPoint >= luckydrawData.pointToLuckyDraw); // กรณี point ไม่พอ
  const contBTN2 = !(luckydrawData.pointToLuckyDraw > luckydrawData.available); // กรณี สิทธิ์ ไม่พอ
  // const disabledBTN = contBTN0 || contBTN1 || contBTN2
  const disabledBTN = contBTN0;

  const styleShow = { display: isShowPage === false ? "block" : "none" };

  return (
    <div>
      {/* newCurrentPoint:: {newCurrentPoint} */}
      <GetCurrentPoint />
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <PolicyLuckydraw
        open={page === "policy-luckydraw"}
        onBack={() => {
          setSearchParams({ id: luckydrawId });
        }}
      />

      <div id="pageLuckydraw-Details" style={styleShow}>
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/luckydraw")}
                ></a>
                <h2>Lucky draw</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bDetailsMain bCard">
                <div className="bTitle">
                  <h2>{luckydrawData.name}</h2>
                  <p>{luckydrawData.description}</p>
                </div>
                <div className="bPointMain">
                  <div className="bPoint">
                    <span>ใช้ Point ลุ้น</span>
                    <p>
                      {helper.FN.toNumberWithCommas(
                        luckydrawData.pointToLuckyDraw
                      )}
                    </p>
                  </div>
                  <div className="bQuata">
                    <div className="bRemaining">
                      <p>
                        จำนวนลุ้น (สิทธิ์){" "}
                        <span>
                          {helper.FN.toNumberWithCommas(
                            luckydrawData.totalTime
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="bDate">
                      แลกได้ถึงวันที่:
                      <span>
                        {helper.TIME.toShortDateNormalTH(luckydrawData.toDate)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bProductImg">
                  <div className="bPic">
                    <img
                      src={
                        luckydrawData.img
                          ? luckydrawData.img
                          : "https://dummyimage.com/200x200/fff/000"
                      }
                      alt=""
                    />
                  </div>
                  <div className="bTag">จัดส่งฟรี!</div>
                </div>
                <div className="bCondition">
                  <h3>เงื่อนไขการรับของรางวัล</h3>
                  <div
                    className="bText"
                    dangerouslySetInnerHTML={{
                      __html: luckydrawData.conditions,
                    }}
                  />
                </div>
              </div>
              <div className="bBtnPage bCard">
                <div className="bAmount">
                  <div className="amountControl">
                    <button
                      className="btnMinus"
                      onClick={() => setDrawTimes((cur) => cur - 1)}
                      disabled={drawTimes <= 1}
                    ></button>
                    <span>{drawTimes}</span>
                    <button
                      className="btnPlus"
                      onClick={() => setDrawTimes((cur) => cur + 1)}
                      disabled={drawTimes >= maxDrawTimes}
                    ></button>
                  </div>
                  {/* currentPoint {currentPoint}<br/> */}
                  {/* pointToLuckyDraw{pointToLuckyDraw}<br/> */}
                  {/* maxDrawTimes {maxDrawTimes}<br/> */}
                  <p>เลือกจำนวนสิทธิ์ที่ต้องการลุ้น</p>
                </div>
                <div className="bBtn">
                  <div className="bForm">
                    <div className="control-group pdpa">
                      {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ Class error --> */}
                      <label className="control control--checkbox">
                        <p>
                          คุณยอมรับ{" "}
                          <a
                            onClick={() => {
                              setSearchParams({
                                id: luckydrawId,
                                page: "policy-luckydraw",
                              });
                            }}
                          >
                            เงื่อนไขการรับของรางวัล
                          </a>
                        </p>
                        <input
                          type="checkbox"
                          checked={isAccept}
                          onChange={(e) => {
                            console.log(
                              "e.target.checked >>",
                              e.target.checked
                            );
                            setIsAccept(e.target.checked);
                          }}
                        />
                        <div className="control__indicator"></div>
                      </label>
                      <label for="" className="tError">
                        จำเป็นต้องยินยอมรับเงื่อนไขการรับของรางวัล
                      </label>
                    </div>
                  </div>
                  {/* (contBTN1 || notEnoughPoints) */}
                  {(contBTN1 || notEnoughPoints) && (
                    <p className="tError">Point ของคุณไม่เพียงพอ</p>
                  )}
                  <a
                    onClick={() => setOpenCf(true)}
                    className={`btn ${
                      disabledBTN || isLoading ? "disabled" : ""
                    }`}
                    style={helper.STYLE.aDisable(disabledBTN || isLoading)}
                    id="btnModalConfirmLuckydraw"
                  >
                    ลุ้นรางวัล
                  </a>
                  {/* <!-- ใส่ class disabled เพื่อเป็นปุ่มเทา --> */}
                  <a
                    onClick={() => navigate("/luckydraw")}
                    className="btn outline"
                  >
                    ยกเลิก
                  </a>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ModalConfirmLuckydraw
        isOpen={openCf}
        onConfirm={onSubmit}
        onClose={() => setOpenCf(false)}
      />
    </div>
  );
};

export default LuckydrawDetail;
