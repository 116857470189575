import { get, post, put } from "../Config";

// GET
export function CharacterCollectionList({ params }) {
  return get({ url: `/api/koala/v1/themeSticker`, params });
}

export function CharacterStickerDetail({ params }) {
  return get({ url: `/api/koala/v1/stickerHistory`, params });
}
