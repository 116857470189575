/*  */
import React from "react";
import liff from "@line/liff";
import { useNavigate } from "react-router-dom";

const RewardItem = ({ item = {} }) => {
  const navigate = useNavigate();

  const openExternalLink = (link, type = -1) => {
    console.log("link >>", link);
    console.log("type >>", type);
    if (link) {
      if (type === 1) {
        // ExternalLink
        if (liff.getOS() === "web") {
          window.open(link, "_blank");
        } else {
          liff.openWindow({ url: link, external: true });
        }
      } else if (type === 0 || type === null) {
        // InternalLink
        let url = link; //"https://www.google.co.th/abcd/1234";
        let URI = "/" + url.split("/").slice(3).join("/"); // เอาโดเมนออก
        console.log(URI);
        navigate(URI);
      }
    }
  };
  const openExternal = () => {
    console.log("openExternal >>");
  };
  return (
    <>
      <div className="bContent">
        <div className="newsImg">
          <img
            src={item.img ? item.img : "https://dummyimage.com/400x400/fff/000"}
          />
        </div>
        <div className="bText">
          <p className="tHead">{item.name}</p>
          <p>{item.description}</p>
          {/* <a onClick={() => openExternalLink(item.eventLink, item.linkType)}>
            อ่านเพิ่มเติม
          </a> */}
          <a onClick={() => openExternalLink(item.eventLink, item.linkType)}>
            อ่านเพิ่มเติม
          </a>
        </div>
      </div>
    </>
  );
};

export default RewardItem;
