import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister, setCustomerId } from "features/RegisterKlSlice";
import { GetCustomerData } from "services/Api/Module/Customer";
import { setTheme, editTheme } from "features/ThemeSlice";
import {
  CreateCustomer,
  CreateQuestion,
  UpdateCustomer,
} from "services/Api/Module/Customer";

import ModalContext from "contexts/ModalContext";
import _ from "lodash";
import { setProfile } from "features/ProfileSlice";

const ThemeProfile = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  // const stateTheme = useSelector((state) => state.themeKl.theme);
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const { mAlert } = useContext(ModalContext);

  // themme
  const [defTheme, setdefTheme] = useState("green");
  const [myTheme, setMyTheme] = useState("");

  const navigate = useNavigate();
  const goBack = () => {
    dispatch(setTheme(defTheme)); // ดึง defTheme มาใช้ ถ้ายังไม่ Save
    navigate("/profile-view");
  };

  // ---------Prepare update customer data--------
  const payloadDatatheme = () => {
    const convertTheme = myTheme == "green" ? 1 : 2;

    let registerPayload = {
      themeSelected: convertTheme,
    };

    return registerPayload;
  };

  //api update Customer
  const postCustomer = async (id, payload) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await UpdateCustomer({ id: id, data: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {},
        });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  const onSubmit = async () => {
    try {
      const payloadUpdateData = payloadDatatheme();
      await postCustomer(stateProfile.id, payloadUpdateData);
      dispatch(setTheme(myTheme));
      await fetchProfile(); // โหลด profile ใหม่
      navigate("/profile-view");
    } catch (error) {
      console.log("ERR :", error);
    }
  };

  const getCustomerProfile = async (id) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await GetCustomerData({ id: id });

      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {},
        });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  useEffect(() => {
    dispatch(setTheme(myTheme));
  }, [myTheme]);

  const fetchProfile = async () => {
    const cusId = stateProfile.customerId;
    const custormerProfile = await getCustomerProfile(cusId);
    dispatch(
      setProfile({
        ...custormerProfile.result,
        customerId: custormerProfile.result.id,
      })
    );
  };

  useEffect(() => {
    let defaultTheme = "green";
    if (stateProfile.themeSelected) {
      if (stateProfile.themeSelected == "1") {
        defaultTheme = "green";
      } else if (stateProfile.themeSelected == "2") {
        defaultTheme = "pink";
      }
    }
    setMyTheme(defaultTheme);
    setdefTheme(defaultTheme);
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRegister-step3">
        <main>
          <div className="wrapContent">
            <section id="pThemesMain">
              <div className="bThemes">
                <div className="bTitlePage">
                  <h1>เลือกธีม</h1>
                  <p>เลือกธีมคาแร็กเตอร์ที่คุณชอบ</p>
                </div>
                <div className="bForm">
                  <div className="bSelectThemes">
                    <div className="control-group imgRadio">
                      <label className="control control--radio green">
                        <input
                          type="radio"
                          value="green"
                          name="theme"
                          onChange={(e) => setMyTheme(e.target.value)}
                          checked={"green" === myTheme}
                        />
                        <div className="control__indicator">
                          <div className="bPic"></div>
                        </div>
                      </label>
                      <label className="control control--radio pink">
                        <input
                          type="radio"
                          value="pink"
                          name="theme"
                          onChange={(e) => setMyTheme(e.target.value)}
                          checked={"pink" === myTheme}
                        />
                        <div className="control__indicator">
                          <div className="bPic"></div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="bStepBtn">
                  <div className="bBtn">
                    <button type="button" className="btn" onClick={onSubmit}>
                      ยืนยัน
                    </button>
                    <button className="btn outline" onClick={goBack}>
                      ย้อนกลับ
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ThemeProfile;
