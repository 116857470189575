import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalConfirmEdit = ({ isOpen = false, onClose }) => {
  const [modalRef, setModalRef] = useState(null);

  useEffect(() => {
    if (modalRef && isOpen) {
      openModal();
      listenerCloseModal();
    }
  }, [modalRef, isOpen]);

  useEffect(() => {
    const initModalRef = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModalRef(initModalRef);

    return () => {
      initModalRef.destroy();
    };
  }, []);

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModal");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  const openModal = () => {
    modalRef.open();
  };

  const closeModal = () => {
    modalRef.close();
    modalRef.destroy();
    onClose();
  };

  return (
    <div className="bModal bCard" id="ModalConfirm" style={{ display: "none" }}>
      <div className="bInner">
        <div className="bDetails">
          <p className="tDetails">บันทึกข้อมูลเรียบร้อยแล้ว</p>
        </div>
        <div className="bBtn">
          <a onClick={closeModal} className="btn" id="btnCloseModal">
            ตกลง
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmEdit;
