/*  */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Menu from "pages/menuMain/menu";
import _ from "lodash";

const CharacterProcess = () => {
  const partimgloadding =
    "assets/img/ci/phase2/character-random/ico-random.svg";
  const navigate = useNavigate();
  useEffect(() => {
    const timeOutId = setTimeout(() => navigate("/character-point"), 2000);
    return () => clearTimeout(timeOutId);
  }, [navigate]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageScan-characterRandom-process" data-isLoading="1">
        <main>
          <div className="wrapContent">
            <section>
              <div className="bProcess">
                <p>
                  เรากำลังสุ่ม Character Collection
                  <br /> กรุณารอสักครู่...
                </p>
                <img src={partimgloadding} />
              </div>
            </section>
          </div>
        </main>
        <Menu />
      </div>
    </div>
  );
};

export default CharacterProcess;
