import { createSlice } from "@reduxjs/toolkit";

// const themeData = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'green'
const themeData = 'green'

const defaultData = {
    theme: themeData
  };

export const ThemeSlice = createSlice({
    name: "ThemeKl",
    initialState: defaultData,
    reducers: {
        setTheme:(state,action)=>{
            state.theme = action.payload
            localStorage.setItem("theme",action.payload);
            document.body.setAttribute('data-theme', action.payload);
        }
    }
})

export const {setTheme} = ThemeSlice.actions;

export default ThemeSlice.reducer;