import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const PolicyPrivacy = ({ open = false, onBack = () => {}, noBTN = false }) => {
  const styleShow = { display: open ? "block" : "none" };

  const goBack = () => {
    onBack();
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [open]);

  return (
    <div style={styleShow}>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pagePolicy-Privacy">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <div className="bTitlePage">
                  {!noBTN ? (
                    <a className="btnIconBack" onClick={goBack}></a>
                  ) : (
                    <a></a>
                  )}
                </div>
                <h2>นโยบายความเป็นส่วนตัว</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bContent bCard">
                <h1>นโยบายความเป็นส่วนตัว</h1>
                <div className="bDetails">
                  <p>
                    เพื่อการประมวลผลข้อมูลส่วนบุคคล บริษัท ไทยลอตเต้ จำกัด
                    และกลุ่มบริษัทในเครือ (“เรา” หรือ “บริษัทฯ”)
                    จำเป็นต้องได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
                    (“ผู้ร่วมกิจกรรม”, “ผู้ใช้ระบบ” หรือ “ท่าน”)
                    โดยได้มีการแจ้งวัตถุประสงค์ของการประมวลผลข้อมูลส่วนบุคคลก่อนการขอความยินยอม(ข้อตกลงฯ)แล้ว
                    เช่น การเก็บข้อมูลส่วนบุคคล มาตรา 6
                    หรือการเก็บรวบรวมข้อมูลส่วนบุคคลที่อ่อนไหวด้วยวัตถุประสงค์ที่ไม่เป็นไปตามข้อยกเว้นมาตรา
                    26 แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                    และกรณีการตลาดที่เหนือความคาดหมายของเจ้าของข้อมูลส่วนบุคคล
                    ข้อมูลส่วนบุคคลถือเป็นสิ่งที่สำคัญ
                    ดังนั้นเราจึงได้พัฒนานโยบายความเป็นส่วนตัวนี้เพื่ออธิบายถึงวิธีที่เราเก็บรวบรวม
                    ใช้ เปิดเผย โอน
                    และดำเนินการเกี่ยวกับข้อมูลของท่านเมื่อท่านทำการเข้าถึงแอปพลิเคชันไลน์ทางการ
                    @koalas-march (หรือเรียกว่า “ระบบ”)
                    กรุณาอ่านและทำความเข้าใจเกี่ยวกับนโยบายความเป็นส่วนตัวนี้ก่อนใช้งานระบบหรือเว็บไซต์อื่นใดบนระบบหมายถึงท่านยินยอมให้ทางเราเก็บรวบรวม
                    ใช้ เปิดเผย และประมวลผลข้อมูล
                    รวมถึงรักษาข้อมูลส่วนบุคคลของท่านตามที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้{" "}
                  </p>
                  <p>
                    นโยบายความเป็นส่วนตัวนี้มีผลบังคับใช้ตั้งแต่วันที่ท่านใช้งานโดยอาจมีการแก้ไขเปลี่ยนแปลง
                    เราจะทำการลงประกาศฉบับแก้ไขเปลี่ยนแปลงที่นี่
                    และระบุวันที่ที่มีการปรับปรุงข้อมูลล่าสุด (วันที่ 1
                    กุมภาพันธ์ พ.ศ. 2567 หรือวันที่มีผลบังคับใช้)
                    ดังนั้นท่านควรตรวจสอบนโยบายความเป็นส่วนตัวนี้เป็นระยะเพื่อรับทราบการเปลี่ยนแปลงที่อาจเกิดขึ้น
                    การที่ท่านเข้ามาใช้งานระบบหมายความว่าท่านยอมรับการเปลี่ยนแปลงนั้น
                    ๆ
                  </p>

                  <h2>ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม</h2>
                  <p>
                    ในนโยบายความเป็นส่วนตัวนี้ “ข้อมูลส่วนบุคคล”
                    หมายถึงข้อมูลหรือรายละเอียดข้อมูลที่สามารถใช้ระบุตัวตนได้
                    เราจะดำเนินรวบรวมข้อมูลของท่านเมื่อท่านใช้งานบนระบบ
                    ข้อมูลที่เรารวบรวมได้แบ่งออกเป็นสามประเภทคือ
                  </p>
                  <p>
                    (ก) ข้อมูลส่วนบุคคลที่ท่านมอบให้แก่เรา
                    ท่านอาจมอบข้อมูลดังต่อไปนี้ให้แก่เรา
                  </p>
                  <ul>
                    <li>
                      รายละเอียดข้อมูลส่วนบุคคล เช่น ชื่อ - นามสกุล ที่อยู่
                      หมายเลขโทรศัพท์มือถือ หรือข้อมูลการติดต่ออื่น ๆ
                      เมื่อท่านลงทะเบียนผ่านระบบ หรือการติดต่อเราทางโทรศัพท์
                    </li>
                    <li>
                      ข้อมูลการทำธุรกรรมรวมถึงข้อมูลเกี่ยวกับผลิตภัณฑ์ที่ท่านซื้อ
                      ราคา วิธีการชำระเงิน ร้านค้าที่ท่านซื้อ
                      และรายละเอียดการชำระเงินอื่น ๆ
                    </li>
                    <li>
                      ข้อมูลบัญชีผู้ใช้ เช่น ชื่อผู้ใช้ รูปภาพของท่าน
                      (หรือข้อมูลอื่น ๆ ที่ระบุถึงตัวท่าน)
                      ที่ใช้เพื่อเข้าถึงระบบและข้อมูลส่วนบุคคลอื่น ๆ
                      ที่ท่านให้เราเมื่อท่านโต้ตอบกับเรา
                    </li>
                  </ul>
                  <p>(ข) ข้อมูลส่วนบุคคลที่เรารวบรวมด้วยวิธีอัตโนมัติ</p>
                  <p>
                    เราอาจใช้เทคโนโลยีอัตโนมัติในการเก็บรวบรวมข้อมูลการใช้งานจากอุปกรณ์เคลื่อนที่ของท่าน
                    เมื่อท่านใช้งานเทคโนโลยีอัตโนมัตินี้อาจรวมถึงเลขที่สมาชิก
                    (UID), คุกกี้ เว็บบีคอน พิกเซลแท็ก
                    และเทคโนโลยีการติดตามอื่นๆ ทั้งนี้คุกกี้และเทคโนโลยีอื่นๆ
                  </p>
                  <p>(ค) ข้อมูลส่วนบุคคลที่เรารวบรวมจากแหล่งอื่นๆ</p>
                  <p>
                    เราอาจรวบรวมข้อมูลเกี่ยวกับท่านจากบริษัทและองค์กรอื่นๆ
                    ซึ่งเราอาจรวบรวมข้อมูลที่เปิดเผยต่อสาธารณะ ตัวอย่างเช่น
                    เราอาจรวบรวมข้อมูลเกี่ยวกับท่านเมื่อท่านโต้ตอบกับเราผ่านสื่อสังคมออนไลน์
                  </p>

                  <h2>วิธีที่เรานำข้อมูลของท่านไปใช้</h2>
                  <p>
                    ข้อมูลที่ถูกต้องเกี่ยวกับท่านช่วยให้เราสามารถให้บริการท่านด้วยความราบรื่น
                    มีประสิทธิภาพ สิทธิประโยชน์ของท่าน
                    เราอาจใช้ข้อมูลที่รวบรวมเกี่ยวกับท่านเพื่อ :
                  </p>
                  <ul>
                    <li>
                      สร้างและจัดการบัญชีของท่าน
                      เพื่อจดจำข้อมูลของท่านเพื่อความสะดวกเมื่อท่านเข้าใช้งานบนระบบ
                    </li>
                    <li>
                      ติดต่อท่านเพื่อส่งข้อมูลเกี่ยวกับผลิตภัณฑ์และการบริการที่ท่านอาจสนใจ
                    </li>
                    <li>
                      นำเสนอผลิตภัณฑ์ใหม่ การบริการ และ/หรือคำแนะนำแก่ท่าน
                    </li>
                    <li>
                      ประชาสัมพันธ์กิจกรรมการขายและการตลาดของทางบริษัทฯ อาทิ
                      กิจกรรมแลกคะแนน(พอยต์)รับของรางวัล
                      หรือกิจกรรมแลกคะแนน(พอยต์) ชิงโชค โปรโมชั่น
                      และการแข่งขันต่างๆ
                    </li>
                    <li>
                      ส่งโฆษณาที่ตรงเป้าหมาย คูปอง ข่าวสาร และข้อมูลอื่นๆ
                      เกี่ยวกับโปรโมชั่นหรือกิจกรรมพิเศษให้กับท่าน
                    </li>
                    <li>
                      ตอบสนองเกี่ยวกับผลิตภัณฑ์และคำร้องขอบริการของลูกค้ารวบรวมข้อมูลทางสถิติที่ไม่สามารถระบุตัวตนได้
                      และการวิเคราะห์ข้อมูลสำหรับใช้ภายในหรือกับบุคคลภายนอก
                    </li>
                    <li>
                      ตรวจสอบและวิเคราะห์การใช้งานและแนวโน้มเพื่อปรับปรุงประสบการณ์ของท่านที่มีกับระบบ
                    </li>
                    <li>เพิ่มประสิทธิภาพและการดำเนินการของระบบ</li>
                    <li>
                      ป้องกันการฉ้อโกง ตรวจสอบการโจรกรรม และป้องกันอาชญากรรม
                    </li>
                    <li>ปฏิบัติตามกฎหมายที่บังคับใช้</li>
                  </ul>
                  <p>
                    การเปิดเผยข้อมูลส่วนบุคคลของท่านเราอาจเปิดเผยข้อมูลที่เรารวบรวมเกี่ยวกับท่านในบางสถานการณ์
                    ข้อมูลของท่านอาจได้รับการเปิดเผยให้กับบุคคลดังนี้
                    ผู้ให้บริการบุคคลภายนอกเราอาจเปิดเผยข้อมูลของท่านให้แก่บุคคลภายนอกที่ให้บริการแก่เราหรือในนามของเรา
                    รวมถึงการปฏิบัติตามคำสั่งซื้อของท่าน การประมวลผลการชำระเงิน
                    การวิเคราะห์ข้อมูล การบริการลูกค้า การจัดโปรโมชั่น
                    การแข่งขัน การจับรางวัลและการชิงโชค
                    การทำวิจัยหรือการสำรวจความพึงพอใจของลูกค้า
                    ซึ่งบุคคลภายนอกเหล่านี้มีหน้าที่ในการปกป้องข้อมูลของท่าน
                    ตามมาตรการข้อตกลงของบริษัทฯ อย่างเคร่งครัด
                    อย่างไรก็ตามสมาชิกในเครือ [บริษัท ไทยลอตเต้ จำกัด]
                    หรือบริษัทตัวแทนของเรา
                    ไม่ได้รับอนุญาตให้ใช้หรือเปิดเผยข้อมูลนี้
                    ยกเว้นที่ข้อมูลที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                  </p>

                  <h2>เว็บไซต์ของบุคคลภายนอก</h2>
                  <p>
                    แอปพลิเคชันไลน์ทางการ @koalas-march (หรือเรียกว่า “ระบบ”)
                    อาจมีการเชื่อมโยงไปยังเว็บไซต์ของบุคคลภายนอกที่ไม่เกี่ยวข้องกับเรา
                    หากท่านเข้าชมเว็บไซต์ที่เชื่อมโยงเหล่านี้
                    ท่านควรอ่านนโยบายความเป็นส่วนตัว
                    เงื่อนไขและข้อกำหนดในการให้บริการ และนโยบายอื่นๆ
                    ของบุคคลภายนอก เราไม่มีส่วนรับผิดชอบต่อเนื้อหา
                    หรือนโยบายความเป็นส่วนตัว ความปลอดภัย และนโยบายต่างๆ
                    ของบุคคลภายนอก
                  </p>

                  <h2>การรักษาความปลอดภัยของข้อมูล</h2>
                  <p>
                    ความสมบูรณ์ของข้อมูลและการรักษาข้อมูลส่วนบุคคล
                    เราช่วยให้ท่านสามารถเก็บข้อมูลส่วนบุคคลของท่านได้ถูกต้องสมบูรณ์และเป็นข้อมูลล่าสุดได้อย่างง่ายดาย
                    เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นระยะเวลาเท่าที่จำเป็น
                    เพื่อให้เป็นไปตามวัตถุประสงค์ที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                    เว้นแต่จะมีการขยายระยะเวลาตามที่ถูกร้องขอหรือได้รับอนุญาตตามกฎหมาย
                    ถึงแม้เราใช้มาตรการรักษาความปลอดภัยที่เหมาะสมในการปกป้องข้อมูลส่วนบุคคลเพื่อรักษาข้อมูลส่วนบุคคลของท่าน
                    ถึงแม้ว่าเราจะใช้ขั้นตอนที่สมเหตุสมผลในการปกป้องข้อมูลส่วนบุคคลของท่าน
                  </p>
                  <p>
                    แต่โปรดทราบว่าไม่ว่าเราจะใช้ความพยายามอย่างไร
                    ก็ไม่มีมาตรการรักษาความปลอดภัยใดที่ดีที่สุดหรือไม่มีข้อบกพร่อง
                    และไม่มีวิธีการส่งข้อมูลทางอินเทอร์เน็ตใดที่สามารถรับประกันการแทรกซึมหรือการใช้งานผิดประเภทอื่นๆ
                    ข้อมูลใดๆ
                    ที่เปิดเผยทางออนไลน์มีความเสี่ยงที่จะถูกแทรกซึมและนำไปใช้ผิดวัตถุประสงค์โดยบุคคล
                    ซึ่งเราจะไม่รับผิดชอบต่อความสูญหายหรือเสียหายใดๆ
                    อันเกิดจากการใช้งานนี้ไม่ว่าทางตรงหรือทางอ้อม
                    รวมถึงความเสียหายอื่นใด เช่น
                    ความเสียหายจากการใช้หรือมิได้ใช้บริการบนระบบ เป็นต้น
                    เว้นแต่กรณีกฎหมายที่เกี่ยวข้องและหรือข้อตกลงฯ นี้
                    จะกำหนดไว้เป็นอย่างอื่น ที่ไม่ได้รับอนุญาต
                    ดังนั้นเราไม่สามารถรับประกันความปลอดภัยได้ทั้งหมด
                  </p>

                  <h2>นโยบายสำหรับเด็ก</h2>
                  <p>
                    ระบบของเราได้รับการออกแบบสำหรับผู้ใช้ที่มีอายุตั้งแต่ 10
                    ปีขึ้นไปเท่านั้น
                    เราไม่ได้เก็บรวบรวมข้อมูลที่สามารถระบุตัวตนได้จากเด็กอายุต่ำกว่า
                    10 ปี หากท่านรู้ถึงข้อมูลใด ๆ
                    ที่เราเก็บรวบรวมมาจากเด็กอายุต่ำกว่า 10 ปี
                    กรุณาติดต่อเราโดยใช้ข้อมูลการติดต่อที่อยู่ด้านล่าง นอกจากนี้
                    หากเราทราบว่าเราได้เก็บรวบรวมข้อมูลจากเด็กอายุต่ำกว่า 10 ปี
                    เราจะดำเนินการลบข้อมูลดังกล่าวโดยเร็วที่สุด
                  </p>

                  <h2>อื่น ๆ</h2>
                  <p>
                    การเปิดเผยข้อมูลข้อมูลส่วนบุคคลของท่านมีความจำเป็นต้องเป็นไปตามข้อกำหนดของกฎหมาย
                    กระบวนการทางกฎหมาย การดำเนินคดี
                    และ/หรือการเรียกร้องโดยหน่วยงานของรัฐและสาธารณะ
                    นอกจากนี้เราอาจเปิดเผยข้อมูลเกี่ยวกับท่านหากเราพิจารณาแล้วว่า
                    เพื่อความมั่นคงของชาติ การบังคับใช้กฎหมาย
                    หรือเรื่องอื่นๆที่มีความสำคัญต่อสาธารณะ
                    โดยเราจะเปิดเผยเท่าที่จำเป็นและเหมาะสม
                  </p>
                  <p>
                    นอกจากนี้เราอาจเปิดเผยข้อมูลเกี่ยวกับท่านหากเราพิจารณาแล้วว่า
                    การเปิดเผยข้อมูลดังกล่าวมีความจำเป็นเพื่อบังคับใช้ข้อกำหนดและเงื่อนไขในการบริการ
                    หรือปกป้องการดำเนินงานหรือผู้ใช้ของเรา
                    นอกจากนี้ในกรณีที่มีการปรับเปลี่ยนโครงสร้างองค์กร
                    การควบกิจการ หรือการขายธุรกิจทั้งหมดหรือบางส่วนของเรา
                    เราอาจโอนข้อมูลส่วนบุคคลทั้งหมดหรือบางส่วนที่เราได้รวบรวมไว้ให้แก่บุคคลภายนอกที่เกี่ยวข้อง
                  </p>
                  <p>
                    สุดท้ายนี้บริษัท ไทยลอตเต้ จำกัด และกลุ่มบริษัทในเครือ
                    (“เรา” หรือ “บริษัทฯ”)
                    เคารพสิทธิ์ของท่านที่ได้ใช้สิทธิ์ในการลงทะเบียนร่วมกิจกรรมกับเราและคำนึงถึงความปลอดภัยในการเก็บรักษาข้อมูลเป็นอย่างยิ่ง
                    ดังนั้นทางบริษัทฯ
                    จึงจะเก็บรวบรวมและใช้ข้อมูลเท่าที่จำเป็นต่อการระบุถึงที่อยู่ได้ตามที่ระบุไว้ดังนี้เท่านั้น
                    โดยจะเก็บรวบรวมข้อมูลที่ท่านลงทะเบียนไว้เป็นความลับและจะไม่อนุญาตให้ผู้อื่นที่ไม่เกี่ยวข้องเข้าถึงข้อมูลดังกล่าวได้
                    ทางบริษัทฯ จะไม่เปิดเผยข้อมูลของท่านเพื่อประโยชน์ใด ๆ
                    โดยจะใช้เพียงข้อมูลบางส่วนของท่านที่เกี่ยวกับกิจกรรมเท่านั้น
                    (หรือข้อมูลที่ทางผู้ลงทะเบียนอนุญาตเท่านั้น)
                    รวมถึงระยะเวลาเก็บรักษาข้อมูลของผู้ลงทะเบียนทางบริษัทฯ
                    จะเก็บรักษาไว้ในระบบตามกฎหมายกำหนด
                    หรือตามระยะเวลาวัตถุประสงค์ในการเก็บข้อมูลนั้น ๆ ตามกิจกรรม
                    และมีระยะเวลาในการจัดเก็บข้อมูลตั้งแต่วันที่ 1 กุมภาพันธ์
                    พ.ศ. 2567 เป็นต้นไป เป็นระยะเวลา 2 ปี
                    หากข้อมูลในระบบสูญหายอันเนื่องมาจากเหตุการณ์ไม่คาดคิดใด ๆ
                    เช่น
                    ภัยธรรมชาติการเกิดสถานการณ์ความไม่สงบหรือการโจรกรรมทางคอมพิวเตอร์
                    ฯลฯ
                    หากเรามีเหตุจำเป็นต้องขอข้อมูลใหม่ในช่วงที่กิจกรรมยังไม่สิ้นสุด
                    ทางบริษัทฯ จะแจ้งขอข้อมูลของท่านอีกครั้ง
                  </p>
                  <h2>ติดต่อเรา</h2>
                  <p>
                    หากท่านมีคำถามหรือความเห็นเกี่ยวกับนโยบายความเป็นส่วนตัวนี้
                    สามารถติดต่อเราได้ที่:
                    <br />
                    บริษัท ไทยลอตเต้ จำกัด <br />
                    ที่อยู่ : เลขที่ 25 อาคารกรุงเทพประกันภัย ชั้น 19 ถนนสาทรใต้
                    แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพฯ 10120
                    <br />
                    โทรศัพท์ : 02-029-1415
                  </p>
                  <h2>คำแนะนำ </h2>
                  <p>
                    โปรดอ่านและทำความเข้าใจข้อตกลงนโยบายความเป็นส่วนตัวและเงื่อนไขการใช้งานระบบนี้อย่างละเอียด
                    การที่ท่านลงทะเบียนเพื่อขอใช้งานระบบ
                    ตามขั้นตอนที่เรากำหนดไว้ถือว่าท่านตกลงยอมรับเงื่อนไขที่กำหนดไว้ในข้อตกลงฯ
                    ข้างต้นทุกประการหากท่านไม่ยอมรับข้อตกลงฯ ที่กำหนดไว้นี้
                    ขอให้ท่านปฏิเสธการลงทะเบียนเข้าใช้งานระบบนี้
                  </p>
                </div>
              </div>

              {!noBTN && (
                <div className="bBtn">
                  <a onClick={goBack} className="btn">
                    ย้อนกลับ
                  </a>
                </div>
              )}
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PolicyPrivacy;
