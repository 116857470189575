const config = () => {
  return {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    LIFF_ID: process.env.REACT_APP_LIFF_ID,
    APP_ENV: process.env.REACT_APP_ENV,
    GAME_ENV: process.env.REACT_APP_GAME_URL,
    GAME_KEY_TOKEN: process.env.REACT_APP_KEY_TOKEN,
    GAME_LIFF_ID: process.env.REACT_APP_GAME_LIFF_ID,
  };
};

export default {
  config,
};
