import React, { useRef, useState } from "react";

const CameraComponent = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [photoURL, setPhotoURL] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const takePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, 640, 480); // ปรับขนาดตามต้องการ

      const photoDataURL = canvasRef.current.toDataURL("image/png");
      setPhotoURL(photoDataURL);
    }
  };

  const downloadPhoto = () => {
    const a = document.createElement("a");
    a.href = photoURL;
    a.download = "photo.png";
    a.click();
  };

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline />
      <button onClick={startCamera}>Start Camera</button>
      <button onClick={takePhoto}>Take Photo</button>

      {photoURL && (
        <div>
          <canvas
            ref={canvasRef}
            width={640}
            height={480}
            style={{ display: "none" }}
          />
          <img src={photoURL} alt="Captured" />
          <button onClick={downloadPhoto}>Download Photo</button>
        </div>
      )}
    </div>
  );
};

export default CameraComponent;
