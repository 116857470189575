import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const Error = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pagePolicy-Terms">
        <main>
          <div className="wrapContent">
            <section id="pContent">
              <div className="bContent">
                <h1 style={{ margin: "0px" }}>
                  ขออภัย กรุณาเปิดใน LINE บนมือถือ..
                </h1>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Error;
