import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { LuckydrawHistoryView } from "services/Api/Module/Luckydraw";
import helper from "services/helper";

const LuckydrawHistoryDetail = () => {
  const [LuckydrawHistoryData, setLuckydrawHistoryData] = useState({});
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const orderCode = searchParams.get("orderCode");

  const getLuckydrawHistoryView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };

    try {
      const { data } = await LuckydrawHistoryView({
        orderCode: orderCode,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchLuckydrawHistoryView = async () => {
    try {
      const resData = await getLuckydrawHistoryView();
      setLuckydrawHistoryData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLuckydrawHistoryView();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageHistory-Luckydraw-Details">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/history-luckydraw")}
                ></a>
                <h2>ประวัติการลุ้น Lucky Draw</h2>
              </div>
            </section>
            <section id="pContent">
              <h1>รายละเอียด</h1>
              <div className="bRewardMain bCard">
                <div className="bTitle">
                  <h2>Lucky Draw ลุ้นโชค</h2>
                </div>
                <div className="bRewardDetails">
                  <div className="bPoint">
                    <h2>{LuckydrawHistoryData.luckyDrawName}</h2>
                    <div>
                      <span>ใช้ Point ลุ้น</span>
                      <p>
                        {helper.FN.toNumberWithCommas(
                          LuckydrawHistoryData.luckyDrawPointToLuckyDraw
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="bProductImg">
                    <div className="bPic">
                      <img
                        src={
                          LuckydrawHistoryData.luckyDrawImg
                            ? LuckydrawHistoryData.luckyDrawImg
                            : "https://dummyimage.com/200x200/fff/000"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bTotalPoint">
                    <p>
                      จำนวนสิทธิ์ที่ลุ้น :{" "}
                      <span>
                        {helper.FN.toNumberWithCommas(
                          LuckydrawHistoryData.drawTimes
                        )}
                      </span>
                    </p>
                    <div className="bPoint">
                      <p>
                        คุณใช้
                        <span>
                          {helper.FN.toNumberWithCommas(
                            LuckydrawHistoryData.drawUsedPoint
                          )}
                        </span>
                        ในการลุ้น
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bCodeDate">
                  <div className="bRow">
                    <p>
                      <span>รหัสรางวัล:</span>
                      {/* 121564446 */}
                      {LuckydrawHistoryData.orderCode}
                    </p>
                    <p>
                      <span>วันที่/เวลาแลกของรางวัล:</span>
                      {/* 20/07/2566 12:21:01 น. */}
                      {helper.TIME.toShortDateTimeNormalTH(
                        LuckydrawHistoryData.createdOn
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bBtn">
                <a
                  onClick={() => navigate("/history-luckydraw")}
                  className="btn"
                >
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LuckydrawHistoryDetail;
