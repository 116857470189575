import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckSkuForm,
  CreatePointHistoryAndSticker,
} from "services/Api/Module/Customer";
import Menu from "pages/menuMain/menu";
import _ from "lodash";
import ModalContext from "contexts/ModalContext";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import ScanOCR from "utility/ocr";
import { setProfile } from "features/ProfileSlice";
import UploadReceipt from "./uploadReceipt";

const DEF_FORM_VALUE = [""];
const DEF_FORM_ERROR = [3];
const ScanPointNew = () => {
  const { mAlert } = useContext(ModalContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(DEF_FORM_VALUE);
  const [formErrors, setFormErrors] = useState(DEF_FORM_ERROR);
  const [disabledForm, setDisabledForm] = useState(false); // ปุ่มกด
  const [isLoading, setIsLoading] = useState(false);

  // profile state กลาง
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  // camera
  const [openCamera, setOpenCamera] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const addRowForm = () => {
    // VALUE
    let _formValues = _.cloneDeep(formValues);
    _formValues.push("");
    setFormValues(_formValues);

    // ERROR
    let _formErrors = _.cloneDeep(formErrors);
    _formErrors.push(3);
    setFormErrors(_formErrors);
  };

  const removeRowForm = (form = [], errorForm, index, value = "") => {
    let _form = _.cloneDeep(form);
    let _errorForm = _.cloneDeep(errorForm);

    let tmpValue = value ?? _form[index];
    if (tmpValue === "") {
      if (!value) {
        _errorForm = _errorForm.filter((item, i) => i !== index);
      }
    } else if (value.length < 13 || true) {
      _errorForm[index] = 3;
    } else {
      _errorForm[index] = 0;
    }
    return _errorForm;
  };

  const editFormValues = (form = [], value = "", index) => {
    let _form = _.cloneDeep(form);
    _form[index] = value;
    return _form;
  };

  const clearFormValues = (form = [], index) => {
    let _form = _.cloneDeep(form);
    if (_form[index]) {
      _form[index] = "";
    } else {
      _form = _form.filter((item, i) => i !== index);
    }
    return _form;
  };

  const validateForm = (notRequire = false) => {
    let isPass = true;
    const _formValues = _.cloneDeep(formValues);
    for (let i in _formValues) {
      const value = _formValues[i];

      if (notRequire === false) {
        // มี option แบบไม่เช็ค require
        if (!value) {
          // เช็ค require
          isPass = false;
          break;
        }
      }

      if (!(value.length == 13 || value.length == 14)) {
        isPass = false;
        break;
      }

      isPass = true;
    }
    // setDisabledForm(!isPass);
    return !isPass;
  };

  const postPointHistory = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await CreatePointHistoryAndSticker({ data: payload });
      if (data.resultCode === "20000") {
        _return.status = true;
        _return.result = data.resultData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {},
        });
      }
    } catch (e) {
      console.log("ERR : ", e);
    }
    return _return;
  };

  const prepareData = () => {
    const codeSkuData = _.cloneDeep(formValues);
    const payload = {
      customerId: customerId,
      pointEarnConfig: 2,
      pointType: 1,
      codeSku: codeSkuData,
    };
    return payload;
  };

  const savePointHistory = async () => {
    setIsLoading(true);
    const payload = prepareData();
    const result = await postPointHistory(payload);
    if (result.status) {
      dispatch(
        setProfile({
          ...stateProfile,
          currentPoint: result.result.currentPoint,
          actionPoint: result.result.point,
          countSticker: result.result.countSticker,
          stickerHistory: result.result.stickerHistory,
          actionHeart: result.result.heartHistory.amount ?? 0,
          currentHeart: result.result.heartHistory.currentHeart ?? 0,
          isSchedualGame: result.result.isSchedualGame,
          isSchedualSticker: result.result.isSchedualSticker,
        })
      );
      if (
        result.result.isSchedualSticker == 1 ||
        result.result.isSchedualGame == 1
      ) {
        setIsLoading(false);
        navigate("/character-process");
      } else {
        setIsLoading(false);
        navigate("/collectpoint");
      }
    }
  };

  const postCheckCodeForm = async (payload) => {
    let _return = {
      status: false,
      result: [],
    };

    try {
      const { data } = await CheckSkuForm({ data: payload });
      if (data.resultCode === "20000") {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const checkSkuFormAll = async (form = []) => {
    const payload = {
      codeSku: formValues,
    };
    const result = await postCheckCodeForm(payload);
    if (result.status) {
      const objSkuStatus = result.result;
      let _formValues = _.cloneDeep(formValues);
      let _formErrors = _.cloneDeep(formErrors);

      _formErrors = _formValues.map((item) => objSkuStatus[item]);
      setFormErrors(_formErrors);
    }
  };

  const checkDupSkuForm = (form = [], error = []) => {
    const _form = _.cloneDeep(form);
    const _error = _.cloneDeep(error);
    let isPass = true;
    for (let i in _form) {
      let v = form[i];
      let findDup = _.cloneDeep(form).filter((item, index) => item == v);
      if (findDup.length > 1) {
        isPass = false;
      }
    }
    return isPass;
  };

  const onSubmit = () => {
    savePointHistory();
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => checkSkuFormAll(), 400); // ยิง API หลังจาก พิมเสด 1 วิ
    return () => clearTimeout(timeOutId);
  }, [formValues]);

  const contBTN0 = validateForm();
  const contBTN1 = !(
    formErrors.filter((item) => item === 0).length === formErrors.length
  );
  const contBTN2 = !checkDupSkuForm(formValues);
  const disabledBTN = contBTN0 || contBTN1 || contBTN2;
  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageScan">
        <main>
          <div className="wrapContent">
            <CollectpointHeader />
            <section id="pScanCode">
              {/* <!-- เปิดตอน Phase 2 -->
            <!-- <div className="bLink">
              <a href="artcollection.php">สุ่ม Sticker collection</a>
            </div> --> */}
              <div className="bScanCode">
                <p className="tDetail">
                  กรอกรหัสสะสม Point พร้อมสุ่มรับ Character Collection
                  <br />
                  สะสมครบ!แลกเลยของรางวัลพิเศษ
                  <br />
                  ตั้งแต่วันที่ 1 พฤษภาคม - 30 พฤศจิกายน 2567
                  <br />
                  <b>*กรุณาเก็บกล่องเพื่อเป็นหลักฐานในการร่วมกิจกรรม*</b>
                </p>

                <h2>Scan point หรือกรอกรหัส</h2>
                <div className="bForm">
                  {formValues.map((item, index) => {
                    return (
                      <div className="bRow" key={index}>
                        <a
                          className="btnScan"
                          onClick={() => {
                            setOpenCamera(true);
                            setCurrentIndex(index);
                          }}
                        ></a>
                        <div
                          className={`control-group 
                          ${
                            formErrors[index] === 0
                              ? "success"
                              : [1, 2, 4].includes(formErrors[index])
                              ? "error"
                              : ""
                          }`}
                        >
                          {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ Class error / ถ้าผ่่าน ให้ใส่ Class success --> */}
                          <div>
                            <input
                              type="text"
                              placeholder="กรอกรหัสฝากล่อง"
                              value={formValues[index]}
                              onChange={(e) => {
                                console.log("e >>", e.target.value);
                                const updateForm = editFormValues(
                                  formValues,
                                  e.target.value,
                                  index
                                );
                                const deleteError = removeRowForm(
                                  formValues,
                                  formErrors,
                                  index,
                                  e.target.value
                                );
                                setFormValues(updateForm);
                                setFormErrors(deleteError);
                              }}
                              maxLength={14}
                            />
                            {index === 0 ? (
                              <></>
                            ) : (
                              <a
                                className="btnClear"
                                onClick={() => {
                                  const deleteForm = clearFormValues(
                                    formValues,
                                    index
                                  );
                                  const deleteError = removeRowForm(
                                    formValues,
                                    formErrors,
                                    index
                                  );
                                  setFormValues(deleteForm);
                                  setFormErrors(deleteError);
                                }}
                              ></a>
                            )}
                          </div>
                          <label for="" className="tError">
                            {formErrors[index] === 1 &&
                              "*รหัสนี้ถูกใช้งานไปแล้ว"}
                            {formErrors[index] === 2 && "*รหัสไม่ถูกต้อง"}
                          </label>
                        </div>
                      </div>
                    );
                  })}

                  <div className="bRow">
                    {formValues.length < 5 && (
                      <div
                        className="btnAdd"
                        onClick={() => addRowForm()}
                      ></div>
                    )}
                  </div>
                  <div className="bBtn">
                    <button
                      className="btn"
                      onClick={onSubmit}
                      disabled={disabledBTN || isLoading}
                    >
                      สะสมคะแนน
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <UploadReceipt />

            {openCamera ? (
              <ScanOCR
                // getCodePoint={handleRecognizedTextChange}
                onCapture={(value) => {
                  let _formValues = _.cloneDeep(formValues);
                  _formValues[currentIndex] = `${value}`.trim();
                  setFormValues(_formValues);

                  setOpenCamera(false);
                }}
                onClose={(value) => {
                  setOpenCamera(value);
                }}
              />
            ) : (
              <div></div>
            )}

            {/* <div className="apiScan"><div><div style="width: 50%; height: 12%; position: relative; border: 1px solid rgba(255, 0, 0, 0.7); margin: auto;"></div></div><video autoplay="" playsinline="" height="100%" width="375"></video><div><button>Back</button><div><label for="uploadImg">Upload image:</label><input id="uploadImg" type="file" accept="image/*" style="display: none;"><canvas style="display: none;"></canvas></div></div><div className="textLog">Text Log</div></div> --> */}
          </div>
        </main>
        <Menu />
      </div>
    </div>
  );
};

export default ScanPointNew;
