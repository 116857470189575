import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { dateNumber, monthNames, year } from "utility/date";
import { AddressInfo, UpdateCustomer } from "services/Api/Module/Customer";
import { useDispatch, useSelector } from "react-redux";
import helper from "services/helper";
import { setAddress } from "features/ProfileSlice";
import ModalAlert from "components/modal/ModalAlert";
import ModalCancel from "./modalCancelEdit";
import ModalSuccess from "./modalSuccess";
import { PatternFormat } from "react-number-format";
import $ from "jquery";
import moment from "moment";

const EditProfileForm = () => {
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const stateAddress = useSelector((state) => state.profile.address);
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;
  const [defaultAddress, setDefaultAddress] = useState({});
  const [cusUpdate, setCusUpdate] = useState(null);
  const [openSc, setOpenSc] = useState(false); // ยืนยัน
  const [openCc, setOpenCc] = useState(false); // ยกเลิก
  const [disabled, setDisabled] = useState(true);
  // const [maxDate, setMaxDate] = useState("");
  const maxDate = moment().add(-10, "year").format("YYYY-MM-DD"); // 10 ปีที่แล้ว
  const [addressInfo, setAddressInfo] = useState({
    province: [],
    district: [],
    subDistrict: [],
  });

  const convertAddress = (_stateAddress = {}) => {
    let address = { subDistrict: null, district: null, province: null };
    if (_stateAddress) {
      const subdistrict = _stateAddress.subDistrict.filter(
        (item) => item.id === stateProfile.subDistrict
      );
      address.subDistrict = subdistrict[0].id;
      const districtId = subdistrict[0].districtId;
      const district = _stateAddress.district.filter(
        (item) => item.id === districtId
      );
      address.district = district[0].id;
      const provinceId = district[0].provinceId;
      const province = _stateAddress.province.filter(
        (item) => item.id === provinceId
      );
      address.province = province[0].id;
    }

    // dispatch(setAddress(address))
    // setDefaultAddress(address)
    return address;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const years = year(2510);
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      mobileNo: "",
      genderId: "",
      address: "",
      province: "",
      district: "",
      subDistrict: "",
      postcode: "",
      birthDate: "",
    },
  });
  const testGet = getValues();
  console.log("errors :", errors);

  const getAdderssInfo = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await AddressInfo();
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  useEffect(() => {
    // fixMaxYear();
    const fetchAddress = async () => {
      try {
        const dataAddressInfo = await getAdderssInfo();
        dispatch(setAddress(dataAddressInfo.result));
        setAddressInfo(dataAddressInfo.result);
        const memberAddress = convertAddress(dataAddressInfo.result);
        const { province, district, subDistrict } = memberAddress;
        setValue("province", province);
        setValue("district", district);
        setValue("subDistrict", subDistrict);
        setValue(
          "birthDate",
          moment(stateProfile.birthDate).format("YYYY-MM-DD")
        );
        setValue("genderId", stateProfile.genderId.toString());
        setValue("mobileNo", helper.FORMAT.TelToNumber(stateProfile.mobileNo));
        setValue("firstName", stateProfile.firstName);
        setValue("lastName", stateProfile.lastName);
        setValue("address", stateProfile.address);
        setValue("postcode", stateProfile.postcode);
        validateForm();
      } catch (err) {
        console.log(err);
      }
    };
    fetchAddress();
  }, [dispatch]);

  useEffect(() => {
    $(".btnCopy").on("click", function () {
      var textToCopy = $(this).text();

      // Create a temporary input element
      var tempInput = $("<input>");
      $("body").append(tempInput);
      tempInput.val(textToCopy).select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the temporary input
      tempInput.remove();

      $("body").addClass("copy");
      setTimeout(() => {
        $("body").removeClass("copy");
      }, 1000);
    });

    // Don't forget to clean up any resources when the component unmounts
    return () => {
      $(".btnCopy").off("click");
    };
  }, []);

  // ------------call update api -----------
  const updateCustomer = async (id, payload) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await UpdateCustomer({ id: id, data: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        ModalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  const fixMaxYear = () => {
    const currentDate = new Date();
    const maxDateValue = new Date(
      currentDate.getFullYear() - 10,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    // แปลง maxDateValue เป็นรูปแบบ YYYY-MM-DD
    const formattedMaxDate = maxDateValue.toISOString().split("T")[0];
    const result = formattedMaxDate.toString();
    // กำหนดค่า maxDate
    // setMaxDate(result);
  };

  // /----------------update fn-------------------

  const onSubmit = (data) => {
    console.log("getUpdateValue =>", data);
  };

  const valForm = getValues();

  // const validateForm = () => {
  //   const valForm = getValues();
  //   if (
  //     valForm.firstName &&
  //     valForm.lastName &&
  //     valForm.mobileNo &&
  //     valForm.genderId &&
  //     valForm.address &&
  //     valForm.province &&
  //     valForm.district &&
  //     valForm.subDistrict &&
  //     valForm.postcode &&
  //     valForm.birthDate
  //   ) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // };

  const validateForm = () => {
    const valForm = getValues();

    let validateFields = [
      "firstName",
      "lastName",
      "mobileNo",
      "birthDate",
      "address",
      "province",
      "district",
      "subDistrict",
      "postcode",
    ]; // เช็คฟิลไหนบ้าง
    let isPass = false;
    for (let k of validateFields) {
      console.log("🚀 ~ validateForm ~ k:", k);
      if (!valForm[k]) {
        // เช็ค require
        isPass = false;
        console.log("🚀 ~ validateForm ~ k A:", k, isPass);
        break;
      }

      if (valForm[k]) {
        // เช็คเงื่อนไข อื่น
        if (k === "mobileNo" && valForm[k].length !== 10) {
          // เช็ค require
          isPass = false;
          console.log(
            "🚀 ~ validateForm mobileNo ~ kB:",
            k,
            isPass,
            valForm[k]
          );
          break;
        }
        if (
          k === "birthDate" &&
          moment(valForm[k]).format("YYYY-MM-DD") > maxDate
        ) {
          isPass = false;
          console.log(
            "🚀 ~ validateForm birthDate min 10 year ~ kB:",
            k,
            isPass
          );
          break;
        }
      }

      isPass = true;
    }
    setDisabled(!isPass);
  };

  // ---------Prepare date------------
  const prepareData = () => {
    const {
      firstName,
      lastName,
      mobileNo,
      genderId,
      address,
      province,
      district,
      subDistrict,
      postcode,
      birthDate,
    } = getValues();
    const dataForUpdate = {
      firstName: firstName,
      lastName: lastName,
      mobileNo: mobileNo.replace(/-/g, ""),
      genderId: genderId,
      address: address,
      province: province,
      district: district,
      subDistrict: subDistrict,
      postcode: postcode,
      birthDate: birthDate,
    };
    return dataForUpdate;
  };

  const updateCus = async () => {
    const payload = prepareData();
    const result = await updateCustomer(stateProfile.id, payload);
    setOpenSc(true);
  };

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageProfile-edit">
        <div className="wrapContent">
          <section id="pPointTop">
            {/* <a className="btnIconBack" onclick="goBack()"></a> */}
            <div className="bPoint">
              <p>Koala’s March point ของคุณ</p>
              <div className="bTotalPoint">
                <span>{newCurrentPoint ?? "0"}</span>
              </div>
              <p>หมดอายุ 31 ธันวาคม {Number(moment().format("YYYY")) + 543}</p>
            </div>
          </section>
          <section id="pEditProfileMain">
            <div className="bEdit">
              <div className="bDisplay">
                <div
                  className="bPic"
                  style={{
                    backgroundImage: stateProfile.lineImageUrl
                      ? `url(${stateProfile.lineImageUrl})`
                      : `url(${stateProfile.pProfileImgUrl})`,
                  }}
                ></div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bForm">
                  <div className="bGroup">
                    <h2>ข้อมูลทั่วไป</h2>
                    <div className="bRow">
                      <div className="control-group validate">
                        {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ ClassName error --> */}
                        <div className="tTitle">ชื่อ</div>
                        <Controller
                          name="firstName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "กรุณากรอกชื่อ",
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder="กรอกชื่อ"
                              onInput={helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                validateForm();
                              }}
                            />
                          )}
                        />
                        {errors.firstName && (
                          <label htmlFor="" className="tError">
                            {errors.firstName.message}
                          </label>
                        )}
                      </div>
                      <div className="control-group validate">
                        {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ ClassName error --> */}
                        <div className="tTitle">นามสกุล</div>
                        <Controller
                          name="lastName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "กรุณากรอกนามสกุล",
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder="กรอกนามสกุล"
                              onInput={helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                validateForm();
                              }}
                            />
                          )}
                        />
                        {errors.lastName && (
                          <label htmlFor="" className="tError">
                            {errors.lastName.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group validate">
                        {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ ClassName error --> */}
                        <div className="tTitle">เบอร์โทรศัพท์</div>

                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "กรุณากรอกเบอร์โทรศัพท์",
                            // pattern: {
                            //   // value: /^\d{10}$/,
                            //   value: /^\d{3}-\d{3}-\d{4}$/,
                            //   message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง", //"Invalid telephone number format",
                            // },
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              data-icon="phone"
                              type="text"
                              inputmode="numeric"
                              placeholder="กรุณากรอกหมายเลขโทรศัพท์ให้ครบ 10 หลัก"
                              onInput={helper.FNFORM.handleNumberOnly}
                              onChange={({ target: { value } }) => {
                                // if(value.length <10){

                                // }else if(value.length = 10){
                                //   onChange(value);
                                //   validateForm();
                                // }
                                if (value.length <= 10) {
                                  onChange(value);
                                  validateForm();
                                }
                                // checkOnChangeField();
                              }}
                            />
                          )}
                          // render={({ field: { name, value, onChange } }) => (
                          //   <PatternFormat
                          //     data-icon="phone"
                          //     name={name}
                          //     value={value}
                          //     displayType="input"
                          //     format="###-###-####"
                          //     placeholder="กรุณากรอกหมายเลขโทรศัพท์ให้ครบ 10 หลัก"
                          //     onChange={({ target: { value } }) => {
                          //       onChange(value);
                          //       validateForm();
                          //       // checkOnChangeField();
                          //     }}
                          //   />
                          // )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group date validate
                        ${
                          errors.birthDate ||
                          (!!watch("birthDate") &&
                            moment(watch("birthDate")).format("YYYY-MM-DD") >
                              maxDate)
                            ? "error"
                            : ""
                        }`}
                      >
                        {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ ClassName error --> */}
                        <div className="tTitle">วัน/เดือน/ปีเกิด</div>
                        <p>อายุต่ำกว่า 10 ปีไม่สามารถเข้าร่วมกิจกรรมได้</p>
                        <Controller
                          name="birthDate"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "กรุณาเลือกวัน",
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                validateForm();
                              }}
                              data-icon="birthday"
                              type="date"
                              max={maxDate}
                            />
                          )}
                        />

                        <label for="" className="tError">
                          {moment(watch("birthDate")).format("YYYY-MM-DD") >
                          maxDate
                            ? "กรณีกรอกอายุต่ำกว่า 10 ปี ไม่สามารถเข้าร่วมกิจกรรมได้"
                            : "กรุณากรอกวันเกิด"}
                        </label>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group sex validate">
                        <div
                          className={`control-group ${
                            errors.genderId ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">เพศ</div>
                          <div className="gSex">
                            <label className="control control--radio">
                              <Controller
                                name="genderId"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "กรุณาเลือกเพศ",
                                }}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="radio"
                                    value="0"
                                    checked={watch("genderId") === "0"}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      validateForm();
                                    }}
                                  />
                                )}
                              />
                              <span data-icon="male">ชาย</span>
                              <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio">
                              <Controller
                                name="genderId"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "กรุณาเลือกเพศ",
                                }}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="radio"
                                    value="1"
                                    checked={watch("genderId") === "1"}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      validateForm();
                                    }}
                                  />
                                )}
                              />
                              <span data-icon="female">หญิง</span>
                              <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio">
                              <Controller
                                name="genderId"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "กรุณาเลือกเพศ",
                                }}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="radio"
                                    value="2"
                                    checked={watch("genderId") === "2"}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      validateForm();
                                    }}
                                  />
                                )}
                              />
                              <span data-icon="none">ไม่ระบุ</span>
                              <div className="control__indicator"></div>
                            </label>
                            {errors.genderId && (
                              <label htmlFor="" className="tError">
                                {errors.genderId.message}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bMemberID">
                      <span>Member ID</span>
                      <p data-icon="brand">
                        <a className="btnCopy">{stateProfile.memberId}</a>
                      </p>
                    </div>
                  </div>
                  <div className="bGroup">
                    <h2>ที่อยู่</h2>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.address ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">ที่อยู่</div>
                        {/* <p>กรอกที่อยู่เพื่อสิทธิ์รับของรางวัล</p> */}
                        <Controller
                          name="address"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "กรุณากรอกที่อยู่",
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <textarea
                              {...field}
                              rows="3"
                              placeholder="บ้านเลขที่ คอนโดมิเนียม/หมู่บ้าน หมู่ที่ ถนน ซอย"
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                validateForm();
                                // checkOnChangeField();
                              }}
                            />
                          )}
                        />
                        {errors.address && (
                          <label htmlFor="" className="tError">
                            {errors.address.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.province ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">จังหวัด</div>
                        <div className="select">
                          <Controller
                            name="province"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณาเลือกจังหวัด",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <select
                                {...field}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  setValue("district", "");
                                  setValue("subDistrict", "");
                                  setValue("postcode", "");
                                  validateForm();
                                }}
                              >
                                <option value="" disabled>
                                  จังหวัด
                                </option>
                                {addressInfo.province.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          />
                        </div>
                        {errors.province && (
                          <label htmlFor="" className="tError">
                            {errors.province.message}
                          </label>
                        )}
                      </div>
                      <div
                        className={`control-group validate ${
                          errors.district ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เขต/อำเภอ</div>
                        <div className="select">
                          <Controller
                            name="district"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณาเลือกเขต/อำเภอ",
                            }}
                            disabled={!watch("province")}
                            render={({ field: { onChange, ...field } }) => (
                              <select
                                {...field}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  setValue("subDistrict", "");
                                  setValue("postcode", "");
                                  validateForm();
                                  // checkOnChangeField();
                                }}
                              >
                                <option value="" disabled>
                                  เขต/อำเภอ
                                </option>
                                {addressInfo.district
                                  .filter(
                                    (item) =>
                                      item.provinceId === watch("province")
                                  )
                                  .map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            )}
                          />
                        </div>
                        {errors.district && (
                          <label htmlFor="" className="tError">
                            {errors.district.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.subDistrict ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">แขวง/ตำบล</div>
                        <div className="select">
                          <Controller
                            name="subDistrict"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณาเลือกแขวง/ตำบล",
                            }}
                            disabled={!watch("district")}
                            render={({ field: { onChange, ...field } }) => (
                              <select
                                {...field}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  setValue("postcode", "");
                                  validateForm();
                                  const { subDistrict } = addressInfo;
                                  const _postcode =
                                    subDistrict.find((item) => item.id == value)
                                      ?.postCode ?? "";
                                  if (_postcode) {
                                    setValue("postcode", _postcode);
                                  }
                                  validateForm();
                                  // checkOnChangeField();
                                }}
                              >
                                <option value="" disabled>
                                  แขวง/ตำบล
                                </option>
                                {addressInfo.subDistrict
                                  .filter(
                                    (item) =>
                                      item.districtId === watch("district")
                                  )
                                  .map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            )}
                          />
                          {errors.subDistrict && (
                            <label htmlFor="" className="tError">
                              {errors.subDistrict.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="control-group">
                        <div className="tTitle">รหัสไปรษณีย์</div>
                        <Controller
                          name="postcode"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "กรุณากรอกรหัสไปรษณีย์",
                          }}
                          disabled={true}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              inputmode="numeric"
                              placeholder="ไปรษณีย์"
                              onInput={helper.FNFORM.handleNumberOnly}
                              onChange={({ target: { value } }) => {
                                if (value.length <= 5) {
                                  onChange(value);
                                  validateForm();
                                }
                                // checkOnChangeField();
                              }}
                            />
                          )}
                        />
                        {errors.postcode && (
                          <label htmlFor="" className="tError">
                            {errors.postcode.message}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bBtn">
                    <button
                      className="btn"
                      id="btnModalConfirm"
                      onClick={updateCus}
                      disabled={disabled}
                    >
                      ยืนยันการเปลี่ยนแปลง
                    </button>

                    <button
                      className="btn outline"
                      id="btnModalCancelEdit"
                      onClick={() => {
                        setOpenCc(true);
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
      <ModalCancel
        isOpen={openCc}
        onClose={() => setOpenCc(false)}
        onCloseConfirm={() => {
          setOpenCc(false);
          navigate("/profile-view");
        }}
      />
      <ModalSuccess
        isOpen={openSc}
        onClose={() => {
          setOpenSc(false);
          navigate("/profile");
        }}
      />
    </div>
  );
};

export default EditProfileForm;
