/*  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Menu from "pages/menuMain/menu";
import _ from "lodash";
import { CharacterStickerDetail } from "services/Api/Module/CharacterSticker";
import ThemeComponent from "./ThemeComponent";

const CharacterDetail = () => {
  const [characterDetail, setCharacterDetail] = useState([]);
  const [themeName, setThemeName] = useState("");
  const [countStickerConfig, setCountStickerConfig] = useState(0);
  const [countStickerHistory, setCountStickerHistory] = useState(0);

  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const themeId = searchParams.get("id");

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customer = stateProfile.id;

  console.log("characterCollection >>>>", characterDetail);

  const getCharacterDetail = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CharacterStickerDetail({
        params: { customer: customer, theme: themeId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchCharacterDetail = async () => {
    try {
      const resData = await getCharacterDetail();
      setCharacterDetail(resData.result.stickerConfig);
      setThemeName(resData.result.themeName);
      setCountStickerConfig(resData.result.countStickerConfig);
      setCountStickerHistory(resData.result.countStickerHistory);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCharacterDetail();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageCharacter-detail">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section className="bHead">
              <div className="tHead">
                <a onClick={() => navigate(-1)}>
                  <img src="assets/img/ci/phase2/character-collection/ico-arrow-back.svg" />
                </a>
                <p className="">{themeName}</p>
                <div className="dummy"></div>
              </div>
            </section>
            <section id="pAllCharacter">
              <p>Koala’s Theme : {themeName}</p>
              <p>
                คุณสะสม Character Collection แล้ว :{" "}
                <span>
                  {countStickerHistory}/{countStickerConfig}
                </span>
              </p>

              <div className="bAllCharacter">
                {/* <!-- data-collect="1"(1 = มีแล้ว , 0 = ยังไม่มี) data-dup="4"(จำนวนที่ซ้ำ) --> */}
                {/* <?php for ($x = 1; $x <=213 ; $x++) {?> */}
                {characterDetail.map((item) => {
                  return (
                    <div
                      className="bSticker"
                      data-character={item.imgPath}
                      data-collect={item.isHaveSticker}
                      data-dup={item.amount}
                    ></div>
                  );
                })}
              </div>
            </section>

            <section id="pMenuMain">
              <div className="bBack">
                <div className="bBtn">
                  <a onClick={() => navigate(-1)} className="btn">
                    กลับหน้า Character Collection
                  </a>
                  {/* <!-- ใส่ class disabled เพื่อเป็นปุ่มเทา --> */}
                </div>
              </div>
            </section>

            {/* <!-- Menu -->
          <?php include 'inc/inc-btn-fix.php';?>

          <!-- Modal -->
          <?php include 'inc/inc-modal.php';?> */}
          </div>
        </main>
      </div>
      {/* <ModalConfirmLuckydraw
        isOpen={openCf}
        onConfirm={() => {
          // navigate('/reward-complete')
          onSubmit();
        }}
        onClose={() => setOpenCf(false)}
      /> */}
    </div>
  );
};

export default CharacterDetail;
