/*  */
import moment from "moment";
import GetCurrentPoint from "pages/profile/GetCurrentPoint";

const { useSelector } = require("react-redux");

const CollectpointHeader = () => {
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;
  const newCurrentHeart = stateCurrentPoint.currentHeart;
  const isSchedualGame = stateCurrentPoint.isSchedualGame == 1 ? true : false;

  const openListenerTips = (e) => {
    if (e?.target?.id === "iconInfo") {
      document.getElementById("info-upper").classList.remove("hide");
    }
  };

  const closeListenerTips = (e) => {
    if (e?.target?.className === "closeBtn") {
      document.getElementById("info-upper").classList.add("hide");
    }
  };
  return (
    <>
      <GetCurrentPoint />
      <section id="pPointTop">
        <h1 className="bTitle">Collect points</h1>
        <div className="bPoint">
          <div className="bTypePoint">
            <div className="bPointGroup">
              <p>คุณมี Point</p>
              <div className="bTotalPoint">
                <span>{newCurrentPoint ?? "0"}</span>
              </div>
            </div>
            {isSchedualGame && (
              <div className="bPointGroup">
                <div className="tNote">
                  <p>คุณมี Heart</p>
                  <a className="sym">
                    <img
                      id="iconInfo"
                      src="assets/img/ci/phase2/ico-description.svg"
                      onClick={openListenerTips}
                    />
                  </a>
                </div>
                <div className="bTotalPointHeart">
                  <span>{newCurrentHeart ?? "0"}</span>
                </div>

                <div className="bNotice hide" id="info-upper">
                  <div className="tHead">
                    <div className="closeBtn" onClick={closeListenerTips}></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <p>หมดอายุ 31 ธันวาคม {Number(moment().format("YYYY")) + 543}</p>
        </div>
      </section>
    </>
  );
};

export default CollectpointHeader;
