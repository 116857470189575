import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Menu = () => {
  const { pathname } = useLocation();
  const nevigate = useNavigate();

  // const handleClick = (part) => {
  //   navigate(`/${part}`)
  //   setActive(true)
  // }

  const setActive = (pages = [], path) => {
    if (pages.includes(path)) {
      return " active ";
    }
    return "";
  };
  const navigate = useNavigate();
  return (
    <section id="pMenuMain">
      <div className="bMenu">
        <a
          className={`scan ${setActive(
            ["/scanpoint", "/collectpoint"],
            pathname
          )}`}
          onClick={() => navigate("/scanpoint")}
        >
          Scan
        </a>
        <a
          className={`reward ${setActive(["/reward"], pathname)}`}
          onClick={() => navigate("/reward")}
        >
          Rewards
        </a>
        <a
          className={`luckydraw ${setActive(
            ["/luckydraw", "/luckydraw-complete"],
            pathname
          )}`}
          onClick={() => navigate("/luckydraw")}
        >
          Lucky draw
        </a>
        <a
          className={`profile ${setActive(
            [
              "/profile",
              "/profile-view",
              "/history-reward",
              "/history-luckydraw",
              "/history-receipt",
              "/history-collect-point",
              "/history-pillow",
            ],
            pathname
          )}`}
          onClick={() => navigate("/profile")}
        >
          Profile
        </a>
      </div>
    </section>
  );
};

export default Menu;
