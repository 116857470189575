import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalCancel = ({ isOpen = false, onClose, onConfirm }) => {
  const [modalRef, setModalRef] = useState(null);

  useEffect(() => {
    if (modalRef && isOpen) {
      openModal();
      listenerConfirmModal();
      listenerCloseModal();
    }
  }, [modalRef, isOpen]);

  useEffect(() => {
    const initModalRef = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalCancelEditAddress"),
    });
    setModalRef(initModalRef);

    return () => {
      initModalRef.destroy();
    };
  }, []);

  const listenerConfirmModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModalCF");
    btnCloseModal.addEventListener("click", () => {
      onConfirm();
      closeModal(); // ระวังบางหน้าไม่มี
    });
  };

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModalC");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
    const btnCloseModal2 = document.getElementById("btnCloseModalC2");
    btnCloseModal2.addEventListener("click", () => {
      closeModal();
    });
  };

  const openModal = () => {
    modalRef.open();
  };

  const closeModal = () => {
    modalRef.close();
    onClose();
  };

  return (
    // <div className="bModal bCard" id="ModalConfirm" style={{ display: "none" }}>
    //   <div className="bInner">
    //     <div className="bDetails">
    //       <p className="tDetails">บันทึกข้อมูลเรียบร้อยแล้ว</p>
    //     </div>
    //     <div className="bBtn">
    //       <a onClick={closeModal} className="btn" id="btnCloseModal">
    //         ตกลง
    //       </a>
    //     </div>
    //   </div>
    // </div>
    // <!-- Modal Cancel Edit Address -->
    <div
      className="bModal bCard"
      id="ModalCancelEditAddress"
      style={{ display: "none" }}
    >
      <div className="bInner">
        <div className="bDetails">
          <i className="icoAlert"></i>
          <p className="tTitle">ยกเลิกการแก้ไข</p>
          <p className="tDetails">
            หากคุณกดยกเลิก
            <br /> ข้อมูลที่แก้ไขทั้งหมดจะไม่ถูกบันทึก <br />
            คุณยังยืนยันการยกเลิกอยู่ไหม?
          </p>
        </div>
        <div className="bBtn">
          <a className="btn red btnHideEditAddress" id="btnCloseModalCF">
            ยืนยันการยกเลิก
          </a>
          <a className="btn outline red btnModalCancel" id="btnCloseModalC">
            ไม่ต้องการยกเลิก
          </a>
        </div>
        <a className="btnCloseModal2" id="btnCloseModalC2"></a>
      </div>
    </div>
  );
};

export default ModalCancel;
