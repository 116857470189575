/*  */
import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalLucky = ({ isOpen = false, onClose }) => {
  const [modalRef, setModalRef] = useState(null);

  useEffect(() => {
    if (modalRef && isOpen) {
      openModal();
      // listenerConfirmModal();
      listenerCloseModal();
    }
  }, [modalRef, isOpen]);

  useEffect(() => {
    const initModalRef = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalLucky"),
    });
    setModalRef(initModalRef);

    return () => {
      initModalRef.destroy();
    };
  }, []);

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModalLucky");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  const openModal = () => {
    modalRef.open();
  };

  const closeModal = () => {
    modalRef.close();
    onClose();
  };

  return (
    <div className="bModal bCard" id="ModalLucky" style={{ display: "none" }}>
      <div className="btnCloseModal" id="btnCloseModalLucky">
        <img
          src="./assets/img/ci/phase2/ico-collectpoint/ico-close.svg"
          alt=""
        />
      </div>
      <div className="bInner">
        <div className="bText">
          <h2 className="tHead">ยินดีด้วย!</h2>
          <p>
            คุณได้ <span>Lucky Koala</span> <br />
            รับเลยทันที! ของรางวัลสุดพิเศษ
          </p>
        </div>
        <img src="assets/img/ci/phase2/ico-collectpoint/ico-aniversary.svg" />
        <p>กดรับของรางวัลที่ Character Collection</p>
      </div>
    </div>
  );
};

export default ModalLucky;
