import React, { useEffect, useContext, useState } from "react";

// import QRCode from "react-qr-code";

const Pagination = ({
  limit = 10,
  offset,
  rowCount,
  onBack = () => {
    console.log("onBack!");
  },
  onNext = () => {
    console.log("onNext!");
  },
}) => {
  const maxPage = Math.ceil(rowCount / limit);
  const currentPage = Math.floor(offset / limit) + 1;

  const onFnBack = (_currentPage, _limit) => {
    console.log("onFnBack!");
    const { limit, offset } = convertPageToLimitOffset(
      _currentPage - 1,
      _limit
    );
    console.log("offset >>", offset);
    onBack(limit, offset);
  };
  const onFnNext = (_currentPage, _limit) => {
    console.log("onFnNext!");
    const { limit, offset } = convertPageToLimitOffset(
      _currentPage + 1,
      _limit
    );
    console.log("offset >>", offset);
    onNext(limit, offset);
  };

  return (
    <div className="bPagination">
      <nav>
        {currentPage === 1 ? (
          <a></a>
        ) : (
          <a
            className="btnPrev"
            onClick={() => onFnBack(currentPage, limit)}
          ></a>
        )}
        <p>
          หน้า {currentPage}/{maxPage}
        </p>
        {currentPage === maxPage ? (
          <a></a>
        ) : (
          <a
            className="btnNext"
            onClick={() => onFnNext(currentPage, limit)}
          ></a>
        )}
      </nav>
    </div>
  );
};

export default Pagination;

function convertPageToLimitOffset(pageNumber, limit) {
  const offset = (pageNumber - 1) * limit;
  return { limit, offset };
}
