import React, { useState, useEffect } from "react";
import _ from "lodash";
import liff from "@line/liff";

const ScanQRCode = ({ onSave = null, onError = null }) => {
  const scanQRCode = async () => {
    try {
      const result = await liff.scanCodeV2(); // เรียกใช้ฟังก์ชัน scanCode
      onSave(result?.value ?? "");
    } catch (error) {
      onError("ERR");
      console.error(error); // จัดการข้อผิดพลาด (ถ้ามี)
    }
  };
  return (
    <div className="bBtn">
      <button
        id="btnCamera"
        type="button"
        onClick={() => {
          scanQRCode();
        }}
      ></button>
    </div>
  );
};

export default ScanQRCode;
