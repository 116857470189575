import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { RewardView, CreateReward } from "services/Api/Module/Reward";
import helper from "services/helper";
import { Controller, useForm } from "react-hook-form";
import { AddressInfo } from "services/Api/Module/Customer";
import ModalCancel from "./ModalCancel";
import ModalConfirmExchange from "./ModalConfirmExchange";
import ModalAlert from "components/modal/ModalAlert";
import GetCurrentPoint from "./GetCurrentPoint";
import ModalRewardEmpty from "./ModalRewardEmpty";

const defAddressInfo = {
  province: [],
  district: [],
  subDistrict: [],
};
const defForm = {
  firstName: "",
  lastName: "",
  mobileNo: "",
  address: "",
  province: "",
  district: "",
  subDistrict: "",
  postcode: "",
};
const RewardConfirm = () => {
  const [rewardData, setRewardData] = useState({});
  const [isAccept, setIsAccept] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [addressInfo, setAddressInfo] = useState(defAddressInfo);
  const [tmpForm, setTmpForm] = useState(defForm);
  const [disabledForm, setDisabledForm] = useState(false);
  const [notEnoughPoints, setNotEnoughPoints] = useState(false);
  const navigate = useNavigate();

  // Popup State
  const [openCf, setOpenCf] = useState(false); // แลกรางวัล
  const [openCc, setOpenCc] = useState(false); // ยกเลิก
  const [openEmpty, setOpenEmpty] = useState(false); // หมด
  const [isLoading, setIsLoading] = useState(false);

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;

  let [searchParams, setSearchParams] = useSearchParams();
  const rewardId = searchParams.get("id");

  const currentPoint = newCurrentPoint ?? stateProfile.currentPoint ?? 0;

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      mobileNo: "",
      address: "",
      province: "",
      district: "",
      subDistrict: "",
      postcode: "",
    },
  });

  const getRewardView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await RewardView({
        rewardId: rewardId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      } else {
        navigate("/reward");
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchRewardView = async () => {
    try {
      const resData = await getRewardView();

      setRewardData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  const getAdderssInfo = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await AddressInfo();
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchAdderssInfo = async (callBack = () => console.log("cb")) => {
    try {
      const resData = await getAdderssInfo();
      setAddressInfo(resData.result);
      callBack(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  const postCreateReward = async (payload = {}) => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await CreateReward({ data: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      } else {
        setNotEnoughPoints(true);
        setOpenEmpty(true);
        // let _title = "เกิดข้อผิดพลาด",
        //   _subTitle = "กรุณาลองใหม่อีกครั้ง";
        // ModalAlert.info({
        //   open: true,
        //   title: _title,
        //   subTitle: _subTitle,
        //   content: "",
        // });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  const cCus = async () => {
    // setOpenSc(true);
  };

  const validateForm = () => {
    const valForm = getValues();
    let validateFields = [
      "firstName",
      "lastName",
      "mobileNo",
      "address",
      "province",
      "district",
      "subDistrict",
      "postcode",
    ]; // เช็คฟิลไหนบ้าง
    let isPass = false;
    for (let k of validateFields) {
      if (!valForm[k]) {
        // เช็ค require
        isPass = false;

        break;
      }

      if (valForm[k]) {
        // เช็คเงื่อนไข อื่น
        if (k === "mobileNo" && valForm[k].length !== 10) {
          // เช็ค require
          isPass = false;

          break;
        }
      }

      isPass = true;
    }
    setDisabledForm(!isPass);
  };

  const convertAddress = (_stateAddress = {}) => {
    let address = { subDistrict: null, district: null, province: null };
    if (_stateAddress) {
      const subdistrict = _stateAddress.subDistrict.filter(
        (item) => item.id == stateProfile.subDistrict
      );
      address.subDistrict = subdistrict[0].id;
      const districtId = subdistrict[0].districtId;
      const district = _stateAddress.district.filter(
        (item) => item.id == districtId
      );
      address.district = district[0].id;
      const provinceId = district[0].provinceId;
      const province = _stateAddress.province.filter(
        (item) => item.id == provinceId
      );
      address.province = province[0].id;
    }

    // dispatch(setAddress(address))
    // setDefaultAddress(address)
    return address;
  };

  const toAddressText = (
    _addressInfo = defAddressInfo,
    province = null,
    district = null,
    subDistrict = null,
    address = "",
    postcode = ""
  ) => {
    const provinceText =
      _addressInfo.province.find((item) => item.id == province)?.name ?? "";
    const districtText =
      _addressInfo.district.find((item) => item.id == district)?.name ?? "";
    const subDistrictText =
      _addressInfo.subDistrict.find((item) => item.id == subDistrict)?.name ??
      "";
    return `${address} ${subDistrictText} ${districtText} ${provinceText} ${postcode}`;
  };

  const setPreFillForm = (dataAddressInfo = []) => {
    const memberAddress = convertAddress(dataAddressInfo); // province กับ district
    const { province, district, subDistrict } = memberAddress;

    setValue("firstName", stateProfile.firstName);
    setValue("lastName", stateProfile.lastName);
    setValue("mobileNo", helper.FORMAT.TelToNumber(stateProfile.mobileNo));
    setValue("address", stateProfile.address);

    setValue("province", province);
    setValue("district", district);
    setValue("subDistrict", stateProfile.subDistrict);
    setValue("postcode", stateProfile.postcode);
  };

  useEffect(() => {
    // setPreFillForm();
    fetchAdderssInfo((adInfo) => setPreFillForm(adInfo));
    fetchRewardView();
  }, [stateProfile]);

  useEffect(() => {
    if (isEdit) {
      const tmpData = {
        firstName: `${watch("firstName")}`,
        lastName: `${watch("lastName")}`,
        mobileNo: `${watch("mobileNo")}`,
        address: `${watch("address")}`,
        province: `${watch("province")}`,
        district: `${watch("district")}`,
        subDistrict: `${watch("subDistrict")}`,
        postcode: `${watch("postcode")}`,
      };
      setTmpForm(tmpData);
    } else {
      setTmpForm(defForm);
    }
  }, [isEdit]);

  const resetForm = () => {
    setValue("firstName", tmpForm.firstName);
    setValue("lastName", tmpForm.lastName);
    setValue("mobileNo", tmpForm.mobileNo);
    setValue("address", tmpForm.address);

    setValue("province", tmpForm.province);
    setValue("district", tmpForm.district);
    setValue("subDistrict", tmpForm.subDistrict);
    setValue("postcode", tmpForm.postcode);
    setIsEdit(false);
  };

  const saveForm = () => {
    setIsEdit(false);
  };
  // ---------Prepare data------------
  const prepareData = () => {
    const payload = {
      redeemConfig: rewardId,
      customer: stateProfile.id,
      redeemUsedPoint: rewardData.pointToRedeem,
      firstName: `${watch("firstName")}`,
      lastName: `${watch("lastName")}`,
      mobileNo: `${watch("mobileNo")}`,
      address: `${watch("address")}`,
      subDistrict: `${watch("subDistrict")}`,
      postcode: `${watch("postcode")}`,
    };
    return payload;
  };

  const saveCreateReward = async () => {
    const payload = prepareData();
    const result = await postCreateReward(payload);
    if (result.status) {
      navigate("/reward-complete?id=" + result.result?.rewardHistoryId);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    saveCreateReward();
  };

  const contBTN0 = !isAccept; // กรณีไม่ checkboox
  const contBTN1 = !(currentPoint >= rewardData.pointToRedeem); // กรณี point ไม่พอ
  const contBTN2 = !(rewardData.total > rewardData.available); // กรณี point ไม่พอ
  const disabledBTN = contBTN0 || contBTN1 || contBTN2;
  return (
    <div>
      {/* newCurrentPoint:: {newCurrentPoint} */}
      <GetCurrentPoint />
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageReward-Details-Confirm">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate(`/reward-details?id=${rewardId}`)}
                ></a>
                <h2>Rewards</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bAddressMain bCard">
                <div className="bTitle">
                  <h3>รายละเอียดการจัดส่ง</h3>
                  <a
                    className="btnEditAddress"
                    style={{ display: !isEdit ? "block" : "none" }}
                    onClick={() => setIsEdit(true)}
                  ></a>
                </div>
                <div
                  className="bAddress"
                  style={{ display: !isEdit ? "block" : "none" }}
                >
                  <div className="bRow">
                    <p>
                      <span>ชื่อผู้แลก:</span>
                      {watch("firstName")} {watch("lastName")}
                      {/* {stateProfile.firstName} {stateProfile.lastName} */}
                    </p>
                    <p>
                      <span>เบอร์โทรศัพท์:</span>
                      {helper.FORMAT.TEL(watch("mobileNo"))}
                      {/* {stateProfile.mobileNo} */}
                    </p>
                  </div>
                  <div className="bRow">
                    <p>
                      <span>สถานที่จัดส่ง:</span>
                      {/* 12/29 หมู่บ้านไชยลดา ถนนราษฎรพัฒนา แขวงราษฏรพัฒนา เขตสะพานสูง กรุงเทพมหานคร 10230  */}
                      {/* {watch('address')} */}
                      {toAddressText(
                        addressInfo,
                        watch("province"),
                        watch("district"),
                        watch("subDistrict"),
                        watch("address"),
                        watch("postcode")
                      )}
                      {/* {' ' + watch('postcode')} */}
                      {/* {stateProfile.address} */}
                    </p>
                  </div>
                </div>
                <div
                  className="bEditAddress"
                  style={{ display: isEdit ? "block" : "none" }}
                >
                  <div className="bForm">
                    <div className="bGroup">
                      <div className="bRow">
                        <div className="control-group ">
                          {/* <!-- ถ้า  ไม่ผ่านให้ใส่ Class error --> */}
                          <div className="tTitle">ชื่อ</div>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกชื่อ",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder="กรอกชื่อ"
                                onInput={helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  validateForm();
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.firstName && (
                            <label htmlFor="" className="tError">
                              {errors.firstName.message}
                            </label>
                          )}
                        </div>
                        <div className="control-group ">
                          {/* <!-- ถ้า  ไม่ผ่านให้ใส่ Class error --> */}
                          <div className="tTitle">นามสกุล</div>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกนามสกุล",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder="กรอกนามสกุล"
                                onInput={helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  validateForm();
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.lastName && (
                            <label htmlFor="" className="tError">
                              {errors.lastName.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div className="control-group ">
                          {/* <!-- ถ้า  ไม่ผ่านให้ใส่ Class error --> */}
                          <div className="tTitle">เบอร์โทรศัพท์</div>
                          <Controller
                            name="mobileNo"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกเบอร์โทรศัพท์",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                data-icon="phone"
                                type="text"
                                inputmode="numeric"
                                placeholder="กรุณากรอกหมายเลขโทรศัพท์ให้ครบ 10 หลัก"
                                onInput={helper.FNFORM.handleNumberOnly}
                                onChange={({ target: { value } }) => {
                                  // if(value.length <10){

                                  // }else if(value.length = 10){
                                  //   onChange(value);
                                  //   validateForm();
                                  // }
                                  if (value.length <= 10) {
                                    onChange(value);
                                    validateForm();
                                  }
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.mobileNo && (
                            <label htmlFor="" className="tError">
                              {errors.mobileNo.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div className="control-group ">
                          <div className="tTitle">ที่อยู่</div>
                          <Controller
                            name="address"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกที่อยู่",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <textarea
                                {...field}
                                rows="3"
                                placeholder="บ้านเลขที่ คอนโดมิเนียม/หมู่บ้าน หมู่ที่ ถนน ซอย"
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.address && (
                            <label htmlFor="" className="tError">
                              {errors.address.message}
                            </label>
                          )}
                        </div>
                      </div>
                      {/* province :: {watch('province')}<br/> */}
                      {/* district :: {watch('district')}<br/> */}
                      {/* subDistrict :: {watch('subDistrict')}<br/> */}
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.province ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">จังหวัด</div>
                          <div className="select">
                            <Controller
                              name="province"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกจังหวัด",
                              }}
                              render={({ field: { onChange, ...field } }) => (
                                <select
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    setValue("district", "");
                                    setValue("subDistrict", "");
                                    setValue("postcode", "");
                                    validateForm();
                                  }}
                                >
                                  <option value="" disabled>
                                    จังหวัด
                                  </option>
                                  {addressInfo.province.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}
                            />
                          </div>
                          {errors.province && (
                            <label htmlFor="" className="tError">
                              {errors.province.message}
                            </label>
                          )}
                        </div>
                        <div
                          className={`control-group ${
                            errors.district ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">เขต/อำเภอ</div>
                          <div className="select">
                            <Controller
                              name="district"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกเขต/อำเภอ",
                              }}
                              disabled={!watch("province")}
                              render={({ field: { onChange, ...field } }) => (
                                <select
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    setValue("subDistrict", "");
                                    setValue("postcode", "");
                                    validateForm();
                                    // checkOnChangeField();
                                  }}
                                >
                                  <option value="" disabled>
                                    เขต/อำเภอ
                                  </option>
                                  {addressInfo.district
                                    .filter(
                                      (item) =>
                                        item.provinceId === watch("province")
                                    )
                                    .map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            />
                          </div>
                          {errors.district && (
                            <label htmlFor="" className="tError">
                              {errors.district.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div className="control-group">
                          <div className="tTitle">แขวง/ตำบล</div>
                          <div className="select">
                            <Controller
                              name="subDistrict"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกแขวง/ตำบล",
                              }}
                              disabled={!watch("district")}
                              render={({ field: { onChange, ...field } }) => (
                                <select
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    setValue("postcode", "");
                                    validateForm();
                                    validateForm();
                                    const { subDistrict } = addressInfo;
                                    const _postcode =
                                      subDistrict.find(
                                        (item) => item.id == value
                                      )?.postCode ?? "";
                                    if (_postcode) {
                                      setValue("postcode", _postcode);
                                    }

                                    validateForm();
                                  }}
                                >
                                  <option value="" disabled>
                                    แขวง/ตำบล
                                  </option>
                                  {addressInfo.subDistrict
                                    .filter(
                                      (item) =>
                                        item.districtId === watch("district")
                                    )
                                    .map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            />
                            {errors.subDistrict && (
                              <label htmlFor="" className="tError">
                                {errors.subDistrict.message}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="control-group">
                          <div className="tTitle">รหัสไปรษณีย์</div>
                          <Controller
                            name="postcode"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกรหัสไปรษณีย์",
                            }}
                            disabled={true}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                style={{ backgroundColor: "#EDF1F4" }}
                                {...field}
                                type="text"
                                inputmode="numeric"
                                placeholder="ไปรษณีย์"
                                onInput={helper.FNFORM.handleNumberOnly}
                                onChange={({ target: { value } }) => {
                                  if (value.length <= 5) {
                                    onChange(value);
                                    validateForm();
                                  }
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.postcode && (
                            <label htmlFor="" className="tError">
                              {errors.postcode.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bBtn">
                      <a
                        className={`btn outline ${
                          disabledForm ? "disabled" : ""
                        }`}
                        id="btnModalConfirmAddress"
                        onClick={() => saveForm()}
                        style={helper.STYLE.aDisable(disabledForm)}
                      >
                        ยืนยันการเปลี่ยนแปลง
                      </a>
                      <a
                        className={`btn outline`}
                        id="btnModalCancelEditAddress"
                        onClick={() => setOpenCc(true)}
                      >
                        ยกเลิก
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bDetailsMain bCard">
                <div className="bTitle">
                  <h2>{rewardData.name}</h2>
                  <p>{rewardData.description}</p>
                </div>
                <div className="bPointMain">
                  <div className="bPoint">
                    <span>ใช้ Point แลก</span>
                    <p>
                      {helper.FN.toNumberWithCommas(rewardData.pointToRedeem)}
                    </p>
                  </div>
                  <div className="bQuata">
                    <div className="bRemaining">
                      <p>
                        แลกไปแล้ว{" "}
                        <span>
                          {rewardData.available}/{rewardData.total}
                        </span>
                      </p>
                      {/* <!-- คำนวณค่าออกมาเป็น % แล้วใส่ค่าที่ width: xx%; --> */}
                      <div className="bBar">
                        <span
                          style={{
                            width:
                              helper.FN.calPercen(
                                rewardData.available,
                                rewardData.total
                              ) + "%",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="bDate">
                      แลกได้ถึงวันที่:
                      <span>
                        {helper.TIME.toShortDateNormalTH(rewardData.toDate)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bProductImg">
                  <div className="bPic">
                    <img
                      src={
                        rewardData.img
                          ? rewardData.img
                          : "https://dummyimage.com/200x200/fff/000"
                      }
                      alt=""
                    />
                  </div>
                  <div className="bTag">จัดส่งฟรี!</div>
                </div>
              </div>
            </section>
            <section id="pBtnPage">
              {/* (contBTN1 || notEnoughPoints) */}
              {(contBTN1 || notEnoughPoints) && (
                <p className="tError" style={{ marginBottom: "12px" }}>
                  Point ของคุณไม่เพียงพอ
                </p>
              )}
              <div className="bBtn">
                <a
                  id="btnModalConfirmExchange"
                  className={`btn ${
                    disabledBTN || isEdit || isLoading ? "disabled" : ""
                  }`}
                  style={helper.STYLE.aDisable(
                    disabledBTN || isEdit || isLoading
                  )}
                  onClick={() => setOpenCf(true)}
                >
                  ยืนยันแลกรางวัล
                </a>
                {/* <!-- ใส่ class disabled เพื่อเป็นปุ่มเทา --> */}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ModalCancel
        isOpen={openCc}
        onConfirm={() => {
          setOpenCc(false);
          resetForm();
        }}
        onClose={() => setOpenCc(false)}
      />
      <ModalConfirmExchange
        isOpen={openCf}
        point={rewardData.pointToRedeem}
        onConfirm={onSubmit}
        onClose={() => setOpenCf(false)}
      />
      <ModalRewardEmpty
        isOpen={openEmpty}
        onConfirm={() => {
          navigate("/reward");
        }}
        onClose={() => setOpenEmpty(false)}
      />
    </div>
  );
};

export default RewardConfirm;
