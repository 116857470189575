import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  dataUser: {},
  address: {},
};

export const ProfileSlice = createSlice({
  name: "Profile",
  initialState: defaultData,
  reducers: {
    setProfile: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.dataUser[key] = value;
      }
      // state.dataUser = action.payload;
    },
    resetProfile: () => defaultData,

    setAddress: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.address[key] = value;
      }
      // state.dataUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, resetProfile, setAddress } = ProfileSlice.actions;

export default ProfileSlice.reducer;
