/*  */

import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { GetEventNewsList } from "services/Api/Module/EventNews";
import Pagination from "../centerComponent/Pagination";
import EventNewsItem from "./EventNewsItem";
import helper from "services/helper";

const DEF_LIMIT = 10;
const DEF_OFFSET = 0;

const EvenNewsExternal = () => {
  const [eventNews, setEventNews] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = DEF_LIMIT;
  const offset = searchParams.get("offset") ?? DEF_OFFSET;

  const getEventNewsList = async () => {
    let _return = {
      status: false,
      result: null,
      rowCount: 0,
    };

    try {
      const { data } = await GetEventNewsList({
        params: { limit, offset },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchEventNewsList = async () => {
    try {
      const resData = await getEventNewsList();
      setEventNews(resData.result);
      setRowCount(resData.rowCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEventNewsList();
  }, [stateProfile, offset]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageNews-event">
        <main>
          <div className="wrapContent">
            <section className="bHead">
              <div className="tHead">
                <div className="dummy"></div>
                <h2>
                  <p>ข่าวสารและกิจกรรม</p>
                </h2>
                <div className="dummy"></div>
              </div>
            </section>

            <section id="pAllNews">
              {eventNews.map((item, index) => {
                return <EventNewsItem key={index} item={item} />;
              })}
            </section>
            <section id="pContent">
              <Pagination
                limit={limit}
                offset={offset}
                rowCount={rowCount}
                onBack={(limit, offset) => {
                  console.log("🚀 ~ Reward ~ limit, offset:", limit, offset);
                  setSearchParams({ offset });
                }}
                onNext={(limit, offset) => {
                  console.log("🚀 ~ Reward ~ limit, offset:", limit, offset);
                  setSearchParams({ offset });
                }}
              />
            </section>
            {/* <!-- Modal -->
          <?php include 'inc/inc-modal.php';?> */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default EvenNewsExternal;
