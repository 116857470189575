import { get, post,put } from "../Config";


// GET
export function RewardList({ params = { limit: 10, offset:0 } }) {
  return get({ url: `/api/koala/v1/reward`, params });
}
export function RewardView({ rewardId = 0, params }) {
  return get({ url: `/api/koala/v1/reward/${rewardId}`, params});
}

export function RewardHistoryList({ params }) {
  return get({ url: `/api/koala/v1/rewardHistory`, params });
}
export function RewardHistoryView({ rewardHistoryId = 0, params }) {
  return get({ url: `/api/koala/v1/rewardHistory/${rewardHistoryId}`, params});
}

// POST
export function CreateReward({ data }) {
  return post({ url: `/api/koala/v1/redeem`, data, timeOut: 20000 });
}


export function CheckPointView({ customerId = 0, params }) {
  return get({ url: `/api/koala/v1/customer/point/${customerId}`, params, timeOut: 20000 });
}
