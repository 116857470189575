import { useNavigate } from "react-router-dom";
import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";

const ModalHeartEmpty = ({ isOpen = false, onClose }) => {
  const navigate = useNavigate();
  const [modalFilter, setModalFilter] = useState(null);

  useEffect(() => {
    if (modalFilter && isOpen) {
      openModal();
      listenerCloseModal();
    }
  }, [modalFilter, isOpen]);

  useEffect(() => {
    const initModalFilter = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalBanner"),
    });
    setModalFilter(initModalFilter);

    return () => {
      initModalFilter.destroy();
    };
  }, []);

  const openModal = () => {
    document.getElementById("ModalBanner").classList.remove("hide");
    modalFilter.open();
  };

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModalHeart");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  const closeModal = () => {
    modalFilter.close();
    onClose();
  };

  return (
    <div className="bCardBanner hide" id="ModalBanner">
      <div className="popupImg">
        <div className="btnCloseModal" id="btnCloseModalHeart"></div>
      </div>
    </div>
  );
};

export default ModalHeartEmpty;
