import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { GetPillowHistoryView } from "services/Api/Module/Pillow";
import helper from "services/helper";

const PillowComplete = () => {
  const [pillowHistoryData, setPillowHistoryData] = useState({});
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const pillowHistoryId = searchParams.get("id");

  const getPillowHistory = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await GetPillowHistoryView({
        pillowHistoryId: pillowHistoryId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchPillowHistoryView = async () => {
    try {
      const resData = await getPillowHistory();
      setPillowHistoryData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPillowHistoryView();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageReward-Complete">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <h2>Character Collection</h2>
              </div>
            </section>
            <section id="pAnnouncement">
              <div className="bAnnouncement">
                <div className="bPic">
                  <i className="icoCheck"></i>
                </div>
                <div className="bDetails">
                  <h3>ดำเนินการแลกของรางวัลเรียบร้อย</h3>
                  <p>{pillowHistoryData.pillowTitle}</p>
                </div>
              </div>
            </section>
            <section id="pContent">
              <div className="bContent">
                <div className="bPic">
                  <img
                    src={
                      pillowHistoryData.pillowImg
                        ? pillowHistoryData.pillowImg
                        : "https://dummyimage.com/200x200/fff/000"
                    }
                    alt=""
                  />
                </div>
                <div className="bDetails">
                  <h3>รายละเอียด</h3>
                  <div className="bRow">
                    <p>
                      <span>รหัส:</span>
                      {pillowHistoryData.orderCode}
                    </p>
                    <p>
                      <span>วันที่/เวลาแลกของรางวัล:</span>
                      {/* {pillowHistoryData.createdOn}<br/> */}
                      {helper.TIME.toShortDateTimeNormalTH(
                        pillowHistoryData.createdOn
                      )}
                      {/* <br/>20/07/2566 12:21:01 น. */}
                    </p>
                  </div>
                </div>
                <div className="bAddressMain bCard">
                  <div className="bAddress">
                    <div className="bRow">
                      <p>
                        <span>ชื่อผู้แลก:</span>
                        {pillowHistoryData.firstName}{" "}
                        {pillowHistoryData.lastName}
                      </p>
                      <p>
                        <span>เบอร์โทรศัพท์:</span>
                        {/* 081-111-1111 */}
                        {helper.FORMAT.TEL(pillowHistoryData.mobileNo)}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>
                        <span>สถานที่จัดส่ง:</span>
                        {/* 12/29 หมู่บ้านไชยลดา ถนนราษฎรพัฒนา แขวงราษฏรพัฒนา เขตสะพานสูง กรุงเทพมหานคร 10230  */}
                        {/* {toAddressText(addressInfo,pillowHistoryData.province, pillowHistoryData.district, pillowHistoryData.subDistrict, pillowHistoryData.address, pillowHistoryData.postcode)} */}
                        {pillowHistoryData.address}{" "}
                        {pillowHistoryData.subDistrictName}{" "}
                        {pillowHistoryData.districtName}{" "}
                        {pillowHistoryData.provinceName}{" "}
                        {pillowHistoryData.postcode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bBtn">
                  <a
                    onClick={() => navigate("/character-collection")}
                    className="btn"
                  >
                    กลับหน้า Character Collection
                  </a>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PillowComplete;
