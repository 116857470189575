import React from "react";
import { useNavigate } from "react-router-dom";
import helper from "services/helper";

const RewardItem = ({ item = {} }) => {
  const navigate = useNavigate();
  const isDisable = item.available >= item.total;
  const soldout = isDisable ? " soldout" : "";
  return (
    <a
      style={helper.STYLE.aDisable(isDisable)}
      onClick={() => navigate("/reward-details?id=" + item.id)}
      className={soldout}
    >
      {/* <!-- ถ้าชิ้นนี้หมดแล้วให้ใส่ Class soldout --> */}
      <div
        className="bPic"
        style={{ backgroundImage: `url(${item.img})` }}
      ></div>
      <div className="bDetails">
        <div className="tTitle">{item.name}</div>
        <div className="tDate">
          <span>แลกได้ถึงวันที่:</span>
          {/* 23/05/2565  */}
          {helper.TIME.toShortDateNormalTH(item.toDate)}
        </div>
        <div className="bPointRemain">
          <div className="bPoint">
            <span>{item.pointToRedeem}</span>
          </div>
          <div className="tRemaining">
            แลกไปแล้ว
            <span>
              {item.available}/{item.total}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default RewardItem;
