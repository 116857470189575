import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { RewardHistoryView } from "services/Api/Module/Reward";
import helper from "services/helper";

const RewardComplete = () => {
  const [rewardHistoryData, setRewardHistoryData] = useState({});
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const rewardHistoryId = searchParams.get("id");

  const currentPoint = stateProfile.currentPoint ?? 0;

  const getRewardHistoryView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await RewardHistoryView({
        rewardHistoryId: rewardHistoryId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchRewardView = async () => {
    try {
      const resData = await getRewardHistoryView();
      setRewardHistoryData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRewardView();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageReward-Complete">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <h2>Rewards</h2>
              </div>
            </section>
            <section id="pAnnouncement">
              <div className="bAnnouncement">
                <div className="bPic">
                  <i className="icoCheck"></i>
                </div>
                <div className="bDetails">
                  <h3>ดำเนินการแลกของรางวัลเรียบร้อย</h3>
                  <p>{rewardHistoryData.rewardTitle}</p>
                </div>
              </div>
            </section>
            <section id="pContent">
              <div className="bContent">
                <div className="bPic">
                  <img
                    src={
                      rewardHistoryData.rewardImg
                        ? rewardHistoryData.rewardImg
                        : "https://dummyimage.com/200x200/fff/000"
                    }
                    alt=""
                  />
                </div>
                <div className="bDetails">
                  <h3>รายละเอียด</h3>
                  <div className="bRow">
                    <p>
                      <span>รหัส:</span>
                      {rewardHistoryData.orderCode}
                    </p>
                    <p>
                      <span>วันที่/เวลาแลกของรางวัล:</span>
                      {/* {rewardHistoryData.createdOn}<br/> */}
                      {helper.TIME.toShortDateTimeNormalTH(
                        rewardHistoryData.createdOn
                      )}
                      {/* <br/>20/07/2566 12:21:01 น. */}
                    </p>
                  </div>
                </div>
                <div className="bAddressMain bCard">
                  <div className="bAddress">
                    <div className="bRow">
                      <p>
                        <span>ชื่อผู้แลก:</span>
                        {rewardHistoryData.firstName}{" "}
                        {rewardHistoryData.lastName}
                      </p>
                      <p>
                        <span>เบอร์โทรศัพท์:</span>
                        {/* 081-111-1111 */}
                        {helper.FORMAT.TEL(rewardHistoryData.mobileNo)}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>
                        <span>สถานที่จัดส่ง:</span>
                        {/* 12/29 หมู่บ้านไชยลดา ถนนราษฎรพัฒนา แขวงราษฏรพัฒนา เขตสะพานสูง กรุงเทพมหานคร 10230  */}
                        {/* {toAddressText(addressInfo,rewardHistoryData.province, rewardHistoryData.district, rewardHistoryData.subDistrict, rewardHistoryData.address, rewardHistoryData.postcode)} */}
                        {rewardHistoryData.address}{" "}
                        {rewardHistoryData.subDistrictName}{" "}
                        {rewardHistoryData.districtName}{" "}
                        {rewardHistoryData.provinceName}{" "}
                        {rewardHistoryData.postcode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bBtn">
                  <a onClick={() => navigate("/reward")} className="btn">
                    กลับหน้า Rewards
                  </a>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RewardComplete;
