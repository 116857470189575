import React, { useState, useEffect } from "react";

import jBox from "jbox";
import $ from "jquery";

function ModalAlert({
  open = false,
  title = "",
  subTitle = "",
  content = `มีบางอย่างผิดพลาด`,
  onClose = () => {},
}) {
  const [ModalAlert, setModalAlert] = useState(null);

  function openModal() {
    ModalAlert.open();
  }

  function closeModal() {
    ModalAlert.close();
    onClose();
  }

  function listenerCloseModal() {
    const elementModalAlert = document.getElementById("__btnCloseModalAlert");
    elementModalAlert.addEventListener("click", () => {
      closeModal();
    });
  }

  useEffect(() => {
    if (ModalAlert && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)
    }
  }, [ModalAlert, open]);

  useEffect(() => {
    const ModalAlert = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#__ModalAlert"),
    });
    setModalAlert(ModalAlert);
  }, []);

  return (
    <div className="bModal bCard" id="__ModalAlert" style={{ display: "none" }}>
      <div className="bInner">
        <div className="bDetails">
          <p className="tDetails">
            <span
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.8em",
                fontWeight: "bold",
              }}
            >
              {title}
            </span>
            <br />
            <span style={{ fontSize: "1.2rem", lineHeight: "1.5em" }}>
              {subTitle}
            </span>
            <br />
            <span style={{ fontSize: "1rem", lineHeight: "1.5em" }}>
              {content ? content : ""}
            </span>
          </p>
        </div>
        <div className="bBtn">
          <a onClick={closeModal} className="btn" id="__btnCloseModalAlert">
            ตกลง
          </a>
        </div>
      </div>
    </div>
  );
}

export default ModalAlert;
