import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
// import RewardItem from "./RewardItem";
import Banners from "../centerComponent/Banners";
import Pagination from "../centerComponent/Pagination";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import helper from "services/helper";
import moment from "moment";
import { CollectPointHistoryList } from "services/Api/Module/CollectPoint";
import Submenu2 from "pages/centerComponent/Submenu2";
import EmptyData from "pages/centerComponent/EmptyData";

const DEF_LIMIT = 10;
const DEF_OFFSET = 0;
const MIN_DATE = "2024-01";
const CollectPointHistory = () => {
  const [rewards, setCollectPoints] = useState([]);
  const [pointHistorys, setPointHistorys] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [dateSelect, setDataSelect] = useState(moment().format("YYYY-MM"));
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = DEF_LIMIT;
  const offset = searchParams.get("offset") ?? DEF_OFFSET;

  const getCollectHistoryPointList = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CollectPointHistoryList({
        params: { customer: customerId, limit, offset, date: dateSelect },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchCollectHistoryPointList = async () => {
    try {
      const pointHistory = await getCollectHistoryPointList();
      setPointHistorys(pointHistory.result);
      setRowCount(pointHistory.rowCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCollectHistoryPointList();
  }, [stateProfile, offset, dateSelect]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageHistory-Point">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/profile")}
                ></a>
                <h2>ประวัติการใช้ Point</h2>
              </div>
            </section>
            <Submenu2 />
            <section id="pContent">
              <h1>ประวัติ Collect point</h1>
              <div className="bFilter">
                <div className="control-group">
                  <input
                    type="month"
                    name="text"
                    value={dateSelect}
                    min={MIN_DATE}
                    onChange={(e) => {
                      if (MIN_DATE > moment(e.target.value).format("YYYY-MM")) {
                        setDataSelect(moment().format("YYYY-MM"));
                        alert("ไม่พบข้อมูล");
                      } else {
                        setDataSelect(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              {rowCount > 0 ? (
                <div className="bCard">
                  <div className="bTablePoint">
                    <table>
                      <thead>
                        <tr>
                          <td>วันที่</td>
                          <td colSpan="2">Point ที่ได้</td>
                        </tr>
                      </thead>
                      <tbody>
                        {pointHistorys.map((item, index) => {
                          return (
                            <tr key={index}>
                              {/* {JSON.stringify(item)} */}
                              <td>
                                {item.createdDate
                                  ? helper.TIME.toShortDateNormalTH(
                                      item.createdDate
                                    )
                                  : ""}
                              </td>

                              <td>{item.pointEarnConfigName}</td>
                              <td>
                                <i>{item.point}</i>
                              </td>
                              <td>
                                {/* <i>{item.pointEarnConfig == 1 ? "Collect Point" : item.pointEarnConfig == 2 ? "Referal Point" : 'Wellcom Point'}</i> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    limit={limit}
                    offset={offset}
                    rowCount={rowCount}
                    onBack={(limit, offset) => {
                      setSearchParams({ offset });
                    }}
                    onNext={(limit, offset) => {
                      setSearchParams({ offset });
                    }}
                  />
                </div>
              ) : (
                <EmptyData />
              )}
            </section>
            <Menu />
          </div>
        </main>
      </div>
    </div>
  );
};

export default CollectPointHistory;
