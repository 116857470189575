import ModalProvider from "contexts/ModalProvider";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function Layouts({ children }) {
  const stateProfile = useSelector((state) => state.profile.dataUser);
  useEffect(() => {
    let defaultTheme = "green"
    if (stateProfile.themeSelected) {
      if (stateProfile.themeSelected == "1") {
        defaultTheme = "green"
      }
      else if (stateProfile.themeSelected == "2") {
        defaultTheme = "pink"
      }
    } 
    const theme = defaultTheme ?? localStorage.getItem("theme") ?? "green";
    document.body.setAttribute("data-theme", theme);
    
  }, [stateProfile]);
  console.log('stateProfile >>', stateProfile)
  return <ModalProvider>{children}</ModalProvider>;
}

export default Layouts;
