import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalContext from "contexts/ModalContext";
import { GetCustomerData } from "services/Api/Module/Customer";
import { setProfile } from "features/ProfileSlice";
import _ from "lodash";
import { monthNames } from "../../utility/date";

const Success = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const navigate = useNavigate();
  const { mAlert } = useContext(ModalContext);

  console.log("stateProfile Success :", stateProfile);

  const getProfile = async () => {
    localStorage.setItem("customerId", stateProfile.id);
    navigate("/profile");
  };

  const fetchCustomerProfile = async (id) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await GetCustomerData({ id: id });

      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {},
        });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  const prepareBirthDate = (birthDate) => {
    const date = birthDate.split("-");
    const monthName = monthNames.find((item) => item.id == date[1]);
    date[1] = monthName.name;
    date[0] = Number(date[0]) + 543;
    const dateReverse = date.reverse().join(" ");

    return dateReverse;
  };

  useEffect(() => {
    (async () => {
      if (stateProfile.customerId) {
        // const cusId = /*stateProfile.customerId*/10100;
        const cusId = stateProfile.customerId;
        const custormerProfile = await fetchCustomerProfile(cusId);
        const birthDate = prepareBirthDate(custormerProfile.result.birthDate);
        dispatch(
          setProfile({
            ...custormerProfile.result,
            customerId: custormerProfile.result.id,
            birthDateConvert: birthDate,
          })
        );
      }
    })();
  }, []);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRegister-success">
        <main>
          <div className="wrapContent">
            <section id="pSuccessMain">
              <div className="bSuccess">
                <div className="bTitlePage">
                  <h1>สมัครสมาชิกสำเร็จ</h1>
                  <p>ขอบคุณที่มาเป็นส่วนหนึ่งกับเรา</p>
                </div>
                <div className="bPicSuccess">
                  <div className="bPic">
                    <img src="assets/img/ci/img-twinkoala.svg" alt="" />
                  </div>
                  <div className="bText">
                    <h2>Koala’s Family</h2>
                    <p>สิทธิพิเศษมากมายจากเรารอคุณอยู่</p>
                  </div>
                </div>
                <div className="bStepBtn">
                  <div className="bBtn">
                    <button className="btn" type="button" onClick={getProfile}>
                      ไปที่หน้าโปรไฟล์
                    </button>
                    <a onClick={() => navigate("/reward")} className="btn">
                      ไปที่แลกของรางวัล
                    </a>
                    <a
                      onClick={() => navigate("/scanpoint")}
                      className="btn outline"
                    >
                      สะสมคะแนน
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      {/* <body data-theme="green"> */}
      {/* !-- เปลี่ยน data-theme ตามที่คนใช้งานเลือก green / pink --> */}
      {/* </body> */}
    </div>
  );
};

export default Success;
