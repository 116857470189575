import PolicyPrivacy from "pages/register/PolicyPrivacy";
import PolicyTerms from "pages/register/PolicyTerms";

import Error from "pages/centerComponent/Error";

import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function AppNotLine() {
  // return "TEST";
  return (
    <Router>
      <Routes>
        <Route
          path="/external-policy-terms"
          element={<PolicyTerms open={true} noBTN={true} />}
        />

        <Route
          path="/external-policy-privacy"
          element={<PolicyPrivacy open={true} noBTN={true} />}
        />

        <Route path="/error" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default AppNotLine;
