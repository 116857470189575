import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { RewardView, CheckPointView } from "services/Api/Module/Reward";
import helper from "services/helper";
import GetCurrentPoint from "./GetCurrentPoint";
import PolicyReward from "./PolicyReward";

const RewardDetail = () => {
  const [rewardData, setRewardData] = useState({});
  const [isAccept, setIsAccept] = useState(false);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;

  let [searchParams, setSearchParams] = useSearchParams();
  const rewardId = searchParams.get("id");

  // state page pdpd contions ต่างๆ
  const page = searchParams.get("page") ?? "";
  const isShowPage = !!page ? true : false;

  const currentPoint = newCurrentPoint ?? stateProfile.currentPoint ?? 0;

  const getRewardView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await RewardView({
        rewardId: rewardId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      } else {
        navigate("/reward");
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const gotoConfrim = () => {
    // alert('gotoConfrim!')
    navigate(`/reward-confirm?id=${rewardId}`);
  };

  const fetchRewardView = async () => {
    try {
      const resData = await getRewardView();

      setRewardData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRewardView();
  }, [stateProfile]);

  const contBTN0 = !isAccept; // กรณีไม่ checkboox
  const contBTN1 = !(currentPoint >= rewardData.pointToRedeem); // กรณี point ไม่พอ
  const contBTN2 = !(rewardData.total > rewardData.available); // กรณี ของ ไม่พอ
  const disabledBTN = contBTN0 || contBTN1 || contBTN2;
  // const disabledBTN = contBTN0 || contBTN1
  const styleShow = { display: isShowPage === false ? "block" : "none" };
  return (
    <div>
      {/* newCurrentPoint::{newCurrentPoint} */}
      <GetCurrentPoint />
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <PolicyReward
        open={page === "policy-reward"}
        onBack={() => {
          setSearchParams({ id: rewardId });
        }}
      />

      <div id="pageReward-Details" style={styleShow}>
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/reward")}
                ></a>
                <h2>Rewards</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bDetailsMain bCard">
                <div className="bTitle">
                  <h2>{rewardData.name}</h2>
                  <p>{rewardData.description}</p>
                </div>
                <div className="bPointMain">
                  <div className="bPoint">
                    <span>ใช้ Point แลก</span>
                    <p>
                      {helper.FN.toNumberWithCommas(rewardData.pointToRedeem)}
                    </p>
                  </div>
                  <div className="bQuata">
                    <div className="bRemaining">
                      <p>
                        แลกไปแล้ว{" "}
                        <span>
                          {rewardData.available}/{rewardData.total}
                        </span>
                      </p>
                      {/* <!-- คำนวณค่าออกมาเป็น % แล้วใส่ค่าที่ width: xx%; --> */}
                      <div className="bBar">
                        <span
                          style={{
                            width:
                              helper.FN.calPercen(
                                rewardData.available,
                                rewardData.total
                              ) + "%",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="bDate">
                      แลกได้ถึงวันที่:
                      <span>
                        {helper.TIME.toShortDateNormalTH(rewardData.toDate)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bProductImg">
                  <div className="bPic">
                    <img
                      src={
                        rewardData.img
                          ? rewardData.img
                          : "https://dummyimage.com/200x200/fff/000"
                      }
                      alt=""
                    />
                  </div>
                  <div className="bTag">จัดส่งฟรี!</div>
                </div>
                <div className="bCondition">
                  <h3>เงื่อนไขการรับของรางวัล</h3>
                  <div
                    className="bText"
                    dangerouslySetInnerHTML={{ __html: rewardData.conditions }}
                  />
                </div>
                {/* <div className="bCondition">
                  <h3>เงื่อนไขการรับของรางวัล</h3>
                  <div className="bText">
                    <h1>Header 1</h1>
                    <h2>Header 2</h2>
                    <h3>Header 3</h3>
                    <h4>Header 4</h4>
                    <h5>Header 5</h5>
                    <h6>Header 6</h6>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat
                      explicabo earum ducimus quae accusantium doloremque eos labore ab
                      nobis maxime delectus quibusdam, id similique, incidunt totam
                      excepturi voluptates saepe ipsum?
                    </p>
                    <br />
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                      consectetur impedit dolorum adipisci ex! Provident deserunt sunt
                      porro alias eos voluptatibus voluptatum eius necessitatibus autem!
                      Voluptatem, eligendi pariatur? Assumenda, vitae!<br />
                      <b>Bold</b><br />
                      <i>italic</i><br />
                      <u>Underline</u><br />
                      <strong>Strong</strong><br />
                      <sup>Super</sup><br />
                      <sub>Subscripted</sub><br />
                      <a href="#">Link</a>
                    </p>
                    <br />
                    <ul>
                      <li>List</li>
                      <li>List</li>
                      <li>List</li>
                      <li>List</li>
                    </ul>
                    <br />
                    <ol>
                      <li>Order</li>
                      <li>Order</li>
                      <li>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Id et cupiditate quasi impedit? Fugit alias ipsam nostrum quam cupiditate, eveniet ad laudantium reprehenderit tenetur cum dolorem porro iure eius eligendi.
                      </li>
                      <li>Order</li>
                    </ol>  
                  </div>
                </div> */}
              </div>
            </section>
            <section id="pBtnPage">
              <div className="bBtn">
                <div className="bForm">
                  <div className="control-group pdpa">
                    {/* <!-- ถ้า Validate ไม่ผ่านให้ใส่ Class error --> */}
                    <label className="control control--checkbox">
                      <p>
                        คุณยอมรับ{" "}
                        <a
                          onClick={() => {
                            setSearchParams({
                              id: rewardId,
                              page: "policy-reward",
                            });
                          }}
                        >
                          เงื่อนไขการรับของรางวัล
                        </a>
                      </p>
                      <input
                        type="checkbox"
                        checked={isAccept}
                        onChange={(e) => {
                          setIsAccept(e.target.checked);
                        }}
                      />
                      <div className="control__indicator"></div>
                    </label>
                    <label for="" className="tError">
                      จำเป็นต้องยินยอมรับเงื่อนไขการรับของรางวัล
                    </label>
                  </div>
                </div>
                {contBTN1 && <p className="tError">Point ของคุณไม่เพียงพอ</p>}
                <a
                  onClick={() => gotoConfrim()}
                  className={`btn ${disabledBTN ? "disabled" : ""}`}
                  style={helper.STYLE.aDisable(disabledBTN)}
                >
                  แลกรางวัล
                </a>
                {/* <!-- ใส่ class disabled เพื่อเป็นปุ่มเทา --> */}
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RewardDetail;
