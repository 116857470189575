import React, { useRef, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
// import VConsole from "vconsole";
import Webcam from "react-webcam";
import Tesseract from "tesseract.js";
import Resizer from "react-image-file-resizer";

const ScanOCR = ({ onCapture, onClose }) => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [recognizedText, setRecognizedText] = useState("");
  const [chooseImg, setChooseImg] = useState(null);
  const [result, setResult] = useState("");
  const [image, setImage] = useState(null);
  const [postImage, setPostImage] = useState(null);
  const [rezsize, setResize] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [openCameraScan, setOpenCameraScan] = useState(true);
  // const vConsole = new VConsole();
  const inputRef = useRef(null);
  const canvasRef = useRef(null);
  const [modifiedImage, setModifiedImage] = useState(null);
  // console.log("OCR uploadOpen", uploadOpen);
  const style1 = {
    // width: "100%",
    // height: "100%",
    // position: "absolute",
    // left: "0",
    // top: "0",
    // display: "flex",
    // flexFlow: "column",
    // alignItems: "center",
    // zIndex: "1",
  };

  const style2 = {
    // width: "50%",
    // height: "12%",
    // position: "relative",
    // outlineStyle: "solid",
    // outlineColor: "rgba(0, 0, 0, 0.3)",
    // outlineWidth: "50vw",
    // border: "1px solid rgba(255, 0, 0, 0.7)",
    // margin: "auto",
  };
  // console.log("OCR result-------------------------------->", result);
  // console.log(" postImage-------------------------------->", postImage);
  // ------------------Send Img to Tesseract---------------------
  const handleOCR = async (data) => {
    const {
      data: { text },
    } = await Tesseract.recognize(data, "eng", {
      logger: (info) => console.log(info),
    });
    console.log("Result----------->", text);
    setResult(text);
  };

  let checkChar = true;
  const capture = useCallback(() => {
    if (webcamRef !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      // console.log("imageSrc ===>", imageSrc);
      setCapturedImage(imageSrc);
      Tesseract.recognize(imageSrc, "eng", {
        logger: (info) => {
          // console.log(info)
        },
      }).then(({ data: { text } }) => {
        // setRecognizedText(text);
        const regex = /([A-Z0-9]){13,14}/g;
        let result1 = "";
        let index = text.search(regex);
        console.log("test Scan ===>", text, index);
        if (index >= 0) {
          for (let i = 0; i < 14; i++) {
            const result = text[index + i];
            console.log(/([A-Z0-9])/g.test(result), result);
            // if((/([A-Z0-9])/g).test(result)){
            result1 += text[index + i];
            // }else{
            //   checkChar = false
            // }
          }
          // if(checkChar === true){
          //   onCapture(result1);
          // }
          console.log("result1==>", result1, index);
          if (/([A-Z0-9])/g.test(result1)) {
            onCapture(result1);
          }
        }
      });
    }
  }, [webcamRef]);

  useEffect(() => {
    if (openCameraScan) {
      const intervalCap = setInterval(() => {
        if (!uploadOpen) {
          capture();
        }
      }, 3000);
      return () => {
        clearInterval(intervalCap);
      };
    }
  }, []);

  useEffect(() => {
    const paragraph = result;
    const regex = /([A-Z0-9]){13,14}/g;
    let result1 = "";
    let index = paragraph.search(regex);

    for (let i = 0; i < 14; i++) {
      result1 += paragraph[index + i];
    }
    if (result1) {
      if (result1.length === 13 || result1.length === 14) {
        const replaceStr = result1.replace("\n", "");
        const charValue = replaceStr.replace(/[^\s@_.a-zA-Z0-9]/g, "");
        onCapture(charValue);
        // alert("success");
      }
    }
  }, [result]);

  // --------------------------------------------------------------------------

  const handleImageChange = () => {
    setUploadOpen(true);
    const fileInput = inputRef.current;
    const canvas = canvasRef.current;

    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];

      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          applyBrightness(canvas, img, 0.5); // Adjust brightness factor as needed
          updateModifiedImage(canvas);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const applyBrightness = (canvas, img, brightness) => {
    const context = canvas.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;

    // Draw the original image on the canvas
    context.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Apply brightness adjustment
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] += 255 * brightness; // Red channel
      data[i + 1] += 255 * brightness; // Green channel
      data[i + 2] += 255 * brightness; // Blue channel
    }

    // Put the modified image data back onto the canvas
    context.putImageData(imageData, 0, 0);
  };

  const updateModifiedImage = (canvas) => {
    const dataURL = canvas.toDataURL("image/png");
    handleOCR(dataURL);
    // setModifiedImage(dataURL);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertToBase64(file);
  //   setPostImage({ ...postImage, myFile: base64 });
  // };

  return (
    <div className="apiScan">
      <div className="bCamera">
        <div className="bText">
          กรุณาใช้กล้องถ่ายรหัสในกล่อง
          <br /> โดยรหัสจะต้องอยู่ภายในพื้นที่กรอบสีแดงเท่านั้น
          <span>12345ABCDEFGH</span>
        </div>
        <div className="bRed">
          <Webcam
            audio={false}
            height={"100%"}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={375}
            imageSmoothing={true}
            videoConstraints={{
              facingMode: "environment",
              height: { ideal: 1280, max: 1920 },
              width: { ideal: 720, max: 1080 },
              aspectRatio: 1,
            }}
          />
        </div>
        <div className="bCondition">
          หากกล้องไม่สามารถอ่านรหัสได้สามารถกดปุ่ม
          ย้อนกลับเพื่อทำการพิมพ์กรอกรหัสได้ด้วยตนเอง
        </div>
        <button onClick={() => onClose(false)}>ย้อนกลับ</button>
      </div>
      <div>
        <label htmlFor="uploadImg">Upload image:</label>
        <input
          style={{ display: "none" }}
          id="uploadImg"
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleImageChange}
        />
        <canvas style={{ display: "none" }} ref={canvasRef}></canvas>
      </div>
      {capturedImage && <div className="textLog"></div>}
    </div>
  );
};

export default ScanOCR;

// -----------------เอา inline style ออก ใส่ื classname
