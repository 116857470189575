import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Menu from "pages/menuMain/menu";
import Banners from "pages/centerComponent/Banners";
import moment from "moment";

const Collectpoint = () => {
  const navigate = useNavigate();
  const stateProfile = useSelector((state) => state.profile.dataUser);

  console.log("stateProfile => ", stateProfile);
  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <>
        <div id="pageScan-Collectpoint">
          <main>
            <div className="wrapContent">
              <section id="pPointTop">
                <h1 className="bTitle">Collect points</h1>
                <div className="bPoint">
                  <p>Koala’s March point ของคุณ</p>
                  <div className="bTotalPoint">
                    <span>{stateProfile.currentPoint}</span>
                  </div>
                  <p>
                    หมดอายุ 31 ธันวาคม {Number(moment().format("YYYY")) + 543}
                  </p>
                </div>
              </section>
              <section id="pCollectpoint">
                <div className="bCollectpoint">
                  <div className="bPoint">
                    <p>คุณได้รับ Points</p>
                    <div className="bTotalPoint">
                      <span>{stateProfile.actionPoint}</span>
                    </div>
                  </div>
                  <div className="bLink">
                    <a onClick={() => navigate("/history-collect-point")}>
                      ประวัติการ Collect point
                    </a>
                  </div>
                </div>
              </section>
              <section id="bButton">
                <div className="bBtn">
                  <a onClick={() => navigate("/scanpoint")} className="btn">
                    กรอกโค้ดเพิ่ม
                  </a>
                  <a
                    onClick={() => navigate("/reward")}
                    className="btn outline"
                  >
                    แลกของรางวัล
                  </a>
                </div>
              </section>
              <section id="pBanner">
                <Banners currentPage="Scan results" />
              </section>
              <Menu />

              {/* <!-- Menu -->
          <?php include 'inc/inc-menu.php';?>

          <!-- Modal -->
          <?php include 'inc/inc-modal.php';?> */}
            </div>
          </main>
        </div>
      </>
    </div>
  );
};

export default Collectpoint;
