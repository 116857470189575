export const dateNumber = [
  {"id": "01", "name": "1"},
  {"id": "02", "name": "2"},
  {"id": "03", "name": "3"},
  {"id": "04", "name": "4"},
  {"id": "05", "name": "5"},
  {"id": "06", "name": "6"},
  {"id": "07", "name": "7"},
  {"id": "08", "name": "8"},
  {"id": "09", "name": "9"},
  {"id": "10", "name": "10"},
  {"id": "11", "name": "11"},
  {"id": "12", "name": "12"},
  {"id": "13", "name": "13"},
  {"id": "14", "name": "14"},
  {"id": "15", "name": "15"},
  {"id": "16", "name": "16"},
  {"id": "17", "name": "17"},
  {"id": "18", "name": "18"},
  {"id": "19", "name": "19"},
  {"id": "20", "name": "20"},
  {"id": "21", "name": "21"},
  {"id": "22", "name": "22"},
  {"id": "23", "name": "23"},
  {"id": "24", "name": "24"},
  {"id": "25", "name": "25"},
  {"id": "26", "name": "26"},
  {"id": "27", "name": "27"},
  {"id": "28", "name": "28"},
  {"id": "29", "name": "29"},
  {"id": "30", "name": "30"},
  {"id": "31", "name": "31"}
]

export const monthNames = 
[
  {"id": "01", "name": "มกราคม"},
  {"id": "02", "name": "กุมภาพันธ์"},
  {"id": "03", "name": "มีนาคม"},
  {"id": "04", "name": "เมษายน"},
  {"id": "05", "name": "พฤษภาคม"},
  {"id": "06", "name": "มิถุนายน"},
  {"id": "07", "name": "กรกฎาคม"},
  {"id": "08", "name": "สิงหาคม"},
  {"id": "09", "name": "กันยายน"},
  {"id": "10", "name": "ตุลาคม"},
  {"id": "11", "name": "พฤศจิกายน"},
  {"id": "12", "name": "ธันวาคม"}
]



export const year = (startYear) => {
    var currentYear = new Date().getFullYear(), years = [];
    currentYear = currentYear+543
    // var currentYear = new Date().getFullYear(), years = [];
    // startYear = startYear || 1900;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    return years;
}
 
