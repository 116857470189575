/*  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Menu from "pages/menuMain/menu";
import _ from "lodash";
import CollectpointHeader from "../centerComponent/collectPointHeader";
import SubMenu from "../centerComponent/Submenu";
import { CharacterCollectionList } from "services/Api/Module/CharacterSticker";
import ThemeComponent from "./ThemeComponent";
import helper from "services/helper";

const CharacterProcess = () => {
  const [characterCollection, setCharacterCollection] = useState([]);
  const [isBannerLucky, setIsBannerLucky] = useState(false);
  const [isBannerRedeem, setIsBannerRedeemed] = useState(false);
  const [isBannerEmptyPillow, setIsBannerEmptyPillow] = useState(false);
  const [disabledNormal, setDisabledNormal] = useState(false);

  const [disabledLucky, setDisabledLucky] = useState(false);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customer = stateProfile.id;

  const normalType = "SK214";
  const luckyType = "SKLUCKY";

  const getCharacterCollection = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CharacterCollectionList({
        params: { customer },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchCharacterCollection = async () => {
    try {
      const resData = await getCharacterCollection();
      console.log("resData >>>", resData);

      setCharacterCollection(resData.result.theme);
      setIsBannerRedeemed(
        resData.result.isReadyNormalToRedeemed === 1 &&
          resData.result.isHaveNormalStockPillow === 1 &&
          resData.result.isNormalRedeemed === 0
      );
      setIsBannerLucky(
        resData.result.isReadyLuckyToRedeemed === 1 &&
          resData.result.isHaveLuckyStockPillow === 1 &&
          resData.result.isLuckyRedeemed === 0
      );

      setIsBannerEmptyPillow(
        resData.result.isHaveNormalStockPillow === 0 &&
          resData.result.isNormalRedeemed === 0
      );
      setDisabledNormal(
        resData.result.isNormalRedeemed === 1 &&
          resData.result.isReadyNormalToRedeemed === 0
      );

      setDisabledLucky(
        resData.result.isLuckyRedeemed === 1 &&
          resData.result.isReadyLuckyToRedeemed === 0
      );

      console.log("isBannerLucky >>", isBannerLucky);
      console.log("disabledLucky >>", disabledLucky);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCharacterCollection();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageCharacter-Collection">
        <main>
          <div className="wrapContent">
            <CollectpointHeader />
            <div className="bMenu">
              <SubMenu />
            </div>
            <section id="pContent">
              <div className="bContent">
                <div className="bTitle">
                  <h2>Character Collection</h2>
                  <p>
                    สะสม Character ในแต่ละธีมให้ครบ 214 ตัว รับของรางวัลสุดพิเศษ
                    (ของรางวัลมีจำนวนจำกัด)
                  </p>
                </div>

                {isBannerLucky ? (
                  <div className="sticker-theme lucky">
                    <p>
                      Special Character : <span>Lucky Koala</span>
                    </p>
                    <a
                      onClick={() =>
                        navigate("/pillow-detail?type=" + luckyType)
                      }
                    >
                      <img src="assets/img/ci/phase2/character-collection/img-banner.png" />
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {disabledLucky ? (
                  <div className="sticker-theme lucky">
                    <p>
                      Special Character : <span>Lucky Koala</span>
                    </p>
                    <a>
                      <img src="assets/img/ci/phase2/character-collection/img-banner-disable.png" />
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {isBannerRedeem ? (
                  <div className="collect-success redeem">
                    <a
                      onClick={() =>
                        navigate("/pillow-detail?type=" + normalType)
                      }
                      className={`btn`}
                    >
                      แลกรางวัล
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {disabledNormal ? (
                  <div className="collect-success redeem">
                    <a
                      onClick={() =>
                        navigate("/pillow-detail?type=" + normalType)
                      }
                      className={`btn ${disabledNormal ? "disabled" : ""}`}
                      style={helper.STYLE.aDisable(disabledNormal)}
                    >
                      แลกรางวัล
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {/* //Out of stock //add className="hide" for hide element */}
                {isBannerEmptyPillow ? (
                  <div className="collect-success outstock"></div>
                ) : (
                  ""
                )}

                {/* ใช้ตอน pillow หมดโรงงาน */}
                {/* <div className="collect-success out"></div> */}

                {characterCollection.map((item, index) => {
                  return <ThemeComponent key={index} item={item} />;
                })}
              </div>
            </section>
          </div>
        </main>
        <Menu />
      </div>
    </div>
  );
};

export default CharacterProcess;
