import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const Policy = ({
  isDrawerCondition,
  isDrawerPolicy,
  isDrawerPromotion,
  sendDataToParent,
}) => {
  const navigate = useNavigate();
  const closeDrawer = () => {
    navigate(-1);
    // เมื่อมีการกดปุ่มส่งข้อมูล ให้เรียกใช้ callback function ที่ถูกส่งมาจาก parent component
    sendDataToParent(false);
  };
  return (
    <div>
      <section
        id="drawerCondition"
        style={{ display: isDrawerCondition ? "block" : "none" }}
      >
        <article>
          <div className="bTop">
            <a
              /*href={"/"}*/ className="btnCloseDrawer"
              onClick={closeDrawer}
            ></a>
          </div>
          <div className="bContent">
            <h1>Condition</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <h6>Header 6</h6>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat
              explicabo earum ducimus quae accusantium doloremque eos labore ab
              nobis maxime delectus quibusdam, id similique, incidunt totam
              excepturi voluptates saepe ipsum?
            </p>
            <br />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              consectetur impedit dolorum adipisci ex! Provident deserunt sunt
              porro alias eos voluptatibus voluptatum eius necessitatibus autem!
              Voluptatem, eligendi pariatur? Assumenda, vitae!
              <br />
              <b>Bold</b>
              <br />
              <i>italic</i>
              <br />
              <u>Underline</u>
              <br />
              <strong>Strong</strong>
              <br />
              <sup>Super</sup>
              <br />
              <sub>Subscripted</sub>
              <br />
              <a href="#">Link</a>
            </p>
            <br />
            <ul>
              <li>List</li>
              <li>List</li>
              <li>List</li>
              <li>List</li>
            </ul>
            <br />
            <ol>
              <li>Order</li>
              <li>Order</li>
              <li>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id et
                cupiditate quasi impedit? Fugit alias ipsam nostrum quam
                cupiditate, eveniet ad laudantium reprehenderit tenetur cum
                dolorem porro iure eius eligendi.
              </li>
              <li>Order</li>
            </ol>
            <hr />
            <br />
          </div>
        </article>
      </section>
      <section
        id="drawerPolicy"
        style={{ display: isDrawerPolicy ? "block" : "none" }}
      >
        <article>
          <div className="bTop">
            <a
              /*href={"#"}*/ className="btnCloseDrawer"
              onClick={closeDrawer}
            ></a>
          </div>
          <div className="bContent">
            <h1>นโยบายความเป็นส่วนตัว</h1>
            <h2>บทนำ</h2>

            <p>
              เพื่อการประมวลผลข้อมูลส่วนบุคคล บริษัท ไทยลอตเต้ จำกัด
              และกลุ่มบริษัทในเครือ (“เรา” หรือ “บริษัทฯ”)
              จำเป็นต้องได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
              (“ผู้ร่วมกิจกรรม”, “ผู้ใช้ระบบ” หรือ “ท่าน”)
              โดยได้มีการแจ้งวัตถุประสงค์ของการประมวลผลข้อมูลส่วนบุคคลก่อนการขอความยินยอม(ข้อตกลงฯ)แล้ว
              เช่น การเก็บข้อมูลส่วนบุคคล มาตรา 6
              หรือการเก็บรวบรวมข้อมูลส่วนบุคคลที่อ่อนไหวด้วยวัตถุประสงค์ที่ไม่เป็นไปตามข้อยกเว้นมาตรา
              26 แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              และกรณีการตลาดที่เหนือความคาดหมายของเจ้าของข้อมูลส่วนบุคคล
              ข้อมูลส่วนบุคคลถือเป็นสิ่งที่สำคัญ
              ดังนั้นเราจึงได้พัฒนานโยบายความเป็นส่วนตัวนี้เพื่ออธิบายถึงวิธีที่เราเก็บรวบรวม
              ใช้ เปิดเผย โอน
              และดำเนินการเกี่ยวกับข้อมูลของท่านเมื่อท่านทำการเข้าถึงแอปพลิเคชันไลน์ทางการ
              @koalas-march (หรือเรียกว่า “ระบบ”)
              กรุณาอ่านและทำความเข้าใจเกี่ยวกับนโยบายความเป็นส่วนตัวนี้ก่อนใช้งานระบบหรือเว็บไซต์อื่นใดบนระบบหมายถึงท่านยินยอมให้ทางเราเก็บรวบรวม
              ใช้ เปิดเผย และประมวลผลข้อมูล
              รวมถึงรักษาข้อมูลส่วนบุคคลของท่านตามที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้
            </p>
            <br />
            <p>
              นโยบายความเป็นส่วนตัวนี้มีผลบังคับใช้ตั้งแต่วันที่ท่านใช้งานโดยอาจมีการแก้ไขเปลี่ยนแปลง
              เราจะทำการลงประกาศฉบับแก้ไขเปลี่ยนแปลงที่นี่
              และระบุวันที่ที่มีการปรับปรุงข้อมูลล่าสุด (วันที่ 16 กุมภาพันธ์
              พ.ศ. 2566 หรือวันที่มีผลบังคับใช้)
              ดังนั้นท่านควรตรวจสอบนโยบายความเป็นส่วนตัวนี้เป็นระยะเพื่อรับทราบการเปลี่ยนแปลงที่อาจเกิดขึ้น
              การที่ท่านเข้ามาใช้งานระบบหมายความว่าท่านยอมรับการเปลี่ยนแปลงนั้น
              ๆ
              <br />
              <b>ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม</b>
              <br />
              <p>
                ในนโยบายความเป็นส่วนตัวนี้ “ข้อมูลส่วนบุคคล”
                หมายถึงข้อมูลหรือรายละเอียดข้อมูลที่สามารถใช้ระบุตัวตนได้
                เราจะดำเนินรวบรวมข้อมูลของท่านเมื่อท่านใช้งานบนระบบ
                ข้อมูลที่เรารวบรวมได้แบ่งออกเป็นสามประเภทคือ
              </p>
              <br />
              <p>
                (ก) ข้อมูลส่วนบุคคลที่ท่านมอบให้แก่เรา
                ท่านอาจมอบข้อมูลดังต่อไปนี้ให้แก่เรา
              </p>
              <br />
              <li>
                รายละเอียดข้อมูลส่วนบุคคล เช่น ชื่อ - นามสกุล ที่อยู่
                หมายเลขโทรศัพท์มือถือ หรือข้อมูลการติดต่ออื่น ๆ
                เมื่อท่านลงทะเบียนผ่านระบบ หรือการติดต่อเราทางโทรศัพท์
              </li>
              <li>
                ข้อมูลการทำธุรกรรมรวมถึงข้อมูลเกี่ยวกับผลิตภัณฑ์ที่ท่านซื้อ ราคา
                วิธีการชำระเงิน ร้านค้าที่ท่านซื้อ และรายละเอียดการชำระเงินอื่น
                ๆ
              </li>
              <li>
                ข้อมูลบัญชีผู้ใช้ เช่น ชื่อผู้ใช้ รูปภาพของท่าน (หรือข้อมูลอื่น
                ๆ ที่ระบุถึงตัวท่าน)
                ที่ใช้เพื่อเข้าถึงระบบและข้อมูลส่วนบุคคลอื่น ๆ
                ที่ท่านให้เราเมื่อท่านโต้ตอบกับเรา
              </li>


              
              <i>italic</i>
              <br />
              <u>Underline</u>
              <br />
              <strong>Strong</strong>
              <br />
              <sup>Super</sup>
              <br />
              <sub>Subscripted</sub>
              <br />
              <a href="#">Link</a>
            </p>
            <br />
            <ul>
              <li>List</li>
              <li>List</li>
              <li>List</li>
              <li>List</li>
            </ul>
            <br />
            <ol>
              <li>Order</li>
              <li>Order</li>
              <li>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id et
                cupiditate quasi impedit? Fugit alias ipsam nostrum quam
                cupiditate, eveniet ad laudantium reprehenderit tenetur cum
                dolorem porro iure eius eligendi.
              </li>
              <li>Order</li>
            </ol>
            <hr />
            <br />
          </div>
        </article>
      </section>
      <section
        id="drawerPromotion"
        style={{ display: isDrawerPromotion ? "block" : "none" }}
      >
        <article>
          <div className="bTop">
            <a
              /*href={"#"}*/ className="btnCloseDrawer"
              onClick={closeDrawer}
            ></a>
          </div>
          <div className="bContent">
            <h1>Promotion</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <h6>Header 6</h6>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat
              explicabo earum ducimus quae accusantium doloremque eos labore ab
              nobis maxime delectus quibusdam, id similique, incidunt totam
              excepturi voluptates saepe ipsum?
            </p>
            <br />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              consectetur impedit dolorum adipisci ex! Provident deserunt sunt
              porro alias eos voluptatibus voluptatum eius necessitatibus autem!
              Voluptatem, eligendi pariatur? Assumenda, vitae!
              <br />
              <b>Bold</b>
              <br />
              <i>italic</i>
              <br />
              <u>Underline</u>
              <br />
              <strong>Strong</strong>
              <br />
              <sup>Super</sup>
              <br />
              <sub>Subscripted</sub>
              <br />
              <a href="#">Link</a>
            </p>
            <br />
            <ul>
              <li>List</li>
              <li>List</li>
              <li>List</li>
              <li>List</li>
            </ul>
            <br />
            <ol>
              <li>Order</li>
              <li>Order</li>
              <li>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id et
                cupiditate quasi impedit? Fugit alias ipsam nostrum quam
                cupiditate, eveniet ad laudantium reprehenderit tenetur cum
                dolorem porro iure eius eligendi.
              </li>
              <li>Order</li>
            </ol>
            <hr />
            <br />
          </div>
        </article>
      </section>
    </div>
  );
};

export default Policy;
