/* eslint-disable react/jsx-no-target-blank */
import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { GetPillowHistoryView } from "services/Api/Module/Pillow";
import helper from "services/helper";

const PillowHistoryDetail = () => {
  const [pillowHistoryData, setPillowHistoryData] = useState({});
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const pillowHistoryId = searchParams.get("id");

  const getPillowHistoryView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };

    try {
      const { data } = await GetPillowHistoryView({
        pillowHistoryId: pillowHistoryId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchPillowHistoryView = async () => {
    try {
      const resData = await getPillowHistoryView();
      setPillowHistoryData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPillowHistoryView();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageHistory-Reward-Details">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage" style={{ textAlign: "center" }}>
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/history-pillow")}
                ></a>
                <h2>
                  ประวัติการแลก
                  <br /> Character Collection
                </h2>
              </div>
            </section>
            <section id="pContent">
              <h1>รายละเอียด</h1>
              <div className="bRewardMain bCard">
                <div className="bTitle" style={{ textAlign: "center" }}>
                  <h2 style={{ fontWeigth: 700 }}>แลกของรางวัลพิเศษ</h2>
                  <p>{pillowHistoryData.pillowDescription}</p>
                  {pillowHistoryData.trackingStatus ? (
                    <div className="tStatus" data-status="shipped">
                      จัดส่งแล้ว
                    </div>
                  ) : (
                    <div className="tStatus" data-status="pending">
                      รอจัดส่ง
                    </div>
                  )}
                </div>
                <div className="bRewardDetails">
                  <div className="bPoint">
                    <h2>{pillowHistoryData.pillowTitle}</h2>
                    {/* <div>
                      <span>ใช้ Point แลก</span>
                      <p>
                        {helper.FN.toNumberWithCommas(
                          pillowHistoryData.pointToRedeem
                        )}
                      </p>
                    </div> */}
                  </div>
                  <div className="bProductImg">
                    <div className="bPic">
                      <img
                        src={
                          pillowHistoryData.pillowImg
                            ? pillowHistoryData.pillowImg
                            : "https://dummyimage.com/200x200/fff/000"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bShipped bCard">
                <div className="bTrackingMain">
                  <div className="bDetails">
                    <div className="bTransport">
                      ขนส่งโดย:
                      {/* <!-- ถ้ายังไม่ส่งของให้เอารูปออก --> */}
                      {pillowHistoryData.trackingStatus == 1 ? (
                        <img
                          src="assets/img/ci/transport/logo-flash.svg"
                          alt=""
                        />
                      ) : (
                        <span>รอการจัดส่ง</span>
                      )}
                    </div>
                    <div className="bTrackingNumber">
                      หมายเลขพัสดุ
                      {
                        // PillowHistoryData.trackingStatus == 1 &&
                        pillowHistoryData.trackingNumber ? (
                          <p>{pillowHistoryData.trackingNumber}</p>
                        ) : (
                          <span>รอการจัดส่ง</span>
                        )
                      }
                    </div>
                    <div className="bCondition">
                      สามารถตรวจสอบสถานะการจัดส่งได้
                      <br />
                      โดยการกดที่หมายเลขพัสดุ
                    </div>
                  </div>
                  <div className="bBtn">
                    {
                      // PillowHistoryData.trackingStatus == 1
                      pillowHistoryData.trackingNumber ? (
                        <a
                          href={`https://www.flashexpress.co.th/fle/tracking?se=${pillowHistoryData.trackingNumber}`}
                          className="btnTracking"
                          target="_blank"
                        >
                          เช็กพัสดุ
                        </a>
                      ) : (
                        // ? <a href={`${PillowHistoryData.trackingLink}`} className="btnTracking" target="_blank">เช็กพัสดุ</a>
                        <a className="btnTracking">เช็คพัสดุ</a>
                      )
                    }
                  </div>
                </div>
                <div className="bCodeDate">
                  <div className="bRow">
                    <p>
                      <span>รหัส:</span>
                      {pillowHistoryData.orderCode}
                    </p>
                    <p>
                      <span>วันที่/เวลาแลกของรางวัล:</span>
                      {/* 20/07/2566 12:21:01 น.<br/> */}
                      {helper.TIME.toShortDateTimeNormalTH(
                        pillowHistoryData.createdOn
                      )}
                    </p>
                  </div>
                </div>
                <div className="bAddress">
                  <div className="bRow">
                    <p>
                      <span>ชื่อผู้แลก:</span>
                      {pillowHistoryData.firstName} {pillowHistoryData.lastName}
                    </p>
                    <p>
                      <span>เบอร์โทรศัพท์:</span>
                      {helper.FORMAT.TEL(pillowHistoryData.mobileNo)}
                    </p>
                  </div>
                  <div className="bRow">
                    <p>
                      <span>สถานที่จัดส่ง:</span>
                      {/* 12/29 หมู่บ้านไชยลดา ถนนราษฎรพัฒนา แขวงราษฏรพัฒนา เขตสะพานสูง กรุงเทพมหานคร 10230  */}
                      {pillowHistoryData.address}{" "}
                      {pillowHistoryData.subDistrictName}{" "}
                      {pillowHistoryData.districtName}{" "}
                      {pillowHistoryData.provinceName}{" "}
                      {pillowHistoryData.postcode}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bBtn">
                <a onClick={() => navigate("/history-pillow")} className="btn">
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PillowHistoryDetail;
