/*  */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import helper from "services/helper";

const ThemeComponent = ({ item = {} }) => {
  const navigate = useNavigate();
  const [loopImg, setLoopImg] = useState(["01.png", "02.png", "03.png"]);
  const disabled = item.isOpen === 0 ? true : false;

  const className =
    item.isSpecial === 1
      ? "sticker-theme hide"
      : item.isOpen === 1 && item.isComplete === 0
      ? "sticker-theme"
      : item.isOpen === 1 && item.isComplete === 1
      ? "sticker-theme complete"
      : item.isOpen === 0
      ? "sticker-theme comming-soon"
      : "";

  const imgSticker =
    "assets/img/ci/phase2/icon-sticker/" + item.folderPath + "/";
  return (
    <a
      style={helper.STYLE.aDisable(disabled)}
      onClick={() => navigate("/character-detail?id=" + item.id)}
    >
      <div className={className}>
        {item.name.match("40th") ? (
          <p>
            Koala’s Theme :{" "}
            <span>
              40<sub>th</sub> Anniversary
            </span>
          </p>
        ) : (
          <p>
            Koala’s Theme : <span>{item.name}</span>
          </p>
        )}
        <div className="bSumSticker" data-theme={item.id}>
          <div className="show-Sticker">
            {loopImg.map((val, index) => (
              <div key={index} className="bSticker">
                <img className="collection" src={imgSticker + val} />
              </div>
            ))}
          </div>
          <div className="sumSticker">
            <p>
              คุณมี Character <br />
              <span>
                {" "}
                {item.stickerAmount}/{item.stickerConfigAmount}
              </span>
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ThemeComponent;
