import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LuckydrawHistoryList } from "services/Api/Module/Luckydraw";
import helper from "services/helper";
import Submenu2 from "pages/centerComponent/Submenu2";
import EmptyData from "pages/centerComponent/EmptyData";

const DEF_LIMIT = 10;
const DEF_OFFSET = 0;
const LuckydrawHistory = () => {
  const [luckydrawHistorys, setLuckydrawHistorys] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const limit = DEF_LIMIT;
  const offset = searchParams.get("offset") ?? DEF_OFFSET;

  const getLuckydrawHistoryList = async () => {
    let _return = {
      status: false,
      result: null,
      rowCount: 0,
    };

    try {
      const { data } = await LuckydrawHistoryList({
        params: { customer: customerId, limit, offset },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchLuckydrawHistoryList = async () => {
    try {
      const resData = await getLuckydrawHistoryList();
      setLuckydrawHistorys(resData.result);
      setRowCount(resData.rowCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLuckydrawHistoryList();
  }, [stateProfile, offset]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageHistory-Luckydraw">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/profile")}
                ></a>
                <h2>ประวัติการใช้ Point</h2>
              </div>
            </section>
            <Submenu2 />
            <section id="pContent">
              <h1>ประวัติการลุ้น Lucky Draw</h1>
              {rowCount > 0 ? (
                luckydrawHistorys.map((item, index) => {
                  return (
                    <article key={index} className="bCard">
                      <div className="bHistoryDetails">
                        <div className="bDateStatus">
                          <p className="tDate">
                            แลกเมื่อวันที่:{" "}
                            <span>
                              {helper.TIME.toFullDateTH(item.createdOn)}
                            </span>
                          </p>
                        </div>
                        <div className="bDetailsMain">
                          <div className="bPic">
                            <img
                              src={
                                item.luckyDrawImg
                                  ? item.luckyDrawImg
                                  : "https://dummyimage.com/200x200/fff/000"
                              }
                              alt=""
                            />
                          </div>
                          <div className="bDetails">
                            <div className="bText">
                              <h3>{item.luckyDrawName}</h3>
                              <p>{item.luckyDrawDescription}</p>
                              <span>รหัส: {item.orderCode}</span>
                            </div>
                            <div className="bPoint">
                              <div className="point">
                                {helper.FN.toNumberWithCommas(
                                  item.pointToLuckyDraw
                                )}
                              </div>
                              <p>
                                จำนวนสิทธิ์ที่ลุ้น :
                                <span>
                                  {helper.FN.toNumberWithCommas(item.drawTimes)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="bBtn">
                          <a
                            onClick={() =>
                              navigate(
                                "/history-luckydraw-details?orderCode=" +
                                  item.orderCode
                              )
                            }
                            className="btn outline"
                          >
                            ดูรายละเอียด
                          </a>
                        </div>
                      </div>
                    </article>
                  );
                })
              ) : (
                <EmptyData />
              )}
              {/* <?php for ($y = 1; $y <= 4; $y++) {?> */}

              {/* <?php };?> */}
            </section>
            <Menu />
          </div>
        </main>
      </div>
    </div>
  );
};

export default LuckydrawHistory;
