import React, { useState } from "react";
import MyContext from "./ModalContext";
import ModalAlert from "components/modal/ModalAlert";

const defaultInfo = {
  open: false,
  title: "$title",
  subTitle: "$subTitle",
  content: "",
  onExit: () => {},
};
const ModalProvider = ({ children }) => {
  const [infoShow, setInfoShow] = useState(false);
  const [info, setInfo] = useState(defaultInfo);

  const onAlertInfo = (newData) => {
    setInfoShow(newData.open);
    if (newData.open) {
      setInfo(newData);
    } else {
      setTimeout(() => setInfo(newData), 200);
    }
  };

  return (
    <MyContext.Provider value={{ mAlert: { info: onAlertInfo } }}>
      <ModalAlert
        open={infoShow}
        title={info.title}
        subTitle={info.subTitle}
        content={info.content}
        onClose={() => {
          onAlertInfo(defaultInfo);
          info.onExit();
        }}
      />
      {children}
    </MyContext.Provider>
  );
};

export default ModalProvider;
