import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  banners: [],
  isLoaded: false
};

export const BannerSlice = createSlice({
  name: "Banner",
  initialState: defaultData,
  reducers: {
    setBanners: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
      // state.dataUser = action.payload;
    },
    resetBanners: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setBanners, resetBanners } = BannerSlice.actions;

export default BannerSlice.reducer;
