import { useNavigate } from "react-router-dom";
import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
// import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { QRCode } from "react-qrcode-logo";
import { koalaImg } from "utility/koalaImg";
import { useSelector } from "react-redux";

const ModalQrCode = ({
  isOpen = false,
  onClose,
  memberId = null,
  pProfileImgUrl = null,
  lineImageUrl = null,
  Name = null,
}) => {
  const navigate = useNavigate();
  const [modalFilter, setModalFilter] = useState(null);
  const [tipsModal, setTipsModal] = useState(null);
  const [buttonText, setButtonText] = useState("บันทึกการ์ดโคอะลา มาร์ช");
  const [openTips, setOpenTips] = useState(false);
  const koalaImage = koalaImg;

  useEffect(() => {
    if (modalFilter && isOpen) {
      openModal();
      listenerCloseModal();
      openListenerTips();
      closeListenerTips();
    }
  }, [modalFilter, isOpen]);

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModal");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  const openListenerTips = () => {
    const openTips = document.querySelector(".btnTips");
    openTips.addEventListener("click", (e) => {
      if (e?.target?.className === "btnTips") {
        document.querySelector(".bTips").style.display = "block";
        document.getElementById("ModalQR").classList.add("tips");
      }
    });
  };
  // ----------- Close Tips---------------------

  const closeListenerTips = () => {
    const btnCloseModal = document.querySelector(".btnCloseTips");
    btnCloseModal.addEventListener("click", (e) => {
      if (e?.target?.className === "btnCloseTips") {
        document.querySelector(".bTips").style.display = "none";
        document.getElementById("ModalQR").classList.remove("tips");
      }
    });
  };
  // ----------- Close Tips---------------------

  useEffect(() => {
    const initModalFilter = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalQR"),
    });
    setModalFilter(initModalFilter);

    return () => {
      initModalFilter.destroy();
    };
  }, []);

  const openModal = () => {
    modalFilter.open();
  };

  const closeModal = () => {
    modalFilter.close();
    onClose();
  };

  const handleButtonClick = () => {
    setButtonText("กรุณารอซักครู่");

    // กำหนด ID ของ div ที่ต้องการจะ Capture
    const capDiv = "ModalQR";

    setTimeout(() => {
      // Capture รูปภาพจาก div
      html2canvas(document.getElementById(capDiv)).then(function (canvas) {
        const dataUrl = canvas.toDataURL("image/jpeg");
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "Koala-Member-QR.jpg";
        // เพิ่ม element a ไปยัง DOM
        document.body.appendChild(link);

        // แสดงข้อความให้ผู้ใช้คลิกเพื่อดาวน์โหลด
        const message = document.createElement("p");
        message.textContent = "Tap the image to download.";
        document.body.appendChild(message);

        // เพิ่ม event listener สำหรับการคลิกที่รูปภาพ
        link.addEventListener("click", function () {
          // ลบ element a และข้อความที่แสดง
          document.body.removeChild(link);
          document.body.removeChild(message);
        });

        // คลิกที่ element a เพื่อแสดงการดาวน์โหลด
        link.click();
      });

      setButtonText("บันทึกการ์ดโคอะลา มาร์ช");
    }, 1000);
    // setTimeout(() => {
    //   // Capture รูปภาพจาก div
    //   html2canvas(document.getElementById(capDiv)).then(function (canvas) {
    //     // สร้างลิงก์สำหรับดาวน์โหลดรูป
    //     const link = document.createElement('a');
    //     link.href = canvas.toDataURL('image/jpeg');
    //     link.download = 'Koala-Member-QR.jpg';
    //     // นำลิงก์ไปแทรกในเอกสาร
    //     document.body.appendChild(link);
    //     // คลิกที่ลิงก์เพื่อดาวน์โหลด
    //     link.click();
    //     // ลบลิงก์ทิ้ง
    //     document.body.removeChild(link);
    //   });

    //   setButtonText('บันทึกการ์ดโคอะลา มาร์ช');
    // }, 1000);
  };

  return (
    <div className="bModal bCard" id="ModalQR" style={{ display: "none" }}>
      <div className="bInner">
        <div className="bQR">
          <div className="bPic">
            {memberId ? (
              <QRCode
                value={memberId}
                size={180}
                logoImage={koalaImage}
                logoWidth={50}
                logoOpacity={1}
                enableCORS={true}
                qrStyle="squares"
                eyeRadius={0}
                id={"QR"}
              />
            ) : (
              <img src="https://dummyimage.com/200x200" alt="" />
            )}
          </div>

          <div>
            ชวนเพื่อนสมัครง่ายๆ เพียงสแกน QR Code
            <i className="btnTips" id="tips">
              <div className="bTips">
                <a className="btnCloseTips"></a>
                <span>ชวนเพื่อนมาเป็นสมาชิกรับ Point เพิ่ม</span>
                <p>
                  ชวนเพื่อนมาสมัครด้วยการเปิด QR code ให้เพื่อนสแกน หรือ
                  ก็อปปี้เลขไอดี ของตัวเอง ส่งให้เพื่อนกรอก
                </p>
                <p>
                  เลือกวิธีไหนก็ได้ คุณได้รับ point เพิ่มทันที ยิ่งชวนเพื่อนเยอะ
                  ยิ่งได้ Point เยอะ ชวนเพื่อนเลย!
                </p>
              </div>
            </i>
          </div>
        </div>
        <div className="bMemberDetails">
          <div className="bDisplay">
            <div
              className="bPic"
              style={{
                backgroundImage: lineImageUrl
                  ? `url(${lineImageUrl})`
                  : `url(${pProfileImgUrl})`,
              }}
            ></div>
          </div>
          <div className="bDetails">
            <h2>{Name}</h2>
            <p className="bMemberID">
              ID : <a className="btnCopy">{memberId}</a>
            </p>
          </div>
        </div>
        <div className="bBtn">
          <p>
            แคปหน้าจอนี้เพื่อส่งต่อ
            <br />
            การ์ดโคอะลา มาร์ช ให้กับเพื่อน
            <br />
            เพื่อรับพอยต์สะสม x1 พอยต์
          </p>
        </div>
        <a className="btnCloseModal" id="btnCloseModal"></a>
      </div>
    </div>
  );
};

export default ModalQrCode;
