import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { GetPillowDetail } from "services/Api/Module/Pillow";
import helper from "services/helper";

const PillowDetail = () => {
  const [pillowData, setPillowData] = useState({});
  const [isAccept, setIsAccept] = useState(false);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  const stateCurrentPoint = useSelector((state) => state.currentPoint);

  let [searchParams, setSearchParams] = useSearchParams();
  const pillowType = searchParams.get("type");
  const disabledBTN = false;

  const getPillowView = async () => {
    let _return = {
      status: false,
      result: {},
    };
    try {
      const { data } = await GetPillowDetail({
        type: pillowType,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      } else {
        navigate("/character-collection");
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const gotoConfrim = () => {
    navigate(`/pillow-confirm?type=${pillowType}`);
  };

  const fetchPillowView = async () => {
    try {
      const resData = await getPillowView();

      setPillowData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPillowView();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageReward-character-Details">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a
                  className="btnIconBack"
                  onClick={() => navigate("/character-collection")}
                ></a>
                <h2>Character Collection</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bDetailsMain bCard">
                <div className="bTitle">
                  <h2>แลกของรางวัลพิเศษ</h2>
                  <p>{pillowData.description}</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h3>{pillowData.name}</h3>
                </div>

                <div className="bProductImg">
                  <div className="bPic">
                    <img
                      src={
                        pillowData.img
                          ? pillowData.img
                          : "https://dummyimage.com/200x200/fff/000"
                      }
                      alt=""
                    />
                  </div>
                  <div className="bTag">จัดส่งฟรี!</div>
                </div>
                <div className="bCondition">
                  <h3>เงื่อนไขการรับของรางวัล</h3>
                  <div
                    className="bText"
                    dangerouslySetInnerHTML={{ __html: pillowData.conditions }}
                  />
                </div>
              </div>
            </section>
            <section id="pBtnPage">
              <div className="bBtn">
                <a
                  onClick={() => gotoConfrim()}
                  className={`btn ${disabledBTN ? "disabled" : ""}`}
                  style={helper.STYLE.aDisable(disabledBTN)}
                >
                  แลกรางวัล
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PillowDetail;
