import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const PolicyLuckydraw = ({ open = false, onBack = () => {} }) => {
  const styleShow = { display: open ? "block" : "none" };

  const goBack = () => {
    onBack();
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [open]);

  return (
    <div style={styleShow}>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pagePolicy-Luckydraw">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a className="btnIconBack" onClick={goBack}></a>
                <h2>เงื่อนไขการลุ้นของรางวัล</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bContent bCard">
                <h1>เงื่อนไขการลุ้นของรางวัล</h1>
                <div className="bDetails">
                  <h2>
                    <center>
                      เริ่มแลกได้ตั้งแต่วันที่ 1 พฤษภาคม 2567 ถึง วันที่ 31
                      ธันวาคม 2567
                    </center>
                  </h2>
                  <p>
                    1. สมาชิกโคอะลา มาร์ช ที่มีคะแนนสะสม
                    สามารถแลกคะแนนสิทธิ์สำหรับลุ้นชิงโชค
                    โดยมีอัตราการแลกคะแนนคือ ทุก 1 พอยต์ รับ 1 สิทธิ์
                  </p>
                  <p>
                    2. สมาชิกโคอะลา มาร์ช สามารถแลกคะแนนได้ไม่จำกัดจำนวนสิทธิ์
                    ตามคะแนนสะสมที่คงเหลือของสมาชิก ณ วันที่สมาชิกทำการแลกคะแนน
                    และการแลกคะแนนเพื่อรับสิทธิ์ลุ้นโชคจะต้องทำผ่านระบบสะสมคะแนนบนไลน์ทางการ
                    @koalas-march เท่านั้น
                  </p>
                  <p>
                    เมื่อครบกำหนดระยะเวลา ทางบริษัทฯ
                    จะทำการรวบรวมรายชื่อของผู้เข้าร่วมรายการที่แลกคะแนนถูกต้อง
                    โดยทำการพิมพ์รายชื่อ- นามสกุล และวันที่ทำการแลกคะแนน
                    ของทุกคนลงบนกระดาษตัดเป็นชิ้นส่วนตามจำนวนสิทธิ์
                    และนำมาจับรางวัล ณ ห้องสัมมนา บริษัท วินเทอร์อีเจนซี่ จำกัด
                    อาคาร 140 ไวร์เลส ชั้นที่ 17 เลขที่ 140 ถนนวิทยุ แขวงลุมพินี
                    เขตปทุมวัน กรุงเทพมหานคร 10330
                  </p>
                  <p>
                    บริษัทฯ จะประกาศรายชื่อผู้โชคดีภายใน 7 วัน
                    ตามลำดับการจับรางวัลในแต่ละครั้ง ผ่านทาง
                    <br /> เฟซบุ๊กแฟนเพจ Lotte Koala’s March
                    (https://www.facebook.com/ilovekoalamarch)
                    <br /> ไลน์ออฟฟิเชียลแอคเคาน์ LINE Official Account:
                    @koalas-march{" "}
                  </p>
                  <p>
                    3. มีการจับรางวัล 1 ครั้ง ดังนี้
                    <br />
                    ครั้งที่ 1 เริ่มวันที่ 1 พฤษภาคม 2567 สิ้นสุดวันที่ 31
                    ธันวาคม 2567 กำหนดจับรางวัลวันที่ 31 มกราคม 2568 เวลา 11.00
                    น.
                  </p>
                  <p>
                    <strong>
                      <u>หมายเหตุ</u>
                    </strong>{" "}
                    ทั้งนี้ผู้โชคดีที่เคยได้รับรางวัลไปแล้วสามารถได้รับรางวัลในการจับรางวัลในครั้งที่
                    2{" "}
                  </p>
                  <p>
                    4. ของรางวัลลุ้นโชครวมตลอดรายการ 7 รางวัล รวมมูลค่าทั้งสิ้น
                    508,300 บาท โดยของรางวัลครั้งที่ 1 มีดังนี้
                  </p>

                  <p>
                    <strong>
                      <u>ครั้งที่ 1</u>
                    </strong>
                  </p>
                  <p>
                    รางวัลที่ 1) ตั๋วเครื่องบินไปกลับ แพ็คเกจทัวร์ กรุงโตเกียว
                    ประเทศญี่ปุ่น 5 วัน 3 คืน จำนวน 2 รางวัล รางวัลละ 4 ท่าน
                    มูลค่ารางวัลละ 200,000 บาท รวม 400,000 บาท
                  </p>
                  <p>
                    รางวัลที่ 2) บัตรกำนันที่พักโรงแรมแกรนด์ เซนเตอร์ พอยต์ สเปซ
                    พัทยา (ห้อง space connex) 1 คืน จำนวน 2 รางวัล รางวัลละ 4
                    ท่าน มูลค่ารางวัลละ 12,300 บาท รวม 24,600 บาท
                  </p>
                  <p>
                    รางวัลที่ 3) โทรศัพท์มือถือ IPhone 15 128 GB จำนวน 2 รางวัล
                    (คละสี) มูลค่ารางวัลละ 32,900 บาท รวม 65,800 บา
                  </p>
                  <p>
                    รางวัลที่ 4) ไดร์เป่าผม Dyson Supersonic จำนวน 1 รางวัล
                    มูลค่า 17,900 บาท{" "}
                  </p>

                  <h2>
                    รายละเอียด กติกา
                    และเงื่อนไขการร่วมกิจกรรมลุ้นโชครับของรางวัล
                  </h2>
                  <p>
                    1.สำหรับผู้โชคดีที่ได้รับรางวัลจากการลุ้นโชคจะต้องเป็นบุคคลธรรมดา
                    ไม่จำกัดสัญชาติ
                    มีภูมิลำเนาและถิ่นที่อยู่ปัจจุบันในประเทศไทยเท่านั้น
                    โดยผู้ที่ได้รับรางวัลจะได้รับการประกาศรายชื่อ ผ่านทาง
                    เฟซบุ๊กแฟนเพจ Lotte Koala’s March
                    (https://www.facebook.com/ilovekoalamarch)
                    ไลน์ออฟฟิเชียลแอคเคาน์ LINE Official Account: @koalas-march
                    และต้องทำการติดต่อตัวแทนของบริษัทฯ
                    พร้อมนำส่งหลักฐานทางไปรษณีย์หรือช่องทางที่บริษัทกำหนดเพื่อยืนยันสิทธิ์ในการรับของรางวัลด้วยตนเอง
                    ได้แก่
                  </p>
                  <p>
                    ก.)
                    สำเนาบัตรประจำตัวประชาชนที่ชื่อตรงกับที่ได้รับรางวัลหรือกรณีชาวต่างชาติสำเนาหนังสือเดินทางพาสปอร์ต
                    1 ชุด เซ็นชื่อแล้วรับรองสำเนาถูกต้อง{" "}
                  </p>
                  <p>
                    ข.) เอกสารยืนยันรับของรางวัล 1 ชุด
                    โดยผู้ที่ได้รับรางวัลส่งหลักฐานดังกล่าว
                    ในวันและเวลาทำการภายในระยะ 30 วัน
                    นับตั้งแต่วันประกาศรายชื่อผู้โชคดี เพื่อให้ทางบริษัทฯ
                    ดำเนินการจัดส่งของรางวัลต่อไป หากเลยกำหนดถือว่าสละสิทธิ์
                    และบริษัทฯ จะมอบของรางวัลให้กับรายชื่อสำรองลำดับถัดไป
                    ถ้ารายชื่อสำรองไม่มารับรางวัลตามระยะเวลาภายใน 7 วัน
                    ทางบริษัทฯ
                    จะมอบรางวัลดังกล่าวให้กับสภากาชาดไทยหรือสาธารณะกุศลอื่นต่อไป
                  </p>
                  <p>
                    2.บริษัทขอสงวนสิทธิ์ในการมอบรางวัลให้กับบุคคลผู้โชคดี 1 ชื่อ
                    ต่อ 1 รางวัล สำหรับการจับรางวัลแต่ละครั้งเท่านั้น
                    หากปรากฏชื่อผู้โชคดีท่านเดียวกันซ้ำกันอีก
                    คณะกรรมการในการจับรางวัลจะพิจารณายกเลิกรางวัลของชื่อผู้โชคดีท่านที่ซ้ำให้เหลือเพียงรางวัลเดียวและจับชิ้นส่วนเพื่อหาผู้โชคดีรายใหม่ต่อไป
                    และขอสงวนสิทธิ์ระงับมอบของรางวัล
                    หรือเรียกคืนของรางวัลในกรณีที่บริษัทฯ
                    ตรวจสอบพบว่าผู้มีสิทธิ์ได้รับรางวัล และ/หรือ
                    ผู้รับรางวัลรายใดรายหนึ่งมีคุณสมบัติไม่ถูกต้องครบถ้วน{" "}
                  </p>
                  <p>
                    3.ระยะเวลาในการร่วมรายการคือผู้แลกคะแนน(พอยต์)
                    สำหรับของรางวัลประเภทลุ้นโชค เริ่มแลกได้ตั้งแต่วันที่ 1
                    พฤษภาคม 2567 สิ้นสุดวันที่ 31 ธันวาคม 2567
                    โดยยึดถือเวลาที่ทำการแลกคะแนน จากระบบคอมพิวเตอร์ของบริษัทฯ
                  </p>
                  <p>
                    4.กรณีทางบริษัทฯ ตรวจสอบภายหลัง
                    และพบว่าผู้ได้รับรางวัลไม่อยู่ในเงื่อนไขที่กำหนด บริษัทฯ
                    สามารถยกเลิกสิทธิ์
                    และมอบสิทธิ์ใหม่ให้ผู้ได้รับรางวัลในรายชื่อสำรองได้
                    รวมถึงขอสงวนสิทธิ์ในการมอบ, ยกเลิก, เพิกถอน,
                    หรือเรียกคืนรางวัลทั้งหมด รวมทั้งเรียกให้ชดใช้ค่าเสียหาย
                    หากมีการใช้สิทธิร่วมรายการโดยทุจริต, ปลอมแปลง, ฉ้อฉล,
                    เพื่อให้ได้มาซึ่งสิทธิในรายการนี้
                  </p>
                  <p>
                    5.ผู้โชคดีที่ได้รับรางวัลลุ้นโชคมูลค่า 1,000 บาท ขึ้นไป
                    จะต้องชำระภาษี ณ ที่จ่าย 5% ตามคำสั่งกรมสรรพากร ที่ ท.ป.
                    4/2528 ลงวันที่ 26 กันยายน 2528 ประกอบคำสั่งกรมสรรพากร ท.ป.
                    104/2554 ลงวันที่ 15 กันยายน 2544 โดยที่บริษัทฯ
                    ไม่รับผิดชอบในการชำระภาษีรายได้บุคคลประจำปี
                  </p>
                  <p>
                    6.ทางบริษัทจะจัดส่งของรางวัลให้ผู้โชคดี ภายใน 30
                    วันนับจากวันประกาศรายชื่อผู้โชคดีในแต่ละครั้ง
                    และทางผู้โชคดีจะต้องรับผิดชอบค่าใช้จ่ายอื่นใด ๆ
                    ที่เกี่ยวข้องกับการรับของรางวัล
                    และการเข้าร่วมกิจกรรมนี้ด้วยตนเอง
                  </p>
                  <p>
                    7.กรณีมีข้อพิพาทขัดแย้ง หรือต้องตีความ
                    ให้ยึดตามคำตัดสินของคณะกรรมการซึ่งถือเป็นเด็ดขาดและสิ้นสุดในทุกกรณี
                  </p>
                  <p>
                    8.พนักงานและครอบครัวของ บริษัท ไทยลอตเต้ จำกัด(“บริษัทฯ”),
                    บริษัท วินเทอร์อีเจนซี่ จำกัด, เอเจนซี่
                    รวมทั้งบุคคลหรือองค์กรที่เกี่ยวข้องกับการจัดรายการในครั้งนี้ไม่มีสิทธิ์เข้าร่วมรายการ
                  </p>
                  <p>
                    9. ใบอนุญาตเลขที่ 14/2567 ออก ณ ที่ว่าการอำเภอ ปากเกร็ด
                    จังหวัดนนทบุรี จังหวัดนนทบุรี{" "}
                  </p>
                </div>
              </div>
              <div className="bBtn">
                <a onClick={goBack} className="btn">
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PolicyLuckydraw;
