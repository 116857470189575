import $ from "jquery";
import jBox from "jbox";
import { useEffect, useState } from "react";
const ModalReceipt = ({ isOpen = false, onClose = false }) => {
  const [modalFilter, setModalFilter] = useState(null);
  useEffect(() => {
    if (modalFilter && isOpen) {
      openModal();
      listenerCloseModal();
      // listenerQrDownload();
    }
  }, [modalFilter, isOpen]);

  const listenerCloseModal = () => {
    const btnCloseModal = document.querySelector(".btnCloseModal");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  useEffect(() => {
    const initModalFilter = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      height: 762,
      content: $("#ModalReceipt"),
    });
    setModalFilter(initModalFilter);

    return () => {
      initModalFilter.destroy();
    };
  }, []);

  const openModal = () => {
    modalFilter.open();
  };
  const closeModal = () => {
    modalFilter.close();
    onClose();
  };

  return (
    <div className="bModal bCard" id="ModalReceipt" style={{ display: "none" }}>
      <div className="bInner">
        <img
          className="pathImage"
          src="assets/img/ci/scan/img-popup-reciept.jpg"
          alt=""
        />
        <a className="btnCloseModal"></a>
      </div>
    </div>
  );
};

export default ModalReceipt;
