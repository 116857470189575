import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const EmptyData = ({ }) => {
 

  return (
    <div  style={{padding: '12px'}} >
      <h1 style={{ fontSize: '1rem'}}>ไม่พบข้อมูล</h1>
    </div>
  );
};

export default EmptyData;
