import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Menu from "pages/menuMain/menu";
import moment from "moment";

const Question = () => {
  const navigate = useNavigate();
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;

  useEffect(() => {
    $(".btnCopy").on("click", function () {
      var textToCopy = $(this).text();

      // Create a temporary input element
      var tempInput = $("<input>");
      $("body").append(tempInput);
      tempInput.val(textToCopy).select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the temporary input
      tempInput.remove();

      $("body").addClass("copy");
      setTimeout(() => {
        $("body").removeClass("copy");
      }, 1000);
    });

    // Don't forget to clean up any resources when the component unmounts
    return () => {
      $(".btnCopy").off("click");
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const {
    address,
    birthDate,
    currentPoint,
    firstName,
    lastName,
    genderId,
    memberId,
    mobileNo,
    postcode,
    birthDateConvert,
    lineImageUrl,
    pProfileImgUrl,
    prepareAddress,
  } = stateProfile;

  const formattedPhoneNumber =
    mobileNo.substring(0, 3) +
    "-" +
    mobileNo.substring(3, 6) +
    "-" +
    mobileNo.substring(6);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      {/* <!-- Content Page --> */}

      <div id="pageProfile-details">
        {/* <!-- Content Page --> */}

        <main>
          <div className="wrapContent">
            <section id="pPointTop">
              <a
                className="btnIconBack"
                onClick={() => navigate("/profile")} /*href="/profile"*/
              ></a>
              <div className="bPoint">
                <p>Koala’s March point ของคุณ</p>
                <div className="bTotalPoint">
                  <span>{newCurrentPoint ?? "0"}</span>
                </div>
                <p>
                  หมดอายุ 31 ธันวาคม {Number(moment().format("YYYY")) + 543}
                </p>
              </div>
            </section>
            <section id="pProfileDisplay">
              <div className="bDisplay">
                <div
                  className="bPic"
                  style={{
                    backgroundImage: lineImageUrl
                      ? `url(${lineImageUrl})`
                      : `url(${pProfileImgUrl})`,
                  }}
                ></div>
              </div>
              <div className="bDetails">
                <h2>{firstName}</h2>
                <p data-icon="birthday">{birthDateConvert}</p>
                <p className="bMemberID">
                  ID : <a className="btnCopy">{memberId}</a>
                </p>
              </div>
              {/* ----------Theme change----------- */}
              <div className="bBtn">
                <button
                  className="btn"
                  onClick={() => navigate("/profile-theme")}
                >
                  เปลี่ยนธีม
                </button>
                {/* <a href="profile-themes.php" className="btn">
                  เปลี่ยนธีม
                </a> */}
              </div>
              {/* ---------------------------------- */}
              <a className="btnEdit" onClick={() => navigate("/profile-edit")}>
                แก้ไข
              </a>
            </section>

            <section id="pProfileDetails">
              <article>
                <h3 className="tTitle">ข้อมูลทั่วไป</h3>
                <div className="bGroup">
                  <p data-icon="user">
                    {firstName} {lastName}
                  </p>
                  <p data-icon="phone">{formattedPhoneNumber}</p>
                  <p data-icon="birthday">{birthDateConvert}</p>
                  <p data-icon="male">
                    {genderId ? (genderId == 2 ? "ไม่ระบุ" : "หญิง") : "ชาย"}
                  </p>
                  <p data-icon="brand">
                    <a className="btnCopy">{memberId}</a>
                  </p>
                </div>
              </article>
              <article>
                <h3 className="tTitle">ที่อยู่</h3>
                <div className="bGroup">
                  <p data-icon="address">{prepareAddress}</p>
                </div>
              </article>
            </section>
            <Menu />
            {/* <!-- Menu -->
          <?php include 'inc/inc-menu.php';?> */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Question;
