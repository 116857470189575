import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import $ from "jquery";
import { LuckydrawView } from "services/Api/Module/Luckydraw";
import { AddressInfo } from "services/Api/Module/Customer";
import helper from "services/helper";
import Banners from "pages/centerComponent/Banners";

const defAddressInfo = {
  province: [],
  district: [],
  subDistrict: [],
};

const LuckydrawComplete = () => {
  const [luckydrawData, setLuckydrawData] = useState({});
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const customerId = stateProfile.id;

  let [searchParams, setSearchParams] = useSearchParams();
  const luckydrawId = searchParams.get("id");

  const currentPoint = stateProfile.currentPoint ?? 0;

  const getLuckydrawView = async () => {
    let _return = {
      status: false,
      result: {},
      rowCount: 0,
    };
    try {
      const { data } = await LuckydrawView({
        luckydrawId: luckydrawId,
        params: { customer: customerId },
      });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        const rowCount = data.rowCount;

        _return.status = true;
        _return.result = resData;
        _return.rowCount = rowCount;
      }
    } catch (e) {
      console.log(e);
      return _return;
    }
    return _return;
  };

  const fetchLuckydrawView = async () => {
    try {
      const resData = await getLuckydrawView();
      setLuckydrawData(resData.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLuckydrawView();
  }, [stateProfile]);

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageLuckydraw-Complete">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <h2>Lucky draw</h2>
              </div>
            </section>
            <section id="pAnnouncement">
              <div className="bAnnouncement">
                <div className="bPic">
                  <i className="icoCheck"></i>
                </div>
                <div className="bDetails">
                  <h3>คุณได้ร่วมสนุกกับเราแล้ว!</h3>
                  <p>
                    รอประกาศผลผู้โชคดี วันที่{" "}
                    {helper.TIME.toFullDateTH(luckydrawData.annoucementDate)}
                  </p>
                  <span>ได้ที่ Line : @koalas-march</span>
                </div>
              </div>
            </section>
            <section id="pBtn">
              <div className="bBtn">
                <a onClick={() => navigate("/luckydraw")} className="btn">
                  กลับหน้า Lucky draw
                </a>
              </div>
            </section>
            <section id="pBanner">
              <h3>ใช้ Point ลุ้นรับของสุดพรีเมียมได้อีก!</h3>
              <Banners currentPage="Lucky draw Results" />
            </section>
          </div>
        </main>
        <Menu />
      </div>
    </div>
  );
};

export default LuckydrawComplete;
