import { configureStore } from "@reduxjs/toolkit";
import RegisterReducer from "../features/RegisterSlice";
import ProfileReducer from "../features/ProfileSlice";
import i18nReducer from "../i18n/i18nSlice";
import RegisterKlReducer from "../features/RegisterKlSlice";
import ThemeReducer from "../features/ThemeSlice";
import BannerReducer from "../features/BannerSlice";
import CurrentPointReducer from "../features/CurrentPointSlice";


export default configureStore({
  reducer: {
    i18n: i18nReducer,
    register: RegisterReducer,
    profile: ProfileReducer,
    registerKl: RegisterKlReducer,
    themeKl: ThemeReducer,   
    banner: BannerReducer,   
    currentPoint: CurrentPointReducer,   
  },
});
